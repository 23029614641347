//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Button, Select, Modal, Form, Checkbox, Input } from "antd";
import { connect } from "react-redux";

//Constants
const { Option } = Select;
const { Item } = Form;

class AddEditSpecimenContract extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
    };
    this.validateFormSpecimen = this.validateFormSpecimen.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
  }

  validateFormSpecimen(value) {
    const { form } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      else {
        const { specimenContractParams, onDeleteSpecimenCase, selectedSpecimenContract } = this.props;
        if(selectedSpecimenContract !== null){
          if(selectedSpecimenContract.specimenContractId !== values.specimenContractId){
            onDeleteSpecimenCase(selectedSpecimenContract.specimenContractId);
          }
        }
        let specimenContract =  specimenContractParams.find( sc => sc.specimenContractId === values.specimenContractId);
        let specimenContractCase = {
          specimenContractId: values.specimenContractId,
          customTariff: values.isCustomTariff ? values.customTariff : specimenContract.tariff,
          quantity: values.quantity,
          sampleSent: values.sampleSent,
          isCustomTariff: values.isCustomTariff,
          cupsCode: specimenContract.specimen.cupsCode,
          name: specimenContract.specimen.name,
        };
        this.props.onToggleAddEditSpecimenContract(false, specimenContractCase);
      }
    });
  }

  render() {
    const { specimenContractParams, onToggleAddEditSpecimenContract, 
            title, selectedSpecimenContract, isCytology } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    let customTariff = getFieldValue("isCustomTariff");
    return (
        <Modal  title={title} visible={true} keyboard={false}
                closable={false} destroyOnClose={true}
                footer={[
                  <Button type={"default"} key="back" onClick={() => onToggleAddEditSpecimenContract(false)}>
                      Cancelar
                  </Button>,
                  <Button key="submit" type="primary" onClick={this.validateFormSpecimen}>
                      {selectedSpecimenContract ? "Editar" : "Agregar"}
                  </Button>
                ]}
        >
        <Form>
          <Row>
            <Col xs={24}>
              <Item className="form-item" label="Espécimen">
                {getFieldDecorator("specimenContractId", {
                  initialValue: selectedSpecimenContract ? selectedSpecimenContract.specimenContractId : null,
                  rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                })(
                  <Select showSearch={true}
                          optionFilterProp={"children"}
                          filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                    {specimenContractParams.map( specimenContract =>
                        <Option key={specimenContract.specimenContractId} value={specimenContract.specimenContractId}>
                          {"(" + specimenContract.specimen.cupsCode + ") " + specimenContract.specimen.name}
                        </Option>
                      )}
                  </Select>
                )}
              </Item>
            </Col>
            <Col xs={24}>
                    <Item className="form-item margin-small" label="Cantidad">
                      {getFieldDecorator("quantity", {
                        getValueFromEvent: (e) => {
                            let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                            if (!isNaN(value))
                              return value
                        },
                        initialValue: selectedSpecimenContract ? selectedSpecimenContract.quantity : 1,
                        rules: [
                          { required: true, message: "Cantidad requerida" },
                          { type: "number", min: "0" },
                        ]
                      })(
                          <Input/>
                      )}
                    </Item>
                  </Col>
            <Col xs={24}>
              <Item className="form-item margin-small">
                {getFieldDecorator("isCustomTariff", {
                  initialValue: selectedSpecimenContract ? selectedSpecimenContract.isCustomTariff : false,
                  valuePropName: "checked",
                })(
                  <Checkbox>Precio personalizado</Checkbox>
                )}
              </Item>
            </Col>
            <Col xs={24}>
              <Item className="form-item margin-small" label="Precio personalizado">
                {getFieldDecorator("customTariff", {
                  getValueFromEvent: (e) => {
                      let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                      if (!isNaN(value))
                        return value
                  },
                  initialValue: customTariff ? (selectedSpecimenContract ? selectedSpecimenContract.customTariff : 0) : null,
                  rules: [
                    { required: customTariff, message: "Este campo es requerido" },
                    { type: "number", min: "0" },
                  ]
                })(
                    <Input disabled={!customTariff}/>
                )}
              </Item>
            </Col>
            {!isCytology &&
              <Col xs={24}>
                <Item className="form-item margin-small" label="Muestra enviada">
                  {getFieldDecorator("sampleSent", {
                    initialValue: selectedSpecimenContract ? selectedSpecimenContract.sampleSent : "",
                    rules: [{ required: true, message: "Este campo es requerido", whitespace: true }]
                  })(
                      <Input type={"text"}/>
                  )}
                </Item>
              </Col>
            }
          </Row>
        </Form>
      </Modal>
    );
  }
}

AddEditSpecimenContract.propTypes = {
  isCytology: PropTypes.bool,
  title: PropTypes.string,
  specimenContractParams: PropTypes.array,
  selectedSpecimenContract: PropTypes.object,
  onDeleteSpecimenCase: PropTypes.func,
  onToggleAddEditSpecimenContract: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddEditSpecimenContract));