// Types
import { clientsTypes } from '../../types';

// Initial State
const initialState = {
  clientsList: []
};

export default function clientsReducer (state = initialState, action) {
  switch (action.type) {
    case clientsTypes.GET_ALL_CLIENTS:
      return{
        ...state,
        clientsList: action.clientsList
      };
    default:
      return state;
  }
}