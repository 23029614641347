//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {Row, Col, Input, Form, Button, Divider, Icon} from "antd";
import { connect } from "react-redux";

//Services
import {forgotPassword} from "../../store/redux/actions/account/accountActions";

//Constants
import routes from "../../lib/routes";
import {CorpopatLogo} from "../../assets/customIcons";
import geekcoreLogo from "../../assets/images/geekcore-logo.png";
const { Item } = Form;

class ForgotPassword extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isMounted: false
    };

    this.onClickForgot = this.onClickForgot.bind(this);
  };

  onClickForgot(){
    const { form } = this.props;
    form.validateFields((errors, values) =>{
      if(errors) return null;
      else{
        this.props.forgotPassword(values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
        <Row className="main-container account">
          <div className={"account-main-div"}>
            <Row className={"account-container"}>
              <Col md={12} xs={0} className={"left-content"}>
                <div className={"center-align logo-container"}>
                  <CorpopatLogo width={300} height={200} />
                </div>
              </Col>
              <Col md={12} xs={24} className={"right-content"}>
                <div className={"form-container"}>
                  <h3>Recuperación de contraseña</h3>
                  <Form>
                    <Item className="form-item" label="">
                      {getFieldDecorator("email", {
                        rules: [
                          { required: true, message: "Campo requerido", whitespace: true}
                        ]
                      })(
                        <Input type="email" placeholder="Correo"
                               prefix={<Icon type="mail"/>}
                        />
                      )}
                    </Item>

                    <Button type={"primary"} size="large"
                            onClick={this.onClickForgot}>
                      Recuperar contraseña
                    </Button>
                  </Form>
                  <Col md={24} xs={24}>
                    <Divider />
                  </Col>
                  <Col md={24} xs={24} className={"account-links center-align"}>
                    <a href={routes.login}>Iniciar sesión</a>
                  </Col>
                </div>
                <p className={"center-align"}>
                  Powered by <a href={"https://geekcore.co"} target={"_blank"}><img alt={"Geekcore"} src={geekcoreLogo} /></a>
                </p>
              </Col>
            </Row>
          </div>
        </Row>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (data) => dispatch(forgotPassword(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ForgotPassword));
