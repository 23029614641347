//Libraries
import React, { Component } from "react";
// import * as PropTypes from "prop-types";
import { Row, Col, Divider } from "antd";
import { connect } from "react-redux";

//Subcomponents
// import CollapseList from "../subComponents/CollapseList";

//Constants
// import { actionsRecordsList } from "./actionsRecordsList";

class ActionsRecord extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      pagination: {
        current: 1,
        pageSize: 20,
      }
    };
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({
          isMounted: false,
        });
      }
    });
  }

  onChangePaginationConfig(current, size){
    const newPagination = this.state.pagination;

    newPagination["current"] = current;
    newPagination["pageSize"] = size;
    this.setState({ pagination: newPagination });
  };

  render() {
    // const { pagination } = this.state;
    return (
      <Row className="main-container section-list">
        <Col xs={24} className={"content-container"}>
          <h1>Registro de acciones</h1>
          <Divider />
            {/* <CollapseList dataList={actionsRecordsList} section={MENU_CONSTANTS}
                          current={pagination.current}
                          pageSize={pagination.pageSize} hideActions={true}
                          onChangePaginationConfig={this.onChangePaginationConfig}
            /> */}
        </Col>
      </Row>
    );
  }
}

ActionsRecord.propTypes = {
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsRecord);