//Libraries
import React, { Component } from "react";
import { ConfigProvider, Layout } from "antd";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";

//Components
import HeaderMenu from "./components/general/layout/HeaderMenu";
import Router from "./components/general/Router";
import Loading from "./components/general/Loading";

//Styles
import "antd/dist/antd.css";
import "./styles/app.scss";
import 'moment/locale/es-mx';
import locale from 'antd/lib/locale/es_ES';

class App extends Component {
  render() {
    const { isLoading, loggedIn } = this.props;

    return (
        <ConfigProvider locale={locale}>
          {isLoading && <Loading/>}

          {loggedIn && <HeaderMenu />}
          <Layout className={"main-layout"}>
            <Router/>
          </Layout>
        </ConfigProvider>
    );
  }
}

App.propTypes = {
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.generalReducer.isLoading,
    loggedIn: state.accountReducer.loggedIn
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));