import request from "./requestWrapper";

function getAll(data){
  return request({
    url: '/Specimen/GetAll',
    method: 'POST',
    params: data
  });
}

export default {
  getAll
};