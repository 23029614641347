export default {
  home: '/',
  login: '/sesion',
  register: '/registro',
  forgotPassword: '/recuperar-contrasena',
  newPassword: '/cambio-de-contrasena',

  caseList: '/casos',
  contract: '/contratos',
  specialist: '/especialistas',
  clients: '/clientes',
  headquarters: '/sedes',
  actionsRecord: '/registro-de-acciones',
  employees: '/empleados',
};