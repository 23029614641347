import { generalTypes } from "../../types";
import accountServices from "../../../../api/accountServices";

//Components
import {ERROR_MODAL, SUCCESS_MODAL, SUCCESS_MODAL_ON_OK} from "../../../../components/subComponents/responseModals";

//Functions
import {setLocalStorage} from "../../../../utils/accountUtils";
import routes from "../../../../lib/routes";

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const login = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    accountServices.login(data)
        .then(response => {
          for (let key in response.data) {
            setLocalStorage(key, response.data[key]);
          }
          window.location.href = routes.home;
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const register = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    accountServices.register(data)
        .then(response => {
          SUCCESS_MODAL(response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const forgotPassword = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    accountServices.forgotPassword(data)
        .then(response => {
          SUCCESS_MODAL(response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const resetPassword = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    accountServices.resetPassword(data)
        .then(response => {
          dispatch(setLoading(false));
          SUCCESS_MODAL_ON_OK(response.data, () => window.location.href = routes.home);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};