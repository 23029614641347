//Libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from 'antd';

//Components
import es_ES from "antd/es/locale/es_ES";
import App from "./App";

//Subcomponents
import * as serviceWorker from "./serviceWorker";
import store from "./store/store";

ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider locale={es_ES}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();