import {stringIsNullOrEmpty} from "./stringUtils";

export const isSignedIn = () => {
  const accessToken = localStorage.getItem("access_token");
  const userName = localStorage.getItem("user_name");
  const renderRole = localStorage.getItem("render_role");
  const validUserInfo = (!stringIsNullOrEmpty(accessToken)
      && !stringIsNullOrEmpty(userName)
      && !stringIsNullOrEmpty(renderRole));
  const expirationDate = new Date(localStorage.getItem("expires_on")).getTime();

  return validUserInfo && (expirationDate > new Date().getTime());
};

export function setSessionStorage(field, value) {
  sessionStorage.setItem(field, value);
}
export function setLocalStorage(field, value) {
  localStorage.setItem(field, value);
}
export function clearStorage(){
  sessionStorage.clear();
  localStorage.clear();
}

export const isValidPassword = (password) => {
  const digitExp = /[0-9]+/;
  const minLength = 6;
  const upperCaseExp = /[A-Z]+/;
  const minUniqueChars = 6;

  if (password.length < minLength) return false;
  if (countUniqueChars(password) < minUniqueChars) return false;
  return digitExp.test(password) && upperCaseExp.test(password);
};

const countUniqueChars = (string) => {
  let countMap = {};
  for (let i in string) {
    if (countMap.hasOwnProperty(string[i])) {
      countMap[string[i]] += 1;
      continue;
    }
    countMap[string[i]] = 1;
  }
  return Object.keys(countMap).length;

};