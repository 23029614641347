import { generalTypes, clientsTypes } from "../../types";
import clientsServices from "../../../../api/clientsServices";

//Components
import { ERROR_MODAL, SUCCESS_MODAL } from "../../../../components/subComponents/responseModals";

//Functions

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getAll = () => {
  return dispatch => {
    dispatch(setLoading(true));
    clientsServices.getAll()
        .then(response => {
          dispatch({
            type: clientsTypes.GET_ALL_CLIENTS,
            clientsList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const add = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    let formData = new FormData();

    Object.keys(data).forEach(key => {
      if (data.logo && key === "logo") {
        formData.append('logo', data.logo.originFileObj, data.logo.name);
      } else {
        formData.append(key, data[key]);
      }
    });
    clientsServices.add(formData)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const update = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    let formData = new FormData();

    Object.keys(data).forEach(key => {
      if (data.logo && key === "logo") {
        formData.append('logo', data.logo.originFileObj, data.logo.name);
      } else {
        formData.append(key, data[key]);
      }
    });
    clientsServices.update(formData)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const remove = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    clientsServices.remove(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};