// Types
import { specimenTypes } from '../../types';

// Initial State
const initialState = {
  specimensList: {
    specimens: [],
  },
};

export default function specialistsReducer (state = initialState, action) {
  switch (action.type) {
    case specimenTypes.GET_ALL_SPECIMENS:
      return{
        ...state,
        specimensList: action.specimensList
      };
    default:
      return state;
  }
}