//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Divider, Button, Icon } from "antd";
import { connect } from "react-redux";

//Services
import { getAll, add, remove, update } from "../../store/redux/actions/contracts/contractsActions";

//Components
import AddModal from "../subComponents/AddModal";
import EditContract from "./EditContract";

//Subcomponents
import CollapseList from "../subComponents/CollapseList";

//Constants
import { MENU_CONSTANTS } from "../../utils/constants";

class Contract extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      addModalVisible: false,
      editContractVisible: false,
      selectedItem: null,
      pagination: {
        current: 1,
        pageSize: 20,
      },
    };
    this.onToggleAddModal = this.onToggleAddModal.bind(this);
    this.onToggleEditContract = this.onToggleEditContract.bind(this);
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
    this.editContract = this.editContract.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({
          isMounted: false,
        });
        this.props.getAll();
      }
    });
  }

  onChangePaginationConfig (current, pageSize) {
    this.setState({ pagination: {
        current: current,
        pageSize: pageSize
      }
    });
  };

  //#region "Modal"
  onToggleAddModal (isVisible) {
    this.setState({ addModalVisible: isVisible });
  };

  onToggleEditContract (isVisible, item = null) {
    this.setState({
      editContractVisible: isVisible,
      selectedItem: item
    });
    if(!isVisible) {
      this.props.getAll();
    }
  };
  //#endregion

  editContract (data) {
    this.props.update(data);
  }

  render() {
    const { pagination, addModalVisible, editContractVisible, selectedItem } = this.state;
    const { contractsList, add, remove } = this.props;
    let contractsListExpanded = contractsList;
    contractsListExpanded.forEach((contract,index) => {
      contractsListExpanded[index].clientName = contractsListExpanded[index].campus.client.name;
      contractsListExpanded[index].campusName = contractsListExpanded[index].campus.name;
      contractsListExpanded[index].isActive = contractsListExpanded[index].campus.client.isActive;
    });
    return (
      <Row className="main-container section-list contract-container">
        {addModalVisible &&
        <AddModal title={"Agregar nuevo contrato"}
                  section={MENU_CONSTANTS.contracts}
                  onToggleAddModal={this.onToggleAddModal}
                  addFunction={add} />}

        <Col xs={24} className={"content-container"}>
          <h1>{
            editContractVisible ?
              <div>
                <span className="button-back">
                  <Icon  style={{ fontSize: '17px' }}
                         type="arrow-left"
                         onClick={() => this.onToggleEditContract(false)}/>
                </span>
                &nbsp; Editar Contrato
              </div>
              : <div>Lista de contratos</div>
          }</h1>
          <Divider />
          {editContractVisible ?
            <EditContract selectedItem={selectedItem} editFunction={(data) => this.editContract(data)}
                          onToggleEditContract={this.onToggleEditContract}/>
            :
            <div>
              <Col className="right-align">
                <Button type={"default"} icon="plus" onClick={() => this.onToggleAddModal(true)}>
                  Agregar contrato
                </Button>
              </Col>
              <CollapseList dataList={contractsList} section={MENU_CONSTANTS.contracts}
                            current={pagination.current} pageSize={pagination.pageSize}
                            rowKey={"contractId"}
                            onChangePaginationConfig={this.onChangePaginationConfig}
                            deleteItem={remove}
                            onToggleEditModal={this.onToggleEditContract}
              />
            </div>
          }
        </Col>
      </Row>
    );
  }
}

Contract.propTypes = {
  contractsList: PropTypes.array,
  getAll: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    contractsList: state.contractsReducer.contractsList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: () => dispatch(getAll()),
    add: (data) => dispatch(add(data)),
    remove: (data) => dispatch(remove(data)),
    update: (data) => dispatch(update(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
