//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {Row, Col, Input, Form, Button, Divider, Icon} from "antd";
import { connect } from "react-redux";

//Services
import {login} from "../../store/redux/actions/account/accountActions";

//functions
import {clearStorage, isValidPassword} from "../../utils/accountUtils";

//Constants
import routes from "../../lib/routes";
import {SUCCESS_MODAL} from "../subComponents/responseModals";
import {CorpopatLogo} from "../../assets/customIcons";
import geekcoreLogo from "../../assets/images/geekcore-logo.png"
const { Item } = Form;

class Login extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isMounted: false
    };

    this.onClickLogin = this.onClickLogin.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  };

  componentDidMount(){
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });

        clearStorage();
        let url_string = window.location;
        let url = new URL(url_string);
        let confirmEmail = url.searchParams.get("confirm") === "true";
        if(confirmEmail){
          SUCCESS_MODAL("El correo ha sido confirmado de manera correcta.");
        }
      }
    });
  }

  onClickLogin(){
    const { form } = this.props;
    form.validateFields((errors, values) =>{
      if(errors) return null;
      else{
        this.props.login(values);
      }
    });
  }

  handlePasswordChange(rule, value, callback) {
    try {
      if (!isValidPassword(value)) {
        throw new Error('La contraseña no cumple las politicas');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
        <Row className="main-container account">
         <div className={"account-main-div"}>
           <Row className={"account-container"}>
             <Col md={12} xs={24} className={"left-content"}>
               <div className={"center-align logo-container"}>
                 <CorpopatLogo width={300} height={200} />
               </div>
               <Col md={0} xs={24} className={"responsive-content"}>
                 <Col md={0} xs={24} className={"right-content responsive-right-content"}>
                   <div className={"form-container"}>
                     <h3>Inicio de sesión</h3>
                     <Form>
                       <Item className="form-item" label="">
                         {getFieldDecorator("email", {
                           rules: [
                             { required: true, message: "Campo requerido", whitespace: true}
                           ]
                         })(
                           <Input type="email" size="default" autoComplete="off" placeholder="Correo"
                                  prefix={<Icon type="mail"/>}
                                  onPressEnter={this.onClickLogin}/>
                         )}
                       </Item>
                       <Item className="form-item" label="">
                         {getFieldDecorator("password", {
                           rules: [
                             { required: true, message: "Campo requerido", whitespace: true },
                             { validator: this.handlePasswordChange }
                           ]
                         })(
                           <Input.Password type="password" size="default" autoComplete={"false"} placeholder="Contraseña"
                                           prefix={<Icon type="lock"/>}
                                           onPressEnter={this.onClickLogin}/>
                         )}
                       </Item>
                       <Col xs={24}>
                         <Button size="large" type={"primary"}
                                 onClick={this.onClickLogin}>
                           Iniciar sesión
                         </Button>
                       </Col>
                     </Form>
                     <Col md={24} xs={24}>
                       <Divider />
                     </Col>
                     <Col md={24} xs={24} className={"account-links center-align"}>
                       <a href={routes.forgotPassword}>¿Olvidó la contraseña?</a>
                       <br />
                       <a href={routes.register}>Registrarse</a>
                     </Col>
                   </div>
                   <p className={"center-align"}>
                     Powered by <a href={"https://geekcore.co"} target={"_blank"}><img alt={"Geekcore"}  src={geekcoreLogo} /></a>
                   </p>
                 </Col>
               </Col>
             </Col>
             <Col md={12} xs={0} className={"right-content"}>
               <div className={"form-container"}>
                 <h3>Inicio de sesión</h3>
                 <Form>
                   <Item className="form-item" label="">
                     {getFieldDecorator("email", {
                       rules: [
                         { required: true, message: "Campo requerido", whitespace: true}
                       ]
                     })(
                       <Input type="email" size="default" autoComplete="off" placeholder="Correo"
                              prefix={<Icon type="mail"/>}
                              onPressEnter={this.onClickLogin}/>
                     )}
                   </Item>
                   <Item className="form-item" label="">
                     {getFieldDecorator("password", {
                       rules: [
                         { required: true, message: "Campo requerido", whitespace: true },
                         { validator: this.handlePasswordChange }
                       ]
                     })(
                       <Input.Password type="password" size="default" autoComplete={"false"} placeholder="Contraseña"
                                       prefix={<Icon type="lock"/>}
                                       onPressEnter={this.onClickLogin}/>
                     )}
                   </Item>
                   <Col xs={24}>
                     <Button size="large" type={"primary"}
                             onClick={this.onClickLogin}>
                       Iniciar sesión
                     </Button>
                   </Col>
                 </Form>
                 <Col md={24} xs={24}>
                   <Divider />
                 </Col>
                 <Col md={24} xs={24} className={"account-links center-align"}>
                   <a href={routes.forgotPassword}>¿Olvidó la contraseña?</a>
                   <br />
                   <a href={routes.register}>Registrarse</a>
                 </Col>
               </div>
               <p className={"center-align"}>
                 Powered by <a href={"https://geekcore.co"} target={"_blank"}><img alt={"Geekcore"}  src={geekcoreLogo} /></a>
               </p>
             </Col>
           </Row>
         </div>
        </Row>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login));
