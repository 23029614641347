import request from "./requestWrapper";

function login(data) {
  return request({
    url: '/Account/Login',
    method: 'POST',
    data: data
  });
}
function register(data){
  return request({
    url: '/Account/Register',
    method: 'POST',
    data: data
  })
}
function forgotPassword(data){
  return request({
    url: '/Account/ForgotPassword',
    method: 'POST',
    data: data
  })
}
function resetPassword(data){
  return request({
    url: '/Account/ResetPassword',
    method: 'POST',
    data: data
  })
}

export default {
  login, register, forgotPassword, resetPassword
}