import request from "./requestWrapper";

function getAllPaginated(data) {
  return request({
    url: '/Case/GetAllPaginated',
    method: 'POST',
    data,
  });
}
function getCaseDetail(data) {
  return request({
    url: '/Case/GetCaseDetail',
    method: 'GET',
    params: data,
  });
}
function getCie10(data){
  return request({
    url: '/Case/GetCie10',
    method: 'GET',
    params: data
  });
}
function getParams(data) {
  return request({
    url: '/Case/getParams',
    method: 'GET',
    params: data,
  });
}
function add(data) {
  return request({
    url: '/Case/Add',
    method: 'POST',
    data
  });
}
function update(data) {
  return request({
    url: '/Case/Update',
    method: 'PUT',
    data: data
  });
}
function remove(caseId) {
  return request({
    url: '/Case/Delete',
    method: 'DELETE',
    params: { caseId }
  });
}
function generatePdf(data) {
  return request({
    url: '/Case/GeneratePdf',
    method: 'POST',
    params: data
  });
}
function generateExcel(data) {
  return request({
    url: '/Case/GenerateReportByCaseId',
    method: 'POST',
    params: data
  });
}
function generateZIP(data, formatName) {
  return request({
    url: '/Case/GenerateZip',
    method: 'POST',
    params: { formatName },
    data: data
  });
}
function generatePDFsReport(data) {
  return request({
    url: '/Case/GeneratePdfs',
    method: 'POST',
    data: data
  });
}
function generateCasesReport(data) {
  return request({
    url: '/Case/GenerateCasesReport',
    method: 'POST',
    data: data
  });
}
function submitMaterial(data) {
  return request({
    url: '/Case/SubmitMaterial',
    method: 'PUT',
    params: data
  });
}
function getCaseHistory(data) {
  return request({
    url: '/Case/GetCaseHistory',
    method: 'GET',
    params: data
  });
}
function getCaseHistoriesByCaseId(data) {
  return request({
    url: '/Case/GetCaseHistoriesByCaseId',
    method: 'GET',
    params: data
  });
}

export default {
  add, update, remove, getParams, getCaseDetail, generatePdf,
  submitMaterial, getAllPaginated, generateCasesReport, generatePDFsReport, generateZIP,
  generateExcel, getCie10, getCaseHistory, getCaseHistoriesByCaseId
}