//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Popconfirm, Icon, Tooltip, Alert, Table } from "antd";
import { connect } from "react-redux";

//Constants
import {DATA_LABELS, MENU_CONSTANTS, PAGINATION_CONFIG} from "../../utils/constants";
import baseURL from "../../api/baseUrl";
import noImage from "../../assets/images/noImage.png";

//Functions
import {getTableColumns} from "../../utils/functions";

class CollapseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      current: 1,
      pageSizeNumber: 10,
      placement: "top",
      panelTitle: "",
      windowWidth: 800
    };
    this.extraIcons = this.extraIcons.bind(this);
  };

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ windowWidth: document.body.clientWidth })
    );

    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({
          windowWidth: document.body.clientWidth,
          isMounted: false
        });
      }
    });
  };

    extraIcons (item) {
    const { onToggleEditModal, deleteItem } = this.props;
    const { placement } = this.state;

    return  <Col md={24} xs={24} className={"center-align"}>
          <Tooltip title={"Editar"}>
            <Icon type="edit"
                  onClick={() => onToggleEditModal(true, item)} />
          </Tooltip>
          <Tooltip title={"Eliminar"} placement={placement}>
            <Popconfirm title={'¿Quiere eliminar este elemento?'}
                        onCancel={() => this.setState({ placement: "top"})}
                        onConfirm={() => {
                          this.setState({ placement: "top"});
                          deleteItem(item);
                        }}>
              <Icon type="delete"
                    onClick={() => this.setState({ placement: "right"}) } />
            </Popconfirm>
          </Tooltip>
    </Col>
  };

  render() {
    const { dataList, onChangePaginationConfig, current, pageSize, section, rowKey } = this.props;
    const { windowWidth } = this.state;
    const logoImg = section !== MENU_CONSTANTS.contracts && section !== MENU_CONSTANTS.headquarters &&
      section !== MENU_CONSTANTS.employees;

    return (
      dataList.length > 0 ? (
        <Row className="list-collapse">
          <Table columns={getTableColumns(section, this.extraIcons, windowWidth)} rowKey={rowKey} dataSource={dataList}
                 scroll={{ x: "700px" }}
                 expandedRowRender={item =>
                     <Row gutter={[12,12]} justify="space-around" className={"expandend-container-row"}>
                       { logoImg &&
                            <Col xs={0} md={6} className={"center-align"}>
                              <img src={
                                section === MENU_CONSTANTS.clients
                                    ? item.logoUrl ? baseURL + item.logoUrl : noImage
                                    : section === MENU_CONSTANTS.specialists
                                    ? item.signatureUrl ? baseURL + item.signatureUrl : noImage
                                    : noImage
                              } alt="Logo" className="logo-img" />
                            </Col>
                       }
                       <Col xs={24} md={logoImg ? 18 : 24}>
                         {Object.keys(item).map(key =>
                             key !== "client" ?
                             (DATA_LABELS[key] &&
                              <Col xs={24} sm={24} md={12} lg={12} xl={8} key={key} className="text-data-label">
                                <b>{DATA_LABELS[key]}:</b>&nbsp;
                                {typeof(item[key]) === "boolean"
                                    ? item[key] ? "Si" : "No"
                                    : item[key]
                                }
                              </Col>)
                             :( Object.keys(item.client).map((keyClient, index) =>
                                DATA_LABELS[keyClient] &&
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index} className="text-data-label">
                                    <b>{DATA_LABELS[keyClient] === "Nombre" ? "Cliente" : DATA_LABELS[keyClient]}:</b>&nbsp;
                                      {typeof(item.client[keyClient]) === "boolean"
                                          ? item.client[keyClient] ? "Si" : "No"
                                          : item.client[keyClient]
                                      }
                                </Col>
                                )
                              ))}
                       </Col>
                       { logoImg &&
                         <Col xs={24} md={0} className={"center-align logo-responsive"}>
                           <img src={
                             section === MENU_CONSTANTS.clients
                               ? item.logoUrl ? baseURL + item.logoUrl : noImage
                               : section === MENU_CONSTANTS.specialists
                               ? item.signatureUrl ? baseURL + item.signatureUrl : noImage
                               : noImage
                           } alt="Logo" className="logo-img" />
                         </Col>
                       }
                     </Row>
                 }
                 pagination={{
                   total: dataList.length,
                   current: current,
                   showSizeChanger: PAGINATION_CONFIG.showSizeChanger,
                   pageSize: pageSize,
                   size: PAGINATION_CONFIG.size,
                   showTotal: (total, range) => PAGINATION_CONFIG.showTotal(total, range),
                   pageSizeOptions: PAGINATION_CONFIG.pageSizeOptions,
                   onChange: onChangePaginationConfig,
                   onShowSizeChange: onChangePaginationConfig
                 }}
          />
        </Row>
        ):(
          <Row className="list-alert">
            <Alert message="Sin registros"
                   description="No hay registros disponibles en este momento."
                   type="info"
                   showIcon/>
          </Row>
      )
    );
  }
}

CollapseList.propTypes = {
  current: PropTypes.number,
  pageSize: PropTypes.number,
  rowKey: PropTypes.string,
  section: PropTypes.string,
  dataList: PropTypes.array,
  onChangePaginationConfig: PropTypes.func,
  deleteItem: PropTypes.func,
  onToggleEditModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseList);