//Libraries
import React from 'react';
import { Layout, Menu, Drawer, Icon } from 'antd';
import {NavLink, withRouter} from 'react-router-dom';
import * as PropTypes from "prop-types";
import connect from 'react-redux/es/connect/connect';
import { getPrivilege } from '../../../utils/functions';

//Actions
import {setCurrentRoute} from "../../../store/redux/actions/general/generalActions";

//Constants
import routes from "../../../lib/routes";
import LogoutModal from "./LogoutModal";
import { PRIVILEGE_LEVEL } from "../../../utils/constants";
import logo from '../../../assets/images/corpopat-logo.png';
import {CorpopatLogo} from "../../../assets/customIcons";
const { Header } = Layout;

class HeaderMenu extends React.Component{
  constructor (props) {

    super(props);

    this.state = {
      isMounted: false,
      modalVisible: false,
      smallSize: false,
      topMenuVisible: false,
    };
    this.onToggleModal = this.onToggleModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClickTopMenu = this.onClickTopMenu.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
  };

  componentDidMount(){
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        this.props.setCurrentRoute(this.props.history.location.pathname);
        window.addEventListener("resize", this.screenSize);
        this.screenSize();
      }
    });
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.screenSize);
  }

  onClickMenu(route){
    if(route.key === "logout") return;
    if(route.key === "/"){
      this.props.setCurrentRoute(routes.caseList);
    }
    else if(route.key === "item_1"){
      this.props.setCurrentRoute(this.props.currentRoute);
    }
    else{
      this.props.setCurrentRoute(route.key);
    }
  }

  onToggleModal(status){
    this.setState({ modalVisible: status });
  }

  onClose(){
    this.setState({ topMenuVisible: false });
  }

  screenSize = () => {
    this.setState({smallSize: window.innerWidth <= 820});
  }

  showDrawer(route){
    this.props.setCurrentRoute(route);
    this.setState({topMenuVisible: !this.state.topMenuVisible});
  }

  onClickTopMenu(route){
    this.onClose();
    this.props.setCurrentRoute(route);
    this.props.history.push(route);
  }

  render(){
    let { modalVisible, smallSize, topMenuVisible } = this.state;
    let { currentRoute } = this.props;
    let rolePrivilege = getPrivilege(localStorage.getItem("render_role"));
    return (
        <div>
          {modalVisible && <LogoutModal onToggleModal={this.onToggleModal} />}

          <Header className='header-menu'>
            {!smallSize ? (
              <Menu onClick={(route) => this.onClickMenu(route)} mode='horizontal' selectedKeys={[currentRoute]}>
                <Menu.Item className='menu-option logo-menu-option' key={routes.home}>
                  <NavLink to={routes.home}>
                    <CorpopatLogo width={100} height={48} />
                  </NavLink>
                </Menu.Item>
                { rolePrivilege >= PRIVILEGE_LEVEL.low &&
                    <Menu.Item className='menu-option' key={routes.caseList}>
                      <NavLink to={routes.caseList}>
                        Casos
                      </NavLink>
                    </Menu.Item>
                }
                { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                    <Menu.Item className='menu-option' key={routes.contract}>
                      <NavLink to={routes.contract}>
                        Contratos
                      </NavLink>
                    </Menu.Item>
                }
                { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                    <Menu.Item className='menu-option' key={routes.headquarters}>
                      <NavLink to={routes.headquarters}>
                        Sedes
                      </NavLink>
                    </Menu.Item>
                }
                { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                    <Menu.Item className='menu-option' key={routes.clients}>
                      <NavLink to={routes.clients}>
                        Clientes
                      </NavLink>
                    </Menu.Item>
                }
                { rolePrivilege >= PRIVILEGE_LEVEL.high &&
                    <Menu.Item className='menu-option' key={routes.specialist}>
                      <NavLink to={routes.specialist}>
                        Especialistas
                      </NavLink>
                    </Menu.Item>
                }
                { rolePrivilege >= PRIVILEGE_LEVEL.high &&
                    <Menu.Item className='menu-option' key={routes.employees}>
                      <NavLink to={routes.employees}>
                        Empleados
                      </NavLink>
                    </Menu.Item>
                }
                <Menu.Item className='menu-option right-menu-option' key={"logout"}
                          onClick={() => this.onToggleModal(true)}>
                  Cerrar sesión
                </Menu.Item>
                <p className='menu-option right-menu-option' key={"welcome"}>
                  {`¡Hola, ${localStorage.getItem("user_name")}!`}
                </p>
              </Menu>
              ):(
                <>
                  <Menu onClick={(route) => this.onClickMenu(route)} mode='horizontal' selectedKeys={[currentRoute]}>
                    <Menu.Item className='menu-option logo-menu-option' key={routes.home}>
                      <NavLink to={routes.home}>
                        <b className={"logo-main"}>REP</b>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item className='menu-option right-menu-option'
                               onClick={() => this.showDrawer(currentRoute)}
                    >
                        <Icon type="menu" style={{ marginRight: '0px'}} />
                    </Menu.Item>
                  </Menu>
                  <Drawer
                          placement="top"
                          closable={false}
                          onClose={() => this.onClose()}
                          visible={topMenuVisible}
                          height={rolePrivilege >= PRIVILEGE_LEVEL.high ? 342 : rolePrivilege >= PRIVILEGE_LEVEL.middle ? 257 : 135}
                          className={topMenuVisible ? "drawer-menu margin-top-visible": "drawer-menu"}
                  >
                    { rolePrivilege >= PRIVILEGE_LEVEL.low &&
                      <p className={currentRoute === routes.caseList ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.caseList)}>Casos</p>
                    }
                    { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                      <p className={currentRoute === routes.contract ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.contract)}>Contratos</p>
                    }
                    { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                      <p className={currentRoute === routes.headquarters ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.headquarters)}>Sedes</p>
                    }
                    { rolePrivilege >= PRIVILEGE_LEVEL.middle &&
                      <p className={currentRoute === routes.clients ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.clients)}>Clientes</p>
                    }
                    { rolePrivilege >= PRIVILEGE_LEVEL.high &&
                      <p className={currentRoute === routes.specialist ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.specialist)}>Especialistas</p>
                    }
                    { rolePrivilege >= PRIVILEGE_LEVEL.high &&
                      <p className={currentRoute === routes.employees ? "menu-option-select" : ""}
                         onClick={() => this.onClickTopMenu(routes.employees)}>Empleados</p>
                    }
                    <p onClick={() => this.onToggleModal(true)}>Cerrar sesión</p>
                  </Drawer>
                </>
            )}
          </Header>
        </div>
    );
  };
}

HeaderMenu.propTypes = {
  currentRoute: PropTypes.string,
  logout: PropTypes.func,
  setCurrentRoute: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    currentRoute: state.generalReducer.currentRoute
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentRoute: (route) => dispatch(setCurrentRoute(route))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderMenu));