import {generalTypes, contractsTypes} from "../../types";

//Services
import contractsServices from "../../../../api/contractsServices";

//Components
import { ERROR_MODAL, SUCCESS_MODAL } from "../../../../components/subComponents/responseModals";

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getContractsParams = () => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.getParams()
        .then(response => {
          let specimens = response.data.specimens;
          let specimenTables = [];
          let specimenCategories = [];

          specimens.forEach(s => {
            if(!specimenTables.some(st => st.specimenTableId === s.specimenCategory.specimenTableId)){
              s.specimenCategory.specimenTable.specimenCategories = [];
              specimenTables.push(s.specimenCategory.specimenTable)
            }

            if(!specimenCategories.some(sc => sc.specimenCategoryId === s.specimenCategoryId)){
              delete s.specimenCategory.specimenTable;
              let specimenCategoryToPush = s.specimenCategory;
              specimenCategoryToPush.specimens = [];
              delete s.specimenCategory;
              specimenCategoryToPush.specimens.push(s);
              specimenCategories.push(specimenCategoryToPush);
            }else{
              let categoryIndex = specimenCategories.findIndex(sc => sc.specimenCategoryId === s.specimenCategoryId);
              specimenCategories[categoryIndex].specimens.push(s);
            }
          });

          specimenCategories.forEach(sc => {
            let tableIndex = specimenTables.findIndex(st => st.specimenTableId === sc.specimenTableId);
            specimenTables[tableIndex].specimenCategories.push(sc);
          });

          dispatch({
            type: contractsTypes.GET_CONTRACT_PARAMS,
            contractsParams: { campuses: response.data.campuses, specimenTables }
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};


export const getAll = () => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.getAll()
        .then(response => {
          dispatch({
            type: contractsTypes.GET_ALL_CONTRACTS,
            contractsList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const add = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.add(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const remove = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.remove({contractId: data.contractId})
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const update = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.update(data)
        .then(response => {
          dispatch(getContractDetail({contractId: data.contractId}));
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const getContractDetail = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    contractsServices.getContractDetail(data)
        .then(response => {
          let specimensContracts = [];
          response.data.specimensContract.forEach((specimenContract, index)=>{
            specimensContracts[index] = {
              key: specimenContract.specimenId,
              category: specimenContract.specimen.specimenCategory.name,
              specimen: "("+ specimenContract.specimen.cupsCode + ") " + specimenContract.specimen.name,
              defaultTariff: specimenContract.tariff
            }
          })
          dispatch({
            type: contractsTypes.GET_CONTRACT_DETAIL,
            specimensContracts: specimensContracts
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};