//Libraries
import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
  Collapse, List, Col, Button, Form, Input, DatePicker, Select,
  Divider, Icon, Checkbox, Row, Tooltip, message
} from "antd";
import {connect} from "react-redux";
import {thousandsNumberSeparator} from "../../../utils/stringUtils";
import moment from 'moment';
import {isStatusComplete} from "../../../utils/functions";

//Services
import {
  getParams, add, getCampusAllByClientId, getCaseDetail, update,
  getCie10, cleanCie10List, getContractAllByCampusId,
  getSpecimensContractByContractId, setInitialCaseDetail
} from "../../../store/redux/actions/cases/casesActions";

//Component
import AddEditSpecimenContract from "./AddEditSpecimenContract";

//Constants
import {
  DATA_SELECTS, IDENTIFICATION_TYPE, AGE_MEASURING_UNIT,
  GENERAL_CATEGORIZATION, OTHER_PLANNING_METHOD, RENDER_ROLE, STATUS_CONSTANTS
} from "../../../utils/constants";

const {Item} = Form;
const {Option} = Select;
const {TextArea} = Input;
const {Panel} = Collapse;

class AddEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      showAddEditSpecimenContract: false,
      specimensContractCase: [],
      selectedSpecimenContract: null,
    };
    this.validateForm = this.validateForm.bind(this);
    this.onChangeCampusSelect = this.onChangeSelect.bind(this);
    this.onToggleAddEditSpecimenContract = this.onToggleAddEditSpecimenContract.bind(this);
    this.onDeleteSpecimenCase = this.onDeleteSpecimenCase.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
  };

  componentDidMount() {
    this.setState({isMounted: true}, () => {
      if (this.state.isMounted) {
        this.setState({isMounted: false});
        let data = {isCytology: this.props.isCytology};
        this.props.getParams(data);
        if (this.props.action === "Edit") {
          this.props.getCaseDetail({caseId: this.props.caseToEdit.caseId}, true);
        } else {
          this.props.setInitialCaseDetail();
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {caseDetail, specimenContractParams} = this.props;
    if (caseDetail !== prevProps.caseDetail) {
      if (caseDetail.specimenContractCases.length > 0) {
        if (caseDetail.specimenContractCases[0].specimenContract.contract.campus.client.clientId !== undefined) {
          let data = {};
          data.clientId = caseDetail.specimenContractCases[0].specimenContract.contract.campus.client.clientId;
          this.props.getCampusAllByClientId(data);
          data = {};
          data.campusId = caseDetail.specimenContractCases[0].specimenContract.contract.campus.campusId;
          this.props.getContractAllByCampusId(data);
          data = {};
          data.contractId = caseDetail.specimenContractCases[0].specimenContract.contract.contractId;
          this.props.getSpecimensContractByContractId(data);
        }
      }
    }
    if (specimenContractParams !== prevProps.specimenContractParams) {
      if (this.props.action === "Edit") {
        caseDetail.specimenContractCases.forEach(specimenContract => {
          let specimenContractInfo = specimenContractParams.find(sc => sc.specimenContractId === specimenContract.specimenContractId);
          let specimenContractCase = {
            specimenContractId: specimenContract.specimenContractId,
            customTariff: specimenContract.customTariff,
            quantity: specimenContract.quantity,
            sampleSent: specimenContract.sampleSent,
            isCustomTariff: specimenContract.customTariff !== specimenContract.specimenContract.tariff,
            cupsCode: specimenContractInfo.specimen.cupsCode,
            name: specimenContractInfo.specimen.name,
          };
          this.onToggleAddEditSpecimenContract(false, specimenContractCase);
        });
      }
    }
  }

  validateForm() {
    const {form, caseDetail} = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        message.error('Campos no completados o erróneos');
        return;
      } else if (this.state.specimensContractCase.length === 0) {
        message.error('Falta agregar especímenes en la infromacion de facturación');
        return;
      } else {
        let data = values;
        let isEdit = this.props.action === "Edit";

        if (isEdit) {
          data.caseId = caseDetail.caseId;
          data.patient.patientId = caseDetail.patientId;
          if (this.props.isCytology) {
            data.cytologyHistory.cytologyHistoryId = caseDetail.cytologyHistoryId;
            data.cytologyAnalysisResult.cytologyAnalysisResultId = caseDetail.cytologyAnalysisResultId;
          } else {
            data.shortDiagnosis = data.noShortDiagnosis ? null : data.shortDiagnosis;
          }
        }
        delete data.campusId;
        delete data.clientId;
        data.isCytology = this.props.isCytology;
        // Fix data
        data.patient.birthday = data.patient.birthday !== null ? moment(new Date(data.patient.birthday).setHours(0, 0, 0)).format() : null;
        data.receptionDate = data.receptionDate !== null ? moment(new Date(data.receptionDate).setHours(0, 0, 0)).format() : null;
        data.specimenDate = data.specimenDate !== null ? moment(new Date(data.specimenDate).setHours(0, 0, 0)).format() : null;
        if (this.props.isCytology) {
          data.cytologyHistory.childbirthLastDate = data.cytologyHistory.childbirthLastDate !== null ? moment(new Date(data.cytologyHistory.childbirthLastDate).setHours(0, 0, 0)).format() : null;
          data.cytologyHistory.lastCytologyDate = data.cytologyHistory.lastCytologyDate !== null ? moment(new Date(data.cytologyHistory.lastCytologyDate).setHours(0, 0, 0)).format() : null;
          data.cytologyHistory.lastPeriodDate = data.cytologyHistory.lastPeriodDate !== null ? moment(new Date(data.cytologyHistory.lastPeriodDate).setHours(0, 0, 0)).format() : null;
          if (!data.isPositive && !data.negativeControl) data.pathologistId = null;
          if (data.cytologyAnalysisResult.neckAspectsIds !== null) {
            data.cytologyAnalysisResult.neckAspectsIds = data.cytologyAnalysisResult.neckAspectsIds.toString();
          }
          if (data.cytologyAnalysisResult.otherFindingsIds !== null) {
            data.cytologyAnalysisResult.otherFindingsIds = data.cytologyAnalysisResult.otherFindingsIds.toString();
          }
          if (data.cytologyAnalysisResult.microorganismsIds !== null) {
            data.cytologyAnalysisResult.microorganismsIds = data.cytologyAnalysisResult.microorganismsIds.toString();
          }
          if (data.cytologyAnalysisResult.squamousCellAbnormalitiesIds !== null) {
            data.cytologyAnalysisResult.squamousCellAbnormalitiesIds = data.cytologyAnalysisResult.squamousCellAbnormalitiesIds.toString();
          }
          if (data.cytologyAnalysisResult.glandularCellAbnormalitiesIds !== null) {
            data.cytologyAnalysisResult.glandularCellAbnormalitiesIds = data.cytologyAnalysisResult.glandularCellAbnormalitiesIds.toString();
          }
          data.isComplex = false;
          data.blocksNumber = 0;
          data.hyeSlidesNumber = 0;
          data.immunoSlidesNumber = 0;
          data.internalNumber = null;
        }
        data.specimenContractCasesVm = this.state.specimensContractCase;
        data.patient.age = parseInt(data.patient.age);
        data.blocksNumber = parseInt(data.blocksNumber);
        data.hyeSlidesNumber = parseInt(data.hyeSlidesNumber);
        data.immunoSlidesNumber = parseInt(data.immunoSlidesNumber);
        // Fix data
        isEdit
          ? this.props.update(data, this.props.onToggleAddEditModal, this.props.pagination, this.props.filterVm)
          : this.props.add(data, this.props.onToggleAddEditModal, this.props.pagination, this.props.filterVm);
      }
    });
  }

  handleChangeBirthday(value) {
    const {setFieldsValue} = this.props.form;
    var years = moment().diff(value, 'years');
    setFieldsValue({
      [`patient.age`]: years,
      [`patient.ageMeasuringUnit`]: AGE_MEASURING_UNIT.years,
    });
  }

  onChangeSelect(id, select) {
    const {setFieldsValue} = this.props.form;
    let data = {}
    switch (select) {
      case DATA_SELECTS.client:
        data.clientId = id;
        this.props.getCampusAllByClientId(data);
        setFieldsValue({
          ["campusId"]: undefined,
          ["contractId"]: undefined
        });
        this.setState({
          specimensContractCase: [],
        });
        break;
      case DATA_SELECTS.campus:
        data.campusId = id;
        this.props.getContractAllByCampusId(data);
        setFieldsValue({["contractId"]: undefined});
        this.setState({
          specimensContractCase: [],
        });
        break;
      case DATA_SELECTS.contract:
        data.contractId = id;
        this.props.getSpecimensContractByContractId(data);
        setFieldsValue({["specimenContractId"]: undefined});
        this.setState({
          specimensContractCase: [],
        });
        break;
      default:
        break;
    }

  }

  onToggleAddEditSpecimenContract(isVisible, specimensContractCase = null, selectedSpecimenContract = null) {
    if (specimensContractCase !== null) {
      let newSpecimenContract = this.state.specimensContractCase;
      let editFlag = false;
      newSpecimenContract.forEach((specimenContract, index) => {
        if (specimenContract.specimenContractId === specimensContractCase.specimenContractId) {
          newSpecimenContract[index] = specimensContractCase;
          editFlag = true;
        }
      });
      if (!editFlag) {
        newSpecimenContract.push(specimensContractCase);
      }
      this.setState({
        showAddEditSpecimenContract: isVisible,
        specimensContractCase: newSpecimenContract,
        selectedSpecimenContract: null,
      });
    } else {
      this.setState({
        showAddEditSpecimenContract: isVisible,
        selectedSpecimenContract: selectedSpecimenContract,
      });
    }
  }

  onDeleteSpecimenCase(specimenContractId) {
    let newSpecimensContractCase = this.state.specimensContractCase;
    newSpecimensContractCase.forEach((specimenContractCase, index) => {
      if (specimenContractCase.specimenContractId === specimenContractId) {
        newSpecimensContractCase.splice(index, 1);
      }
    });
    this.setState({
      specimensContractCase: newSpecimensContractCase
    })
  }

  handleSearch(value) {
    if (value.length === 3 && this.props.cie10List.length === 0) {
      this.props.getCie10({value: value});
    } else if (value.length < 3) {
      this.props.cleanCie10List();
    }
  }

  render() {
    const { action, onToggleAddEditModal, isCytology, casesParams, cie10List, campusParams,
      contractParams, specimenContractParams, caseDetail, form } = this.props;
    const {getFieldDecorator, getFieldValue, setFieldsValue} = form;
    const {showAddEditSpecimenContract, specimensContractCase, selectedSpecimenContract} = this.state;
    const renderRole = localStorage.getItem('render_role');
    const userId = localStorage.getItem('user_id');
    let isEdit = action === "Edit";
    let iconSize = "17px";
    let campusValue = getFieldValue("clientId");
    let contractValue = getFieldValue("campusId");
    let specimenContractValue = getFieldValue("contractId");
    let disableShortDiagnosis = getFieldValue("noShortDiagnosis");
    let shortDiagnosisIsRequired = getFieldValue("caseStatusId") == 4 && !disableShortDiagnosis;
    let patientAgeValue = getFieldValue("patient.age");
    let positiveValueOrNegativeControl = getFieldValue("isPositive") || getFieldValue("negativeControl");
    let isSpecialist = renderRole === RENDER_ROLE.specialist.role;
    let isAdmin = renderRole === RENDER_ROLE.administrator.role;
    let completedStatus = caseDetail.caseStatus.name === STATUS_CONSTANTS.completed
                          || caseDetail.caseStatus.name === STATUS_CONSTANTS.submittedMaterial;
    let isSpecialistAndAssigned = isSpecialist && (caseDetail.pathologistId === userId || caseDetail.cytologistId === userId);
    let isAdminAndCompletedStatus = isAdmin && completedStatus;
    let identificationTypeId = getFieldValue("patient.identificationType");
    let caseDetailIsEmpty = !caseDetail.patient.names;

    return (
      ((isEdit && !caseDetailIsEmpty) || !isEdit) &&
        <Row gutter={[16]} className={"content-container"}>
        {showAddEditSpecimenContract &&
          <AddEditSpecimenContract specimenContractParams={specimenContractParams}
                                   title={"Agregar espécimen"}
                                   isCytology={isCytology}
                                   selectedSpecimenContract={selectedSpecimenContract}
                                   onDeleteSpecimenCase={this.onDeleteSpecimenCase}
                                   onToggleAddEditSpecimenContract={this.onToggleAddEditSpecimenContract}/>
        }
        <h1>
          <div>
            <span className="button-back">
              <Icon style={{fontSize: '17px'}} type="arrow-left"
                    onClick={() => onToggleAddEditModal(false)}/>
            </span>
            &nbsp;{(isEdit ? "Editar caso " : "Crear caso ") + (isCytology ? "de citología vaginal" : "quirúrgico")}
          </div>
        </h1>
        <Divider/>
        {caseDetail ? caseDetail.id : ""}

        <Form>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Información general" key="1">
              <Row gutter={[24]}>
                <Col md={12}>
                  <Item className="form-item" label="Nombre(s)">
                    {getFieldDecorator("patient.names", {
                      initialValue: isEdit ? caseDetail.patient.names : null,
                      rules: [{required: true, message: "Nombre requerido", whitespace: true}]
                    })(
                      <Input type={"text"}/>
                    )}
                  </Item>
                </Col>
                <Col md={12}>
                  <Item className="form-item" label="Apellido(s)">
                    {getFieldDecorator("patient.surnames", {
                      initialValue: isEdit ? caseDetail.patient.surnames : null,
                      rules: [{required: true, message: "Apellido requerido", whitespace: true}]
                    })(
                      <Input type={"text"}/>
                    )}
                  </Item>
                </Col>
                <Col md={12}>
                  <Item className="form-item" label="Tipo de documento">
                    {getFieldDecorator("patient.identificationType", {
                      initialValue: isEdit ? caseDetail.patient.identificationType : IDENTIFICATION_TYPE.CC,
                      rules: [{required: true, message: "Tipo de documento requerido"}]
                    })(
                      <Select>
                        {casesParams.identificationTypes.map(identificationType =>
                          <Option key={identificationType.id} value={parseInt(identificationType.id)}>
                            {identificationType.value}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col md={12}>
                  <Item className="form-item" label="Documento">
                    {getFieldDecorator("patient.identification", {
                      getValueFromEvent: (e) => {
                        if (identificationTypeId === 1 || identificationTypeId === 3) {
                          let value = parseInt(e.currentTarget.value);
                          if (!isNaN(value))
                            return value.toString()
                        } else {
                          return e.currentTarget.value;
                        }
                      },
                      initialValue: isEdit ? caseDetail.patient.identification : null,
                      rules: [
                        {required: true, message: "Identificación requerida"},
                        {
                          message: "Solo se aceptan caracteres alfanuméricos",
                          pattern: new RegExp(/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/)
                        }
                      ]
                    })(
                      <Input/>
                    )}
                  </Item>
                </Col>
              </Row>
              <Col md={12}>
                <Item className="form-item" label="Fecha de nacimiento">
                  {getFieldDecorator("patient.birthday", {
                    initialValue: isEdit ? (caseDetail.patient.birthday !== null ? moment.utc(caseDetail.patient.birthday).local() : null) : null,
                    rules: [{required: false, message: "Este campo es requerido"}]
                  })(
                    <DatePicker format={"DD/MM/YYYY"} onChange={(value) => this.handleChangeBirthday(value)}
                                allowClear={false}/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Row>
                  <Col xs={6}>
                    <Item className="form-item" label="Edad">
                      {getFieldDecorator("patient.age", {
                        getValueFromEvent: (e) => {
                          let value = parseInt(e.currentTarget.value);
                          if (!isNaN(value))
                            return value
                        },
                        initialValue: isEdit ? (caseDetail.patient.age === 0 ? null : caseDetail.patient.age) : null,
                        rules: [
                          {required: false, message: "Edad requerida"},
                          {type: "number", min: "0"},
                        ]
                      })(
                        <Input className={"right-align"}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={18}>
                    <Item className="form-item item-no-label">
                      {getFieldDecorator("patient.ageMeasuringUnit", {
                        initialValue: isEdit ? (caseDetail.patient.ageMeasuringUnit === 0 ? null : caseDetail.patient.ageMeasuringUnit) : null,
                        rules: [{required: patientAgeValue, message: "Unidad de edad requerida"}]
                      })(
                        <Select>
                          {casesParams.ageMeasuringUnits.map(ageMeasuringUnit =>
                            <Option key={ageMeasuringUnit.id} value={parseInt(ageMeasuringUnit.id)}>
                              {ageMeasuringUnit.value}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                </Row>
              </Col>
              {!isCytology &&
                <Col md={12}>
                  <Item className="form-item" label="Género">
                    {getFieldDecorator("patient.gender", {
                      initialValue: isEdit ? caseDetail.patient.gender : null,
                      rules: [{required: true, message: "Género requerido"}]
                    })(
                      <Select>
                        {casesParams.genders.map(gender =>
                          <Option key={gender.id} value={parseInt(gender.id)}>
                            {gender.value}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
              }
              <Col md={12}>
                <Item className="form-item" label="Dirección">
                  {getFieldDecorator("patient.address", {
                    initialValue: isEdit ? caseDetail.patient.address : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <Input type={"text"}/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="Teléfono">
                  {getFieldDecorator("patient.phone", {
                    getValueFromEvent: (e) => {
                      let value = parseInt(e.currentTarget.value);
                      if (!isNaN(value))
                        return value.toString();
                    },
                    initialValue: isEdit ? caseDetail.patient.phone : null,
                    rules: [
                      {required: false, message: " "}
                    ]
                  })(
                    <Input/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="EPS">
                  {getFieldDecorator("patient.eps", {
                    initialValue: isEdit ? caseDetail.patient.eps : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <Input type={"text"}/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="Régimen">
                  {getFieldDecorator("patient.regime", {
                    initialValue: isEdit ? caseDetail.patient.regime : null,
                    rules: [{required: false, message: "Régimen requerido"}]
                  })(
                    <Select>
                      {casesParams.regimes && casesParams.regimes.map(regime =>
                        <Option key={regime.id} value={parseInt(regime.id)}>
                          {regime.value}
                        </Option>
                      )}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="Ciudad">
                  {getFieldDecorator("patient.city", {
                    initialValue: isEdit ? caseDetail.patient.city : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <Input type={"text"}/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="Doctor remitente">
                  {getFieldDecorator("docSender", {
                    initialValue: isEdit ? caseDetail.docSender : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                  )}
                </Item>
              </Col>
              <Col md={24}>
                <Item className="form-item" label="Observación">
                  {getFieldDecorator("observation", {
                    initialValue: isEdit ? caseDetail.observation : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                              disabled={isAdminAndCompletedStatus}/>
                  )}
                </Item>
              </Col>
              {!isCytology &&
                <Col md={24}>
                  <Item className="form-item" label="Diagnóstico clínico">
                    {getFieldDecorator("clinicalDiagnosis", {
                      initialValue: isEdit ? caseDetail.clinicalDiagnosis : null,
                      rules: [{required: false, message: " ", whitespace: true}]
                    })(
                      <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
              }
              <Col md={12}>
                <Item className="form-item" label="Fecha de recepción">
                  {getFieldDecorator("receptionDate", {
                    initialValue: isEdit ? (caseDetail.receptionDate !== null ? moment.utc(caseDetail.receptionDate).local() : null) : null,
                    rules: [{required: true, message: "Fecha requerida"}]
                  })(
                    <DatePicker format={"DD/MM/YYYY"} allowClear={false} disabled={!isAdmin && completedStatus}/>
                  )}
                </Item>
              </Col>
              <Col md={12}>
                <Item className="form-item" label="Toma de la muestra">
                  {getFieldDecorator("specimenDate", {
                    initialValue: isEdit ? (caseDetail.specimenDate !== null ? moment.utc(caseDetail.specimenDate).local() : null) : null,
                    rules: [{required: true, message: "Fecha requerida"}]
                  })(
                    <DatePicker format={"DD/MM/YYYY"} allowClear={false} disabled={!isAdmin && completedStatus}/>
                  )}
                </Item>
              </Col>
              {!isEdit &&
                <Col xs={24} md={12}>
                  <Item className="form-item margin-top-checkbox" label="">
                    {getFieldDecorator("withSerial", {
                      initialValue: false,
                      valuePropName: "checked",
                    })(
                      <Checkbox disabled={isAdminAndCompletedStatus}>Número de caso manual</Checkbox>
                    )}
                  </Item>
                </Col>
              }
              <Col md={12}>
                <Item className="form-item" label="Número de caso">
                  {getFieldDecorator("serial", {
                    getValueFromEvent: (e) => {
                      return e.currentTarget.value.replaceAll(/[^0-9]/g, "");
                    },
                    initialValue: isEdit ? caseDetail.serial : null,
                    rules: [{required: false, message: " ", whitespace: true}]
                  })(
                    <Input disabled={(isAdminAndCompletedStatus || !getFieldValue('withSerial')) ?? true} type={"text"}
                           className={'black-text-disabled-input'}/>
                  )}
                </Item>
              </Col>
              {!isCytology &&
                <Col md={12}>
                  <Item className="form-item" label="Número de bloques">
                    {getFieldDecorator("blocksNumber", {
                      getValueFromEvent: (e) => {
                        let value = parseInt(e.currentTarget.value);
                        if (!isNaN(value))
                          return value.toString();
                      },
                      initialValue: isEdit ? caseDetail.blocksNumber : null,
                      rules: [{required: true, message: "Número de bloques requerido"}]
                    })(
                      <Input disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
              }
              {!isCytology &&
                <Col md={12}>
                  <Item className="form-item" label="Número de Láminas HYE">
                    {getFieldDecorator("hyeSlidesNumber", {
                      getValueFromEvent: (e) => {
                        let value = parseInt(e.currentTarget.value);
                        if (!isNaN(value))
                          return value.toString();
                      },
                      initialValue: isEdit ? caseDetail.hyeSlidesNumber : null,
                      rules: [{required: true, message: "Número de Láminas HYE requerido"}]
                    })(
                      <Input disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
              }
              {!isCytology &&
                <Col md={12}>
                  <Item className="form-item" label="Número de Láminas Inmuno">
                    {getFieldDecorator("immunoSlidesNumber", {
                      getValueFromEvent: (e) => {
                        let value = parseInt(e.currentTarget.value);
                        if (!isNaN(value))
                          return value.toString();
                      },
                      initialValue: isEdit ? caseDetail.immunoSlidesNumber : null,
                      rules: [{required: true, message: "Número de Láminas Inmuno requerido"}]
                    })(
                      <Input disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
              }
              {!isCytology &&
                <Col md={12}>
                  <Item className="form-item" label="Número interno">
                    {getFieldDecorator("internalNumber", {
                      getValueFromEvent: ({currentTarget}) => {
                        const isAlphanumeric = (/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9]*$/).test(currentTarget.value);
                        if (isAlphanumeric) return currentTarget.value;
                        else return getFieldValue('internalNumber');
                      },
                      initialValue: isEdit ? caseDetail.internalNumber : null,
                      rules: [{required: false}]
                    })(
                      <Input disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
              }
            </Panel>

            <Panel header="Información de facturación" key="2">
              <Row gutter={[24]}>
                <Col xs={24} sm={12} md={8}>
                  <Item className="form-item" label="Cliente">
                    {getFieldDecorator("clientId", {
                      initialValue: isEdit ? (caseDetail.specimenContractCases.length > 0 && (caseDetail.specimenContractCases[0].specimenContract.contract.campus.client.clientId)) : null,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                      <Select disabled={isEdit} showSearch optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => this.onChangeSelect(value, DATA_SELECTS.client)}>
                        {casesParams.clients.map(client =>
                          <Option key={client.clientId} value={client.clientId}>
                            {client.name}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Item className="form-item" label="Sede">
                    {getFieldDecorator("campusId", {
                      initialValue: isEdit ? (caseDetail.specimenContractCases.length > 0 && (caseDetail.specimenContractCases[0].specimenContract.contract.campus.campusId)) : null,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                      <Select disabled={isEdit || !campusValue} showSearch optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => this.onChangeSelect(value, DATA_SELECTS.campus)}>
                        {campusParams.map(campus =>
                          <Option key={campus.campusId} value={campus.campusId}>
                            {campus.name}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Item className="form-item" label="Contrato">
                    {getFieldDecorator("contractId", {
                      initialValue: isEdit ? (caseDetail.specimenContractCases.length > 0 && (caseDetail.specimenContractCases[0].specimenContract.contract.contractId)) : null,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                      <Select disabled={isEdit || !contractValue} showSearch optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => this.onChangeSelect(value, DATA_SELECTS.contract)}>
                        {contractParams.map(contract =>
                          <Option key={contract.contractId} value={contract.contractId}>
                            {contract.name}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} className="center-align">
                  <Button type={"primary"} disabled={!specimenContractValue}
                          onClick={() => this.onToggleAddEditSpecimenContract(true)}>Agregar espécimen</Button>
                </Col>
                <Col xs={24} className={"specimen-container"}>
                  <List dataSource={specimensContractCase}
                        renderItem={item => (
                          <List.Item key={item.specimenContractId}>
                            <List.Item.Meta
                              title={<b>{"(" + item.cupsCode + ") " + item.name}</b>}
                              description={
                                <div>
                                  Precio: ${thousandsNumberSeparator(item.customTariff)} <br/>
                                  Cantidad: {item.quantity} <br/>
                                  {!isCytology && `Muestra enviada: ${item.sampleSent}`} <br/>
                                </div>
                              }
                            />
                            <div>
                              <Tooltip title={"Editar"}>
                                <Icon type={"edit"} style={{fontSize: iconSize}}
                                      onClick={() => this.onToggleAddEditSpecimenContract(true, null, item)}/>
                              </Tooltip>
                              &nbsp;
                              <Tooltip title={"Eliminar"}>
                                <Icon type={"delete"} style={{fontSize: iconSize}}
                                      onClick={() => this.onDeleteSpecimenCase(item.specimenContractId)}/>
                              </Tooltip>
                            </div>
                          </List.Item>
                        )}
                  />
                </Col>
              </Row>
            </Panel>

            {isCytology && caseDetail.cytologyHistory &&
              <Panel header="Antecedentes" key="3">
                <Row gutter={[24]}>
                  <Col xs={24} md={24}>
                    <Item className="form-item" label="">
                      {getFieldDecorator("cytologyHistory.isPregnant", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.isPregnant : false,
                        valuePropName: "checked",
                      })(
                        <Checkbox disabled={isAdminAndCompletedStatus}>Está embarazada</Checkbox>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Fecha de último parto">
                      {getFieldDecorator("cytologyHistory.childbirthLastDate", {
                        initialValue: isEdit ? (caseDetail.cytologyHistory.childbirthLastDate !== null ? moment.utc(caseDetail.cytologyHistory.childbirthLastDate).local() : null) : null,
                        rules: [{required: false}]
                      })(
                        <DatePicker format={"DD/MM/YYYY"} allowClear={false} disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Número de partos">
                      {getFieldDecorator("cytologyHistory.childbirthNumber", {
                        getValueFromEvent: (e) => {
                          let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                          if (!isNaN(value))
                            return value
                        },
                        initialValue: isEdit ? caseDetail.cytologyHistory.childbirthNumber : 0,
                        rules: [
                          {required: false, message: "Número de partos requerido"},
                          {type: "number", min: "0"},
                        ]
                      })(
                        <Input disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Número de abortos">
                      {getFieldDecorator("cytologyHistory.abortionNumber", {
                        getValueFromEvent: (e) => {
                          let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                          if (!isNaN(value))
                            return value
                        },
                        initialValue: isEdit ? caseDetail.cytologyHistory.abortionNumber : 0,
                        rules: [
                          {required: false, message: "Número de abortos requerido"},
                          {type: "number", min: "0"},
                        ]
                      })(
                        <Input disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Edad primer embarazo">
                      {getFieldDecorator("cytologyHistory.ageFirstPregnancy", {
                        getValueFromEvent: (e) => {
                          let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                          if (!isNaN(value))
                            return value
                        },
                        initialValue: isEdit ? caseDetail.cytologyHistory.ageFirstPregnancy : null,
                        rules: [
                          {required: false, message: "Edad primer embarazo requerido"},
                          {type: "number", min: "0"},
                        ]
                      })(
                        <Input disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Última citología">
                      {getFieldDecorator("cytologyHistory.lastCytologyDate", {
                        initialValue: isEdit ? (caseDetail.cytologyHistory.lastCytologyDate !== null ? moment.utc(caseDetail.cytologyHistory.lastCytologyDate).local() : null) : null,
                        rules: [{required: false}]
                      })(
                        <DatePicker format={"DD/MM/YYYY"} allowClear={false} disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Resultado (última citología)">
                      {getFieldDecorator("cytologyHistory.lastCytologyResult", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.lastCytologyResult : null,
                        rules: [{required: false, whitespace: true}]
                      })(
                        <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Fecha última regla">
                      {getFieldDecorator("cytologyHistory.lastMenstruationDate", {
                        initialValue: isEdit ? (caseDetail.cytologyHistory.lastMenstruationDate !== null ? moment.utc(caseDetail.cytologyHistory.lastMenstruationDate).local() : null) : null,
                        rules: [{required: false, message: "Fecha requerida"}]
                      })(
                        <DatePicker format={"DD/MM/YYYY"} allowClear={false} disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item margin-top-checkbox" label="">
                      {getFieldDecorator("cytologyHistory.isHumanPapillomaVaccinated", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.isHumanPapillomaVaccinated : false,
                        valuePropName: "checked",
                      })(
                        <Checkbox disabled={isAdminAndCompletedStatus}>Vacunada contra el Papiloma Humano</Checkbox>
                      )}
                    </Item>
                    <br/>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Ciclo menstrual">
                      {getFieldDecorator("cytologyHistory.menstruationCycle", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.menstruationCycle : null,
                        rules: [{required: false}]
                      })(
                        <Select disabled={isAdminAndCompletedStatus}>
                          {casesParams.menstrualCycles.map(menstrualCycle =>
                            <Option key={menstrualCycle.id} value={parseInt(menstrualCycle.id)}>
                              {menstrualCycle.value}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Edad primera relación sexual">
                      {getFieldDecorator("cytologyHistory.ageFirstSexualIntercourse", {
                        getValueFromEvent: (e) => {
                          let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                          if (!isNaN(value))
                            return value
                        },
                        initialValue: isEdit ? caseDetail.cytologyHistory.ageFirstSexualIntercourse : 0,
                        rules: [
                          {required: false, message: "Edad primera relación sexual requerido"},
                          {type: "number", min: "0"},
                        ]
                      })(
                        <Input disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Método de planificación">
                      {getFieldDecorator("cytologyHistory.planningMethod", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.planningMethod : null,
                        rules: [{required: false}]
                      })(
                        <Select disabled={isAdminAndCompletedStatus}>
                          {casesParams.planningMethods.map(planningMethod =>
                            <Option key={planningMethod.id} value={parseInt(planningMethod.id)}>
                              {planningMethod.value}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Otro">
                      {getFieldDecorator("cytologyHistory.otherPlanningMethod", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.otherPlanningMethod : null,
                        rules: [{
                          required: !(getFieldValue('cytologyHistory.planningMethod') !== OTHER_PLANNING_METHOD.other),
                          message: "Campo requerido",
                          whitespace: true
                        }]
                      })(
                        <Input disabled={getFieldValue('cytologyHistory.planningMethod') !== OTHER_PLANNING_METHOD.other
                          || isAdminAndCompletedStatus}
                               type={"text"}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item" label="Tiempo de planificación">
                      {getFieldDecorator("cytologyHistory.planningTime", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.planningTime : null,
                        rules: [{required: false, whitespace: true}]
                      })(
                        <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item className="form-item margin-top-checkbox" label="">
                      {getFieldDecorator("cytologyHistory.withEstrogenTreatment", {
                        initialValue: isEdit ? caseDetail.cytologyHistory.withEstrogenTreatment : false,
                        valuePropName: "checked",
                      })(
                        <Checkbox disabled={isAdminAndCompletedStatus}>Tratamiento con extrógenos</Checkbox>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24}>
                    <Divider orientation="left">Procedimientos anteriores:</Divider>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Biopsia">
                        {getFieldDecorator("cytologyHistory.biopsy", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.biopsy : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Radioterapia">
                        {getFieldDecorator("cytologyHistory.radiotherapy", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.radiotherapy : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Crioterapia">
                        {getFieldDecorator("cytologyHistory.cryotherapy", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.cryotherapy : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Histerectomía">
                        {getFieldDecorator("cytologyHistory.hysterectomy", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.hysterectomy : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Exéresis">
                        {getFieldDecorator("cytologyHistory.excision", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.excision : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Cauterización">
                        {getFieldDecorator("cytologyHistory.cauterization", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.cauterization : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Conización">
                        {getFieldDecorator("cytologyHistory.conization", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.conization : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                      <Item className="form-item" label="Otros">
                        {getFieldDecorator("cytologyHistory.others", {
                          initialValue: isEdit ? caseDetail.cytologyHistory.others : null,
                          rules: [{required: false, whitespace: true}]
                        })(
                          <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                  </Col>
                </Row>
              </Panel>
            }
            {isCytology
              ? caseDetail.cytologyAnalysisResult &&
              <Panel header="Resultados de análisis" key="4">
                <Col md={24}>
                  <Item className="form-item" label="Aspecto del cuello">
                    {getFieldDecorator('cytologyAnalysisResult.neckAspectsIds', {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.neckAspectsIds : [],
                    })(
                      <Checkbox.Group style={{width: '100%'}} disabled={isAdminAndCompletedStatus}>
                        <Row>
                          {casesParams.neckAspects.map(neckAspect =>
                            <Col xs={24} sm={12} md={8} key={neckAspect.id}>
                              <Checkbox value={neckAspect.id}>{neckAspect.value}</Checkbox>
                            </Col>
                          )}
                        </Row>
                      </Checkbox.Group>,
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item" label="Seguimiento">
                    {getFieldDecorator("cytologyAnalysisResult.tracingId", {
                      initialValue: isEdit ? (caseDetail.cytologyAnalysisResult.tracingId !== 0 ? caseDetail.cytologyAnalysisResult.tracingId.toString() : null) : null,
                      rules: [{required: false, message: ""}]
                    })(
                      <Select disabled={isAdminAndCompletedStatus}>
                        {casesParams.tracings.map(tracing =>
                          <Option key={tracing.id} value={tracing.id}>
                            {tracing.value}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col md={12}>
                  <Item className="form-item" label="Calidad de la muestra">
                    {getFieldDecorator("cytologyAnalysisResult.sampleQualityId", {
                      initialValue: isEdit ? (caseDetail.cytologyAnalysisResult.sampleQualityId !== 0 ? caseDetail.cytologyAnalysisResult.sampleQualityId.toString() : null) : null,
                      rules: [{required: false, message: ""}]
                    })(
                      <Select disabled={isAdminAndCompletedStatus}>
                        {casesParams.sampleQualities.map(sampleQuality =>
                          <Option key={sampleQuality.id} value={sampleQuality.id}>
                            {sampleQuality.value}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col md={12}>
                  <Item className="form-item" label="Comentarios de la muestra">
                    {getFieldDecorator("cytologyAnalysisResult.sampleQualityComments", {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.sampleQualityComments : null,
                      rules: [{required: false, message: " ", whitespace: true}]
                    })(
                      <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item margin-small" label="Otros hallazgos no neoplásicos">
                    {getFieldDecorator('cytologyAnalysisResult.otherFindingsIds', {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.otherFindingsIds : [],
                    })(
                      <Checkbox.Group style={{width: '100%'}} disabled={isAdminAndCompletedStatus}>
                        <Row>
                          {casesParams.otherFindings.map(findings =>
                            <Col span={24} key={findings.id}>
                              <Checkbox value={findings.id}>{findings.value}</Checkbox>
                            </Col>
                          )}
                        </Row>
                      </Checkbox.Group>,
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item" label="Otros">
                    {getFieldDecorator("cytologyAnalysisResult.otherFindings", {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.otherFindings : null,
                      rules: [{required: false, message: " ", whitespace: true}]
                    })(
                      <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item margin-small" label="Microorganismos">
                    {getFieldDecorator('cytologyAnalysisResult.microorganismsIds', {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.microorganismsIds : [],
                    })(
                      <Checkbox.Group style={{width: '100%'}} disabled={isAdminAndCompletedStatus}>
                        <Row>
                          {casesParams.microorganisms.map(microorganism =>
                            <Col span={24} key={microorganism.id}>
                              <Checkbox value={microorganism.id}>{microorganism.value}</Checkbox>
                            </Col>
                          )}
                        </Row>
                      </Checkbox.Group>,
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item" label="Otros">
                    {getFieldDecorator("cytologyAnalysisResult.othersMicroorganisms", {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.othersMicroorganisms : null,
                      rules: [{required: false, message: " ", whitespace: true}]
                    })(
                      <Input type={"text"} disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item" label="Categorización general">
                    {getFieldDecorator("cytologyAnalysisResult.generalCategorizationId", {
                      initialValue: isEdit ? (caseDetail.cytologyAnalysisResult.generalCategorizationId !== 0 ? caseDetail.cytologyAnalysisResult.generalCategorizationId.toString() : null) : null,
                      rules: [{required: false, message: " "}]
                    })(
                      <Select onChange={(value) => (
                        parseInt(value) === GENERAL_CATEGORIZATION.epithelialCellAbnormalities &&
                        setFieldsValue({[`isPositive`]: true})
                      )}
                              disabled={isAdminAndCompletedStatus}
                      >
                        {casesParams.generalCategorizations.map(generalCategorization =>
                          <Option key={generalCategorization.id} value={generalCategorization.id}>
                            {generalCategorization.value}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item margin-small" label="Anormalidades de células escamosas">
                    {getFieldDecorator('cytologyAnalysisResult.squamousCellAbnormalitiesIds', {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.squamousCellAbnormalitiesIds : [],
                    })(
                      <Checkbox.Group style={{width: '100%'}} disabled={isAdminAndCompletedStatus}>
                        <Row>
                          {casesParams.squamousCells.map(squamousCell =>
                            <Col span={24} key={squamousCell.id}>
                              <Checkbox value={squamousCell.id}>{squamousCell.value}</Checkbox>
                            </Col>
                          )}
                        </Row>
                      </Checkbox.Group>,
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item margin-small" label="Anormalidades de células glandulares">
                    {getFieldDecorator('cytologyAnalysisResult.glandularCellAbnormalitiesIds', {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.glandularCellAbnormalitiesIds : [],
                    })(
                      <Checkbox.Group style={{width: '100%'}} disabled={isAdminAndCompletedStatus}>
                        <Row>
                          {casesParams.glandularCells.map(glandularCell =>
                            <Col span={24} key={glandularCell.id}>
                              <Checkbox value={glandularCell.id}>{glandularCell.value}</Checkbox>
                            </Col>
                          )}
                        </Row>
                      </Checkbox.Group>,
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item" label="Observaciones">
                    {getFieldDecorator("cytologyAnalysisResult.analysisObservations", {
                      initialValue: isEdit ? caseDetail.cytologyAnalysisResult.analysisObservations : null,
                      rules: [{required: false, message: " ", whitespace: true}]
                    })(
                      <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
                <Col md={24}>
                  <Item className="form-item"
                        label={isEdit ? "Citólogo asignado" : "Asignar citólogo"}>
                    {getFieldDecorator("cytologistId", {
                      initialValue: isEdit ? (caseDetail.cytologist !== null ? caseDetail.cytologist.specialistId : null) : null,
                      rules: [{required: isEdit, message: "Requiere citólogo", whitespace: true}]
                    })(
                      <Select disabled={isAdminAndCompletedStatus}>
                        {casesParams.cytologists.map(cytologist =>
                          <Option key={cytologist.specialistId} value={cytologist.specialistId}
                                  disabled={isSpecialist
                                    && casesParams.cytologists.map(c => c.specialistId).includes(userId)
                                    && userId !== cytologist.specialistId}>
                            {cytologist.names + " " + cytologist.surnames}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col md={6} xs={8}>
                  <Item className="form-item" label="">
                    {getFieldDecorator("isPositive", {
                      valuePropName: 'checked',
                      initialValue: isEdit ? caseDetail.isPositive : false,
                      rules: [{required: false, message: " "}]
                    })(
                      <Checkbox disabled={isAdminAndCompletedStatus}>Positivo</Checkbox>
                    )}
                  </Item>
                </Col>
                {!getFieldValue("isPositive") &&
                  <Col md={6} xs={8}>
                    <Item className="form-item" label="">
                      {getFieldDecorator("negativeControl", {
                        valuePropName: 'checked',
                        initialValue: isEdit ? caseDetail.negativeControl : false,
                        rules: [{required: false, message: " "}]
                      })(
                        <Checkbox disabled={isAdminAndCompletedStatus}>Control negativo</Checkbox>
                      )}
                    </Item>
                  </Col>
                }
                <Col md={24}>
                  <Item className="form-item"
                        label={isEdit ? "Patólogo asignado" : "Asignar patólogo"}>
                    {getFieldDecorator("pathologistId", {
                      initialValue: isEdit ? (caseDetail.pathologist !== null ? caseDetail.pathologist.specialistId : null) : null,
                      rules: [{
                        required: positiveValueOrNegativeControl !== null ? positiveValueOrNegativeControl : false,
                        message: " ",
                        whitespace: true
                      }]
                    })(
                      <Select disabled={!positiveValueOrNegativeControl || isAdminAndCompletedStatus}>
                        {casesParams.pathologists.map(pathologist =>
                          <Option key={pathologist.specialistId} value={pathologist.specialistId}
                                  disabled={isSpecialist
                                    && casesParams.pathologists.map(p => p.specialistId).includes(userId)
                                    && userId !== pathologist.specialistId}>
                            {pathologist.names + " " + pathologist.surnames}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                {isEdit &&
                  <Col md={24}>
                    <Item className="form-item" label={"Estado"}>
                      {getFieldDecorator("caseStatusId", {
                        initialValue: isEdit ? caseDetail.caseStatusId : null,
                        rules: [{required: true, message: " "}]
                      })(
                        <Select disabled={isAdminAndCompletedStatus}>
                          {casesParams.caseStatuses.map(caseStatus =>
                            <Option key={caseStatus.id} value={caseStatus.id}
                                    disabled={isStatusComplete(caseDetail.caseStatus, caseStatus, isSpecialistAndAssigned)}>
                              {caseStatus.value}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                }
              </Panel>
              :
              <Panel header="Resultados de análisis" key="5">
                <Col md={24}>
                  <Item className="form-item" label="Descripción macroscópica">
                    {getFieldDecorator("macroscopicDescription", {
                      initialValue: isEdit && caseDetail.macroscopicDescription
                        ? caseDetail.macroscopicDescription.replaceAll("<br/>", "\n") : null,
                      rules: [{required: true, message: " ", whitespace: true}]
                    })(
                      <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                disabled={isAdminAndCompletedStatus}/>
                    )}
                  </Item>
                </Col>
                {isEdit &&
                  <div>
                    <Col md={24}>
                      <Item className="form-item" label="Descripción microscópica">
                        {getFieldDecorator("microscopicDescription", {
                          initialValue: isEdit && caseDetail.microscopicDescription
                            ? caseDetail.microscopicDescription.replaceAll("<br/>", "\n") : null,
                          rules: [{required: false, message: " ", whitespace: true}]
                        })(
                          <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                    disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col md={24}>
                      <Item className="form-item" label="Diagnóstico">
                        {getFieldDecorator("diagnosis", {
                          initialValue: isEdit ? caseDetail.diagnosis : null,
                          rules: [{required: false, message: " ", whitespace: true}]
                        })(
                          <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                    disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col md={24}>
                      <Item className="form-item" label="Comentarios">
                        {getFieldDecorator("comments", {
                          initialValue: isEdit ? caseDetail.comments : null,
                          rules: [{required: false, message: " ", whitespace: true}]
                        })(
                          <TextArea spellCheck={true} autoSize={{minRows: 2, maxRows: 6}}
                                    disabled={isAdminAndCompletedStatus}/>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24}>
                      <Item className="form-item" label="">
                        {getFieldDecorator("noShortDiagnosis", {
                          initialValue: caseDetail.shortDiagnosis !== null ? (caseDetail.shortDiagnosis === "") : true,
                          valuePropName: "checked",
                        })(
                          <Checkbox disabled={isAdminAndCompletedStatus}>No asignar código CIE-10</Checkbox>
                        )}
                      </Item>
                    </Col>
                    <Col md={24}>
                      <Item className="form-item" label="Diagnóstico corto (CIE-10)">
                        {getFieldDecorator("shortDiagnosis", {
                          initialValue: isEdit ? caseDetail.shortDiagnosis : null,
                          rules: [{required: shortDiagnosisIsRequired, message: " ", whitespace: true}]
                        })(
                          <Select disabled={disableShortDiagnosis || isAdminAndCompletedStatus}
                                  placeholder={"Introduzca un texto para buscar (mínimo 3 letras)"}
                                  showArrow={false}
                                  onSearch={this.handleSearch}
                                  notFoundContent={null}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            {cie10List.map(item =>
                              <Option key={item.id} value={item.id + ' - ' + item.value}>
                                {item.id + ' - ' + item.value}
                              </Option>
                            )}
                          </Select>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Item className="form-item" label="">
                        {getFieldDecorator("isOncological", {
                          valuePropName: 'checked',
                          initialValue: isEdit ? caseDetail.isOncological : null,
                          rules: [{required: false, message: " "}]
                        })(
                          <Checkbox disabled={isAdminAndCompletedStatus}>Oncológico</Checkbox>
                        )}
                      </Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Item className="form-item" label="">
                        {getFieldDecorator("isEpidemiologicalSurveillance", {
                          valuePropName: 'checked',
                          initialValue: isEdit ? caseDetail.isEpidemiologicalSurveillance : null,
                          rules: [{required: false, message: " "}]
                        })(
                          <Checkbox disabled={isAdminAndCompletedStatus}>Vigilancia epidemiológica</Checkbox>
                        )}
                      </Item>
                    </Col>
                    {!isCytology &&
                      <Col xs={24} md={12}>
                        <Item className="form-item" label="">
                          {getFieldDecorator("isComplex", {
                            valuePropName: 'checked',
                            initialValue: isEdit ? caseDetail.isComplex : null,
                            rules: [{required: false, message: ""}]
                          })(
                            <Checkbox disabled={isAdminAndCompletedStatus}>Es caso complejo</Checkbox>
                          )}
                        </Item>
                      </Col>
                    }
                    <Divider/>
                  </div>
                }
                <Col md={24}>
                  <Item className="form-item"
                        label={isEdit ? "Patólogo asignado" : "Asignar patólogo"}>
                    {getFieldDecorator("pathologistId", {
                      initialValue: isEdit ? (caseDetail.pathologist !== null ? caseDetail.pathologist.specialistId : null) : null,
                      rules: [{required: isEdit, message: " ", whitespace: true}]
                    })(
                      <Select disabled={isAdminAndCompletedStatus}>
                        {casesParams.pathologists.map(pathologist =>
                          <Option key={pathologist.specialistId} value={pathologist.specialistId}
                                  disabled={isSpecialist
                                    && casesParams.pathologists.map(p => p.specialistId).includes(userId)
                                    && userId !== pathologist.specialistId}>
                            {pathologist.names + " " + pathologist.surnames}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                {isEdit &&
                  <Col md={24}>
                    <Item className="form-item" label={"Estado"}>
                      {getFieldDecorator("caseStatusId", {
                        initialValue: isEdit ? caseDetail.caseStatusId : null,
                        rules: [{required: true, message: " "}]
                      })(
                        <Select disabled={isAdminAndCompletedStatus}>
                          {casesParams.caseStatuses.map(caseStatus =>
                            <Option key={caseStatus.id} value={caseStatus.id}
                                    disabled={isStatusComplete(caseDetail.caseStatus, caseStatus, isSpecialistAndAssigned)}>
                              {caseStatus.value}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                }
              </Panel>
            }
          </Collapse>
        </Form>

        <br/>

        <Row>
          <Col className="right-align">
            <Button type={"default"} key="back" onClick={() => onToggleAddEditModal(false)}>
              Cancelar
            </Button>
            &nbsp;&nbsp;
            {isEdit && !isAdminAndCompletedStatus && completedStatus && !isSpecialistAndAssigned
              ? <Button key="submit" disabled={true}>Editar</Button>
              :
              <Button key="submit" type="primary" onClick={() => this.validateForm()}>
                {isEdit ? "Editar" : "Agregar"}
              </Button>
            }
          </Col>
        </Row>
      </Row>
    );
  }
}

AddEditModal.propTypes = {
  action: PropTypes.string,
  isCytology: PropTypes.bool,
  caseToEdit: PropTypes.object,
  casesParams: PropTypes.object,
  filterVm: PropTypes.object,
  onToggleAddEditModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    casesParams: state.casesReducer.casesParams,
    campusParams: state.casesReducer.campusParams,
    contractParams: state.casesReducer.contractParams,
    specimenContractParams: state.casesReducer.specimenContractParams,
    caseDetail: state.casesReducer.caseDetail,
    pagination: state.casesReducer.pagination,
    cie10List: state.casesReducer.cie10List,
  }
};

const mapDispatchToProps = {
  getParams,
  add,
  getCampusAllByClientId,
  getContractAllByCampusId,
  getSpecimensContractByContractId,
  getCaseDetail,
  update,
  setInitialCaseDetail,
  getCie10,
  cleanCie10List,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddEditModal));