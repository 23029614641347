//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Divider, Button } from "antd";
import { connect } from "react-redux";

//Services
import {getAll, add, update, remove} from "../../store/redux/actions/headquarters/headquartersActions";

//Components
import AddModal from "../subComponents/AddModal";
import EditModal from "../subComponents/EditModal";
import CollapseList from "../subComponents/CollapseList";

//Constants
import { MENU_CONSTANTS } from "../../utils/constants";

class Headquarters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      addModalVisible: false,
      editModalVisible: false,
      selectedItem: null,
      pagination: {
        current: 1,
        pageSize: 20,
      },
    };
    this.onToggleAddModal = this.onToggleAddModal.bind(this);
    this.onToggleEditModal = this.onToggleEditModal.bind(this);
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        this.props.getAll();
      }
    });
  }
  //#region "Pagination"
  onChangePaginationConfig (current, pageSize) {
    this.setState({ pagination: {
        current: current,
        pageSize: pageSize
      }
    });
  };
  //#endregion

  //#region "Modal"
  onToggleAddModal (isVisible) {
    this.setState({ addModalVisible: isVisible });
  };
  onToggleEditModal (isVisible, item = null) {
    this.setState({
      editModalVisible: isVisible,
      selectedItem: item
    });
  };
  //#endregion

  render() {
    const { pagination, addModalVisible, editModalVisible, selectedItem } = this.state;
    const { headquarterList, add, update, remove } = this.props;

    return (
      <Row className="main-container section-list headquarters-container">
        {addModalVisible &&
          <AddModal title={"Agregar sede"}
                    section={MENU_CONSTANTS.headquarters}
                    onToggleAddModal={this.onToggleAddModal}
                    addFunction={add} />}

        {editModalVisible &&
          <EditModal title={"Editar sede"} section={MENU_CONSTANTS.headquarters}
                     selectedItem={selectedItem}
                     onToggleEditModal = {this.onToggleEditModal}
                     editFunction={update} />}

        <Col xs={24} className={"content-container"}>
          <h1>Sedes</h1>
          <Divider />
          <Col className="right-align">
            <Button type={"default"} icon={"plus"} onClick={() => this.onToggleAddModal(true)}>
              Agregar sede
            </Button>
          </Col>
          <CollapseList dataList={headquarterList} section={MENU_CONSTANTS.headquarters}
                        current={pagination.current} pageSize={pagination.pageSize}
                        rowKey={"campusId"}
                        onChangePaginationConfig={this.onChangePaginationConfig}
                        deleteItem={remove}
                        onToggleEditModal={this.onToggleEditModal}
          />
        </Col>
      </Row>
    );
  }
}

Headquarters.propTypes = {
  headquarterList: PropTypes.array,
  getAll: PropTypes.func,
  add: PropTypes.func,
  update: PropTypes.func,
  remove: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    headquarterList: state.headquartersReducer.headquarterList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: () => dispatch(getAll()),
    add: (data) => dispatch(add(data)),
    update: (data) => dispatch(update(data)),
    remove: (data) => dispatch(remove(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Headquarters);