//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Button, Select } from "antd";
import { connect } from "react-redux";
import moment from 'moment';

// Services
import {
  getCaseHistoriesByCaseId,
  getCaseHistory,
  resetCaseHistory
} from "../../../store/redux/actions/cases/casesActions";
import RightContainer from "../../subComponents/RightContainer";

// Constants
const { Option } = Select;

class ViewHistoryModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false
    };
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        this.props.getCaseHistoriesByCaseId({ caseId: this.props.itemToView.caseId });
      }
    });
  }

  render() {
    const { onToggleHistoryModal, itemToView, getCaseHistory, caseHistory, caseHistoryVersions } = this.props;
    let historyJson = caseHistory !== null ? JSON.parse(caseHistory.json) : {};
    if(historyJson.isCytology){
      if(historyJson.cytologyAnalysisResult !== null){
        if(historyJson.cytologyAnalysisResult.neckAspectsIds !== null){
          historyJson.cytologyAnalysisResult.neckAspectsIds = historyJson.cytologyAnalysisResult.neckAspectsIds.split(",");
        }
        if(historyJson.cytologyAnalysisResult.otherFindingsIds !== null){
          historyJson.cytologyAnalysisResult.otherFindingsIds = historyJson.cytologyAnalysisResult.otherFindingsIds.split(",");
        }
        if(historyJson.cytologyAnalysisResult.microorganismsIds !== null){
          historyJson.cytologyAnalysisResult.microorganismsIds = historyJson.cytologyAnalysisResult.microorganismsIds.split(",");
        }
        if(historyJson.cytologyAnalysisResult.squamousCellAbnormalitiesIds !== null){
          historyJson.cytologyAnalysisResult.squamousCellAbnormalitiesIds = historyJson.cytologyAnalysisResult.squamousCellAbnormalitiesIds.split(",");
        }
        if(historyJson.cytologyAnalysisResult.glandularCellAbnormalitiesIds !== null){
          historyJson.cytologyAnalysisResult.glandularCellAbnormalitiesIds = historyJson.cytologyAnalysisResult.glandularCellAbnormalitiesIds.split(",");
        }
      }
    }

    return (
      <Modal title={`Historial del caso ${itemToView.serial}`} width={900} closable={false} destroyOnClose={true} visible={true}
             className={"view-history-modal"}
             footer={
               <Button type={"primary"} onClick={() => onToggleHistoryModal(false)}>Aceptar</Button>
             }>
        <Row>
          <Col md={12} xs={24}>
            <Select onChange={(value) => getCaseHistory({ caseHistoryId: value })}
                    placeholder={"Seleccione la versión"}>
              {caseHistoryVersions.map(version =>
                <Option value={version.caseHistoryId} key={version.caseHistoryId}>
                  {version.version} ({moment(version.createdDate).format("DD/MM/YYYY")})
                </Option>
              )}
            </Select>
          </Col>
          {caseHistory !== null &&
            <Col md={24} xs={24} className={"user-name-container"}>
              <b>Usuario:</b> {caseHistory.username}
            </Col>
          }
          {caseHistory !== null &&
            <Col md={24} xs={24}>
              <RightContainer selectedCase={historyJson} isHistory={true}/>
            </Col>
          }
        </Row>
      </Modal>
    );
  }
}

ViewHistoryModal.propTypes = {
  itemToView: PropTypes.object,
  caseHistory: PropTypes.object,
  caseHistoryVersions: PropTypes.array,
  onToggleHistoryModal: PropTypes.func,
  getCaseHistory: PropTypes.func,
  getCaseHistoriesByCaseId: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    caseHistory: state.casesReducer.caseHistory,
    caseHistoryVersions: state.casesReducer.caseHistoryVersions,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCaseHistory: (data) => dispatch(getCaseHistory(data)),
    getCaseHistoriesByCaseId: (data) => dispatch(getCaseHistoriesByCaseId(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHistoryModal);