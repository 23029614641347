//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Upload, Icon, Radio } from "antd";
import { connect } from "react-redux";
import ImgCrop from "antd-img-crop";

//Functions
import { beforeUpload, dummyRequest } from "../../utils/functions";

//Constants
import baseURL from "../../api/baseUrl";

class ImageCrop extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      aspect: 1
    };
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({
          isMounted: false,
        });
      }
    });
  }

  render() {
    const { imageFile, imageUrl, onHandleChangeImage, name, loading, aspect } = this.props;
    const isLogo = name === "Logo";

    return (
     <div>
      <label className={"ant-form-item"}><h3>{name}</h3></label>
       {isLogo
         ? <Upload name="image" listType="picture-card"
                   className={imageUrl ? "image-upload" : "empty-image-upload"}
                   showUploadList={false} beforeUpload={beforeUpload(imageFile)}
                   customRequest={dummyRequest}
                   onChange={onHandleChangeImage}
         >
           {imageUrl ? (
             <Row>
               <Col lg={24} md={24} sm={24} xs={24}>
                 {imageFile
                   ? <img src={imageUrl} alt="Imagen" style={{ width: "100%" }} />
                   : <img src={baseURL + imageUrl} alt="Imagen" style={{ width: "100%" }} />
                 }
                 <p>{ name }</p>
               </Col>
             </Row>
           ) : (
             <div>
               <Icon type={loading ? "loading" : "plus"} />
               <div className="ant-upload-text">Cargar imagen</div>
             </div>
           )}
         </Upload>
         :
         <ImgCrop grid modalOk={"Confirmar"} modalCancel={"Cancelar"} modalTitle={"Editar imagen"}
                  aspect={isLogo ? this.state.aspect : aspect}>
           <Upload name="image" listType="picture-card"
                   className={imageUrl ? "image-upload" : "empty-image-upload"}
                   showUploadList={false} beforeUpload={beforeUpload(imageFile)}
                   customRequest={dummyRequest}
                   onChange={onHandleChangeImage}
           >
             {imageUrl ? (
               <Row>
                 <Col lg={24} md={24} sm={24} xs={24}>
                   {imageFile
                     ? <img src={imageUrl} alt="Imagen" style={{ width: "100%" }} />
                     : <img src={baseURL + imageUrl} alt="Imagen" style={{ width: "100%" }} />
                   }
                   <p>{ name }</p>
                 </Col>
               </Row>
             ) : (
               <div>
                 <Icon type={loading ? "loading" : "plus"} />
                 <div className="ant-upload-text">Cargar imagen</div>
               </div>
             )}
           </Upload>
         </ImgCrop>
       }
     </div>
    );
  }
}

ImageCrop.propTypes = {
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  aspect: PropTypes.number,
  name: PropTypes.string,
  imageFile: PropTypes.object,
  onHandleChangeImage: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCrop);