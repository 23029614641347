// Types
import { specialistsTypes } from '../../types';


// Initial State
const initialState = {
  specialistsList: [],
  specialties: []
};

export default function specialistsReducer (state = initialState, action) {
  switch (action.type) {
    case specialistsTypes.GET_ALL_SPECIALISTS:
      return{
        ...state,
        specialistsList: action.specialistsList
      };
    case specialistsTypes.GET_SPECIALTIES:
      return{
        ...state,
        specialties: action.specialties
      };
    default:
      return state;
  }
}