//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Button, Divider } from "antd";
import { connect } from "react-redux";

//Services
import {add, getAll, remove, update} from "../../store/redux/actions/employees/employeesActions";

//Components
import AddModal from "../subComponents/AddModal";
import EditModal from "../subComponents/EditModal";
import CollapseList from "../subComponents/CollapseList";

//Constants
import {MENU_CONSTANTS} from "../../utils/constants";

class Employees extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      addModalVisible: false,
      editModalVisible: false,
      selectedItem: null,
      pagination: {
        current: 1,
        pageSize: 5
      }
    };
    this.onToggleAddModal = this.onToggleAddModal.bind(this);
    this.onToggleEditModal = this.onToggleEditModal.bind(this);
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({
          isMounted: false,
        });
        this.props.getAll();
      }
    });
  }

  //#region "Pagination"
  onChangePaginationConfig(current, size){
    const newPagination = this.state.pagination;

    newPagination["current"] = current;
    newPagination["pageSize"] = size;
    this.setState({ pagination: newPagination });
  };
  //#endregion

  //#region "Modal"
  onToggleAddModal(isVisible){
    this.setState({ addModalVisible: isVisible });
  };
  onToggleEditModal(isVisible, item = null){
    this.setState({ editModalVisible: isVisible, selectedItem: item });
  };
  //#endregion

  render() {
    const { addModalVisible, editModalVisible, selectedItem } = this.state;
    const { current, pageSize } = this.state.pagination;
    const { employeesList, add, update, remove } = this.props;

    return (
        <Row className="main-container section-list employee-container">
          {addModalVisible &&
          <AddModal title={"Agregar empleado"}
                    section={MENU_CONSTANTS.employees}
                    onToggleAddModal={this.onToggleAddModal}
                    addFunction={add} />}

          {editModalVisible &&
          <EditModal title={"Editar empleado"} section={MENU_CONSTANTS.employees}
                     selectedItem={selectedItem}
                     onToggleEditModal = {this.onToggleEditModal}
                     editFunction={update} />}

          <Col xs={24} className={"content-container"}>
            <h1>Empleados</h1>
            <Divider />
            <Col className="right-align">
              <Button type={"default"} icon={"plus"} onClick={() => this.onToggleAddModal(true)}>
                Agregar empleado
              </Button>
            </Col>
            <CollapseList dataList={employeesList} section={MENU_CONSTANTS.employees}
                          current={current} pageSize={pageSize}
                          rowKey={"employeeId"}
                          onToggleEditModal={this.onToggleEditModal}
                          onChangePaginationConfig={this.onChangePaginationConfig}
                          deleteItem={remove}
            />
          </Col>
        </Row>
    );
  }
}

Employees.propTypes = {
  employeesList: PropTypes.array,
  getAll: PropTypes.func,
  add: PropTypes.func,
  update: PropTypes.func,
  remove: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    employeesList: state.employeesReducer.employeesList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: () => dispatch(getAll()),
    add: (data) => dispatch(add(data)),
    update: (data) => dispatch(update(data)),
    remove: (data) => dispatch(remove(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);