import { generalTypes, headquartersTypes } from "../../types";
import headquartersServices from "../../../../api/headquartersServices";

//Components
import { ERROR_MODAL, SUCCESS_MODAL } from "../../../../components/subComponents/responseModals";

//Functions

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getAll = () => {
  return dispatch => {
    dispatch(setLoading(true));
    headquartersServices.getAll()
        .then(response => {
          dispatch({
            type: headquartersTypes.GET_ALL_HEADQUARTERS,
            headquarterList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const getHeadquartersParams = () => {
  return dispatch => {
    dispatch(setLoading(true));
    headquartersServices.getParams()
        .then(response => {
          dispatch({
            type: headquartersTypes.GET_PARAMS_HEADQUARTERS,
            headquartersParams: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const add = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    headquartersServices.add(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const update = (data) => {
  return dispatch => {
    dispatch(setLoading(true));

    headquartersServices.update(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const remove = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    headquartersServices.remove(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};