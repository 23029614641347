//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Divider, Button } from "antd";
import { connect } from "react-redux";

//Services
import {getAll, add, update, remove} from "../../store/redux/actions/clients/clientsActions";

//Components
import AddModal from "../subComponents/AddModal";
import EditModal from "../subComponents/EditModal";
import CollapseList from "../subComponents/CollapseList";

//Constants
import { MENU_CONSTANTS } from "../../utils/constants";

class Clients extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      addModalVisible: false,
      editModalVisible: false,
      selectedItem: null,
      pagination: {
        current: 1,
        pageSize: 20,
      },
    };
    this.onToggleAddModal = this.onToggleAddModal.bind(this);
    this.onToggleEditModal = this.onToggleEditModal.bind(this);
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        this.props.getAll();
      }
    });
  }
  //#region "Pagination"
  onChangePaginationConfig (current, pageSize) {
    this.setState({ pagination: {
        current: current,
        pageSize: pageSize
      }
    });
  };
  //#endregion

  //#region "Modal"
  onToggleAddModal (isVisible) {
    this.setState({ addModalVisible: isVisible });
  };
  onToggleEditModal (isVisible, item = null) {
    this.setState({
      editModalVisible: isVisible,
      selectedItem: item
    });
  };
  //#endregion

  render() {
    const { pagination, addModalVisible, editModalVisible, selectedItem } = this.state;
    const { clientsList, add, update, remove } = this.props;

    return (
        <Row className="main-container section-list client-container">
          {addModalVisible &&
          <AddModal title={"Agregar cliente"}
                    section={MENU_CONSTANTS.clients}
                    onToggleAddModal={this.onToggleAddModal}
                    addFunction={add} />}

          {editModalVisible &&
          <EditModal title={"Editar cliente"} section={MENU_CONSTANTS.clients}
                     selectedItem={selectedItem}
                     onToggleEditModal = {this.onToggleEditModal}
                     editFunction={update} />}

          <Col xs={24} className={"content-container"}>
            <h1>Clientes</h1>
            <Divider />
            <Col className="right-align">
              <Button type={"default"} icon={"plus"} onClick={() => this.onToggleAddModal(true)}>
                Agregar cliente
              </Button>
            </Col>
            <CollapseList dataList={clientsList} section={MENU_CONSTANTS.clients}
                          current={pagination.current} pageSize={pagination.pageSize}
                          rowKey={"clientId"}
                          onChangePaginationConfig={this.onChangePaginationConfig}
                          deleteItem={remove}
                          onToggleEditModal={this.onToggleEditModal}
            />
          </Col>
        </Row>
    );
  }
}

Clients.propTypes = {
  clientsList: PropTypes.array,
  getAll: PropTypes.func,
  add: PropTypes.func,
  update: PropTypes.func,
  remove: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    clientsList: state.clientsReducer.clientsList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAll: () => dispatch(getAll()),
    add: (data) => dispatch(add(data)),
    update: (data) => dispatch(update(data)),
    remove: (data) => dispatch(remove(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);