//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {Row, Col, Card, List, Icon, Tooltip, Empty, Popconfirm} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { getColorStatus, isDownloadable } from "../../utils/functions";

// Services
import {generateCaseReportPdf, generateCaseReportExcel, remove} from "../../store/redux/actions/cases/casesActions";

//Constants
import ViewDetailModal from "./modals/ViewDetailModal";
import {PAGINATION_CONFIG} from "../../utils/constants";
import { RENDER_ROLE } from '../../utils/constants'
import ViewHistoryModal from "./modals/ViewHistoryModal";

class CaseTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      viewDetailModal: false,
      viewHistoryModal: false,
      itemToView: null
    };
    this.onToggleViewDetailModal = this.onToggleViewDetailModal.bind(this);
    this.onToggleHistoryModal = this.onToggleHistoryModal.bind(this);
    this.extraIcons = this.extraIcons.bind(this);
  };

  componentDidMount(){
    this.setState({ isMounted: true}, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
  }

  onToggleViewDetailModal(isVisible){
    this.setState({ viewDetailModal: isVisible });
  }
  onToggleHistoryModal(isVisible, itemToView){
    this.setState({ viewHistoryModal: isVisible, itemToView });
  }
  extraIcons (item) {
    const { casesParams, onToggleAddEditModal, generateCaseReportExcel, generateCaseReportPdf,
      onToggleFormatNameModal } = this.props;
    let iconSize = "17px";
    let role = localStorage.getItem("render_role");

    return<>
        {RENDER_ROLE.client.role === role && <Col lg={12} md={12} sm={6} xs={8} />}
        <Col lg={0}
             md={isDownloadable(casesParams.caseStatuses.find((s) => s.id === item.caseStatusId)) ? 4 : 16}
             sm={isDownloadable(casesParams.caseStatuses.find((s) => s.id === item.caseStatusId)) ? 12 : 18}
             xs={isDownloadable(casesParams.caseStatuses.find((s) => s.id === item.caseStatusId)) ? 8 : 16}>
          <Tooltip title={"Ver detalle"}>
            <Icon type={"eye"} style={{fontSize: iconSize}}
                  onClick={() => this.onToggleViewDetailModal(true)}/>
          </Tooltip>
        </Col>
          {isDownloadable(casesParams.caseStatuses.find((s) => s.id === item.caseStatusId)) &&
            <>
              <Col lg={6} md={4} sm={3} xs={4}>
                <Tooltip title={"Exportar a pdf"}>
                  <Icon type={"file-pdf"} style={{fontSize: iconSize}}
                        onClick={(e) => {
                          e.stopPropagation();
                          onToggleFormatNameModal(true, generateCaseReportPdf, item);
                        }}/>
                </Tooltip>
              </Col>
              <Col lg={6} md={4} sm={3} xs={4}>
                <Tooltip title={"Exportar a excel"}>
                  <Icon type={"file-excel"} style={{fontSize: iconSize}}
                        onClick={(e) => {
                          e.stopPropagation();
                          onToggleFormatNameModal(true, generateCaseReportExcel, item);
                        }}/>
                </Tooltip>
              </Col>
            </>
          }
          {RENDER_ROLE.client.role !== role &&
            <Col lg={isDownloadable(casesParams.caseStatuses.find((s) => s.id === item.caseStatusId)) ? 6 : 18}
                 md={4} sm={3} xs={4}>
              <Tooltip title={"Editar"}>
                <Icon type={"edit"} style={{fontSize: iconSize}}
                      onClick={(e) => {
                        e.stopPropagation();
                        onToggleAddEditModal(true, "Edit", item, item.isCytology);
                      }}/>
              </Tooltip>
            </Col>
          }
          {RENDER_ROLE.client.role !== role &&
            <Col lg={6} md={4} sm={3} xs={4}>
              <Tooltip title={"Eliminar caso"}>
                <Popconfirm title={"¿Confirma la eliminación de este caso?"}
                            okText={"Confirmar"} cancelText={"Cancelar"}
                            onConfirm={(e) => {
                              e.stopPropagation();
                              this.props.remove(item.caseId, this.props.pagination, this.props.filterVm);
                            }}>
                  <Icon type={"delete"} style={{fontSize: iconSize}}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}/>
                </Popconfirm>
              </Tooltip>
            </Col>
          }
      </>
  };

  render() {
    const { pageSize, page } = this.props.pagination;
    const { casesList, casesParams, onSelectCase, selectedCase, totalCasesList, onChangePaginationConfig,
            onFilterIdentification, filterVm } = this.props;
    const { viewDetailModal, viewHistoryModal, itemToView } = this.state;

    return (
        <Row className={"case-table-container"}>
          {viewDetailModal &&
            <ViewDetailModal onFilterIdentification={onFilterIdentification}
                             filterVm={filterVm}
                             selectedCase={selectedCase}
                             onToggleViewDetailModal={this.onToggleViewDetailModal}/>
          }
          {viewHistoryModal &&
            <ViewHistoryModal itemToView={itemToView}
                              onToggleHistoryModal={this.onToggleHistoryModal}/>
          }

          {selectedCase ?
            <List dataSource={casesList}
                  pagination={{
                    total: totalCasesList,
                    current: page,
                    showSizeChanger: PAGINATION_CONFIG.showSizeChanger,
                    pageSize: pageSize,
                    size: PAGINATION_CONFIG.size,
                    showTotal: (total, range) => PAGINATION_CONFIG.showTotal(total, range),
                    pageSizeOptions: PAGINATION_CONFIG.pageSizeOptions,
                    onChange: (page, size) => {
                      onChangePaginationConfig(page, size);
                    },
                    onShowSizeChange: (page, size) => {
                      onChangePaginationConfig(page, size);
                    },
                  }}
                  renderItem={item =>
                      <Card key={item.caseId} onClick={() => onSelectCase(item)}
                            className={selectedCase.caseId === item.caseId ? "selected-case" : "case-card"}>
                        <Row className={"case-body flex-container"}>
                          <Col className={"case-status"} md={2}
                               style={{ "background": getColorStatus(moment(item.receptionDate).format("DD/MM/YYYY"),
                                   casesParams.caseStatuses.find((s) => s.id === item.caseStatusId))
                               }}
                          />
                          <Col md={16} sm={22} xs={22}>
                            <Row>
                              <Col xs={24}>
                                <h3><b>{item.serial} / </b>{item.patient.names + " " + item.patient.surnames}</h3>
                              </Col>
                              <Col xs={24}>
                                {item.isCytology &&
                                <div>
                                  <p> <b>Citólogo(a): </b> {item.cytologist === null ? "No asignado" : (item.cytologist.names + " " +item.cytologist.surnames)}</p>
                                  <p> <b>Patólogo(a): </b> {item.pathologist === null ? "No asignado" : (item.pathologist.names + " " +item.pathologist.surnames)}</p>
                                </div>
                                }
                                {!item.isCytology &&
                                  <p> <b>Patólogo(a): </b> {item.pathologist === null ? "No asignado" : (item.pathologist.names + " " +item.pathologist.surnames)}</p>
                                }
                              </Col>
                              <Col xs={24}>
                                <b>{item.isCytology ? "Citología": "Quirúrgico"}</b>
                              </Col>
                              <Col md={24} xs={24} className={"view-history-container"}>
                                <span onClick={event => {
                                  event.stopPropagation();
                                  this.onToggleHistoryModal(true, item);
                                }}>
                                  <Icon type={"eye"}/> Ver historial
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={0} sm={24} xs={24} className={"case-actions right-align"}>
                                <br/>
                                {this.extraIcons(item)}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} sm={0} xs={0} className={"case-actions right-align"}>
                            {this.extraIcons(item)}
                          </Col>
                        </Row>
                      </Card>
                  }>
            </List>
            :
            <Empty style={{"marginTop": "10vh"}}
                   description={
                     <span>No hay casos para mostrar</span>
                   }
            />
          }
        </Row>
    );
  }
}

CaseTable.propTypes = {
  selectedCase: PropTypes.object,
  pagination: PropTypes.object,
  filterVm: PropTypes.object,
  onSelectCase: PropTypes.func,
  onChangePaginationConfig: PropTypes.func,
  onToggleAddEditModal: PropTypes.func,
  generateCaseReportPdf: PropTypes.func,
  generateCaseReportExcel: PropTypes.func,
  onToggleFormatNameModal: PropTypes.func,
  remove: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    casesList: state.casesReducer.casesList,
    casesParams: state.casesReducer.casesParams,
    totalCasesList: state.casesReducer.totalCasesList,
    pagination: state.casesReducer.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateCaseReportPdf: (data, formatName) => dispatch(generateCaseReportPdf(data, formatName)),
    generateCaseReportExcel: (data, formatName) => dispatch(generateCaseReportExcel(data, formatName)),
    remove: (caseId, pagination, filterVm) => dispatch(remove(caseId, pagination, filterVm))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseTable);