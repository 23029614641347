import request from "./requestWrapper";

function getAll() {
  return request({
    url: '/Campus/GetAll',
    method: 'GET'
  });
}
function getParams() {
  return request({
    url: '/Campus/GetParams',
    method: 'GET'
  });
}
function GetAllByClientId(data) {
  return request({
    url: '/Campus/GetAllByClientId',
    method: 'GET',
    params: data
  });
}
function add(data) {
  return request({
    url: '/Campus/Add',
    method: 'POST',
    data: data
  });
}
function update(data) {
  return request({
    url: '/Campus/Update',
    method: 'PUT',
    data: data
  });
}
function remove(data) {
  return request({
    url: '/Campus/Delete',
    method: 'DELETE',
    data: data
  });
}

export default {
  getAll, add, update, remove, getParams, GetAllByClientId
}