//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, Row, Col, Button, Popconfirm, Tooltip, Icon } from "antd";
import { connect } from "react-redux";
import moment from 'moment';
import { thousandsNumberSeparator } from "../../../utils/stringUtils";
// Services
import { submitMaterial } from "../../../store/redux/actions/cases/casesActions";
// Constants
import {RENDER_ROLE, STATUS_CONSTANTS} from "../../../utils/constants";
class ViewDetailModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false
    };
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
  }

  render() {
    const { onToggleViewDetailModal, selectedCase, casesParams, submitMaterial,
            onFilterIdentification, pagination, filterVm } = this.props;
    let identificationTypeString;
    let genderString;
    let totalTariff = 0;
    let completedStatus
    let iconSize = "15px";
    let role = localStorage.getItem("render_role");

    if(selectedCase !== undefined && selectedCase.patient.identificationType){
      casesParams.identificationTypes.map( identificationType => {
        if(identificationType.id === selectedCase.patient.identificationType.toString())
          identificationTypeString =  identificationType.value
      });
      casesParams.genders.map( gender => {
        if(gender.id === selectedCase.patient.gender.toString())
          genderString =  gender.value
      });
      selectedCase.specimenContractCases.map( specimenContractCase => {
        totalTariff += specimenContractCase.customTariff * specimenContractCase.quantity;
      });
      completedStatus = selectedCase.caseStatus.name === STATUS_CONSTANTS.completed;
    }

    return (
      <Modal title={"Detalle del caso"} width={700} closable={false} destroyOnClose={true} visible={true}
             footer={
               <Button type={"primary"} onClick={() => onToggleViewDetailModal(false)}>Aceptar</Button>
             }>
        <Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col xs={completedStatus ? 12 : 24} md={completedStatus ? (RENDER_ROLE.receptor.role === role ? 12 : 24) : 24}>
              <h3><b>{selectedCase && selectedCase.serial}</b></h3>
            </Col>
            {RENDER_ROLE.receptor.role === role && completedStatus &&
              <Col xs={12} md={12} className={"right-align"}>
                <Popconfirm title={'¿Quieres cambiar el estado a material remitido?'}
                            placement={"bottomRight"}
                            onConfirm={() => {
                              submitMaterial(selectedCase, pagination, filterVm)
                            }}>
                  <Button type={"default"} size={"small"}>Material remitido</Button>
                </Popconfirm>
              </Col>
            }
          </Row>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Nombre(s): </b></Col>
                <Col xs={15}>{selectedCase.patient.names}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Apellidos: </b></Col>
                <Col xs={15}>{selectedCase.patient.surnames}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Tipo de documento: </b></Col>
                <Col xs={15}>{identificationTypeString}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Identificación: </b></Col>
                <Col xs={15}>
                  {selectedCase.patient.identification} &nbsp;
                  <Tooltip placement="right" title={"Filtrar"}>
                    <Icon type="filter" style={{fontSize: iconSize}}
                          onClick={()=> {
                            onFilterIdentification(selectedCase.patient.identification);
                            onToggleViewDetailModal(false);
                          }}/>
                  </Tooltip>
                </Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Género: </b></Col>
                <Col xs={15}>{genderString}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Fecha de recepción: </b></Col>
                <Col xs={15}>{moment.utc(selectedCase.receptionDate).local().format("DD/MM/YYYY")}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Añadido el: </b></Col>
                <Col xs={15}>{moment.utc(selectedCase.createdDate).local().format("DD/MM/YYYY hh:mm:ss a")}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Última modificación: </b></Col>
                <Col xs={15}>{moment.utc(selectedCase.modifiedDate).local().format("DD/MM/YYYY hh:mm:ss a")}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Estado: </b></Col>
                <Col xs={15}>{selectedCase.caseStatus.name}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            { selectedCase.specimenContractCases.length > 0 &&
                <div>
                  <Col xs={9}><b>Contrato: </b></Col>
                  <Col xs={15}>{selectedCase.specimenContractCases[0].specimenContract.contract.name}</Col>
                </div>
            }
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            { selectedCase.specimenContractCases.length > 0 &&
              <div>
                <Col xs={9}><b>Sede: </b></Col>
                <Col xs={15}>{selectedCase.specimenContractCases[0].specimenContract.contract.campus.name}</Col>
              </div>
            }
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            { selectedCase.isCytology &&
              <div>
                <Col xs={9}><b>Citólogo(a): </b></Col>
                <Col xs={15}>{selectedCase.cytologist !== null ? (selectedCase.cytologist.names + " " + selectedCase.cytologist.surnames) : "No asignado"}</Col>
              </div>
            }
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>Patólogo(a): </b></Col>
                <Col xs={15}>{selectedCase.pathologist !== null ? (selectedCase.pathologist.names + " " + selectedCase.pathologist.surnames) : "No asignado"}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
                <Col xs={9}><b>EPS: </b></Col>
                <Col xs={15}>{selectedCase.patient.eps !== null ? (selectedCase.patient.eps) : "-"}</Col>
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            {!selectedCase.isCytology &&
              <div>
                <Col xs={9}><b>Diagnóstico CIE-10: </b></Col>
                <Col xs={15}>{selectedCase.shortDiagnosis !== null ? selectedCase.shortDiagnosis : "Sin diagnóstico"}</Col>
              </div>
            }
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            { selectedCase.specimenContractCases.length > 0 &&
                <div>
                  <Col xs={9}><b>Especímenes: </b></Col>
                  <Col xs={15}>
                      {
                        selectedCase.specimenContractCases.map((specimenContractCase,index)=>
                          <div key={index}>
                            {"- "}
                            {specimenContractCase.sampleSent !== null && specimenContractCase.sampleSent + ": "}
                            {specimenContractCase.quantity+" x ("+specimenContractCase.specimenContract.specimen.cupsCode+") "
                              + specimenContractCase.specimenContract.specimen.name + "- $"
                              + thousandsNumberSeparator(specimenContractCase.customTariff)}
                          </div>
                        )
                      }
                  </Col>
                </div>
            }
          </Col>
          <Col xs={24} md={24} className={"information-panel"}>
            { selectedCase.specimenContractCases.length > 0 &&
                <div>
                  <Col xs={9}><b>Precio total: </b></Col>
                  <Col xs={15}>{"$"+thousandsNumberSeparator(totalTariff)}</Col>
                </div>
            }
          </Col>
          {!selectedCase.isCytology ? (
            <div>
              <Col md={24} className={"information-panel"}>
                      <div>
                        <Col md={9}><b>Descripción macroscópica: </b></Col>
                        <Col md={15} dangerouslySetInnerHTML={{__html: selectedCase.macroscopicDescription ?? "-" }} />
                      </div>
              </Col>
              <Col md={24} className={"information-panel"}>
                      <div>
                        <Col md={9}><b>Descripción microscópica: </b></Col>
                        <Col md={15} dangerouslySetInnerHTML={{__html: selectedCase.microscopicDescription ?? "-" }} />
                      </div>
              </Col>
              <Col md={24} className={"information-panel"}>
                      <div>
                        <Col md={9}><b>Diagnóstico: </b></Col>
                        <Col md={15}>{selectedCase.diagnosis ?? '-'}</Col>
                      </div>
              </Col>
            </div>
          ):(
          <div>
            <Col md={24} className={"information-panel"}>
              <div>
                <Col md={9}><b>Calidad de la muestra: </b></Col>
                <Col md={15}>
                  {selectedCase.cytologyAnalysisResult.sampleQualityId !== 0 ? casesParams.sampleQualities.find(sq => sq.id === selectedCase.cytologyAnalysisResult.sampleQualityId.toString()).value : '-'}
                </Col>
              </div>
            </Col>
            <Col md={24} className={"information-panel"}>
              <div>
                <Col md={9}><b>Categorización general: </b></Col>
                <Col md={15}>
                  {selectedCase.cytologyAnalysisResult.generalCategorizationId !== 0 ? casesParams.generalCategorizations.find(sq => sq.id === selectedCase.cytologyAnalysisResult.generalCategorizationId.toString()).value : '-'}
                </Col>
              </div>
            </Col>
            <Col md={24} className={"information-panel"}>
              <div>
                <Col md={9}><b>Detalles: </b></Col>
                <Col md={15}>
                  {selectedCase.cytologyAnalysisResult.neckAspectsIds !== null &&
                    selectedCase.cytologyAnalysisResult.neckAspectsIds[0] !== "" ?
                      <>
                        <b>Aspectos del cuello:</b> <br />
                        <ul>
                          {selectedCase.cytologyAnalysisResult.neckAspectsIds.map(item =>
                            casesParams.neckAspects.length !== 0 &&
                              <li key={item}>{casesParams.neckAspects.find(na => na.id === item.toString()).value}</li>
                          )}
                        </ul>
                      </>
                    : null
                  }
                  {selectedCase.cytologyAnalysisResult.otherFindingsIds !== null &&
                    selectedCase.cytologyAnalysisResult.otherFindingsIds[0] !== "" ?
                    <>
                      <b>Otros hallazgos no neoplásicos:</b> <br />
                      <ul>
                        {selectedCase.cytologyAnalysisResult.otherFindingsIds.map(item => 
                          casesParams.otherFindings.length !== 0 &&
                            <li key={item}>{casesParams.otherFindings.find(of => of.id === item.toString()).value}</li>
                        )}
                      </ul>
                    </>
                    : null
                  }
                  {selectedCase.cytologyAnalysisResult.microorganismsIds !== null &&
                    selectedCase.cytologyAnalysisResult.microorganismsIds[0] !== "" ?
                    <>
                      <b>Microorganismos:</b> <br />
                      <ul>
                        {selectedCase.cytologyAnalysisResult.microorganismsIds.map(item => 
                          casesParams.microorganisms.length !== 0 &&
                            <li key={item}>{casesParams.microorganisms.find(m => m.id === item.toString()).value}</li>
                        )}
                      </ul>
                    </>
                    : null
                  }
                  {selectedCase.cytologyAnalysisResult.squamousCellAbnormalitiesIds !== null &&
                    selectedCase.cytologyAnalysisResult.squamousCellAbnormalitiesIds[0] !== "" ?
                    <>
                      <b>Anormalidades de células escamosas:</b> <br />
                      <ul>
                        {selectedCase.cytologyAnalysisResult.squamousCellAbnormalitiesIds.map(item => 
                          casesParams.squamousCells.length !== 0 &&
                            <li key={item}>{casesParams.squamousCells.find(sc => sc.id === item.toString()).value}</li>
                        )}
                      </ul>
                    </>
                    : null
                  }
                  {selectedCase.cytologyAnalysisResult.glandularCellAbnormalitiesIds !== null &&
                    selectedCase.cytologyAnalysisResult.glandularCellAbnormalitiesIds[0] !== "" ?
                    <>
                      <b>Anormalidades de células glandulares:</b> <br />
                      <ul>
                        {selectedCase.cytologyAnalysisResult.glandularCellAbnormalitiesIds.map(item => 
                          casesParams.glandularCells.length !== 0 &&
                            <li key={item}>{casesParams.glandularCells.find(gc => gc.id === item.toString()).value}</li>
                        )}
                      </ul>
                    </>
                    : null
                  }
                </Col>
              </div>
            </Col>
          </div>
          )}
        </Row>
      </Modal>
    );
  }
}

ViewDetailModal.propTypes = {
  selectedCase: PropTypes.object,
  filterVm: PropTypes.object,
  onFilterIdentification: PropTypes.func,
  onToggleViewDetailModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    casesParams: state.casesReducer.casesParams,
    pagination: state.casesReducer.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitMaterial: (selectedCase, pagination, filterVm) => dispatch(submitMaterial(selectedCase, pagination, filterVm)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailModal);