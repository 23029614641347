//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Button, Modal, Radio } from "antd";
import { connect } from "react-redux";


class FormatNameModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      formatName: false
    };
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
  }
  onChangeOption(value){
    this.setState({ formatName: value });
  }
  onSubmit(){
    const { formatName } = this.state;
    const { generateFileFunction, generateFileFunctionData, onToggleFormatNameModal } = this.props;

    generateFileFunction(generateFileFunctionData, formatName);
    onToggleFormatNameModal(false, null, null);
  }

  render() {
    const { formatName } = this.state;
    const { onToggleFormatNameModal } = this.props;

    return (
      <Modal title={"Formato del nombre de archivo"} visible={true} keyboard={false}
              closable={false} destroyOnClose={true}
              footer={[
                <Button type={"default"} key="back" onClick={() => onToggleFormatNameModal(false, null, null)}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={this.onSubmit}>
                  Aceptar
                </Button>
              ]}
      >
        <Row>
          <Col xs={24}>
            Por favor, seleccione el formato del nombre del archivo que desea:

            <br/><br/>

            <Radio checked={!formatName} onChange={() => this.onChangeOption(false)}>
              Número de Caso - Identificación
            </Radio>
            <br />
            <Radio checked={formatName} onChange={() => this.onChangeOption(true)}>
              Número de Caso - Apellidos Nombres
            </Radio>
          </Col>
        </Row>
      </Modal>
    );
  }
}

FormatNameModal.propTypes = {
  generateFileFunctionData: PropTypes.object,
  onToggleFormatNameModal: PropTypes.func,
  generateFileFunction: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatNameModal);