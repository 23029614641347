import request from "./requestWrapper";

function getAll() {
  return request({
    url: '/Client/GetAll',
    method: 'GET'
  });
}
function add(data) {
  return request({
    url: '/Client/Add',
    method: 'POST',
    data: data
  });
}
function update(data) {
  return request({
    url: '/Client/Update',
    method: 'PUT',
    data: data
  });
}
function remove(data) {
  return request({
    url: '/Client/Delete',
    method: 'DELETE',
    data: data
  });
}

export default {
  getAll, add, update, remove
}