import { specimenTypes, generalTypes } from "../../types";
import specimensServices from "../../../../api/specimensServices";

//Components
import { ERROR_MODAL } from "../../../../components/subComponents/responseModals";

//Functions

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getAllSpecimens = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    specimensServices.getAll(data)
        .then(response => {
          dispatch({
            type: specimenTypes.GET_ALL_SPECIMENS,
            specimensList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const cleanSpecimensList = () => {
  return dispatch => {
    dispatch({
      type: specimenTypes.GET_ALL_SPECIMENS,
      specimensList: {
        specimens: [],
      }
    });
  }
};