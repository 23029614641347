import React from "react";

const Loading = () => {
  return (
    <div className="spinner-content flex-container">
        <div className="loader"/>
    </div>
  );
};

export default Loading;