import { generalTypes, specialistsTypes } from "../../types";
import specialistsServices from "../../../../api/specialistsServices";

//Components
import { ERROR_MODAL, SUCCESS_MODAL } from "../../../../components/subComponents/responseModals";

//Functions

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getAll = () => {
  return dispatch => {
    dispatch(setLoading(true));
    specialistsServices.getAll()
        .then(response => {
          dispatch({
            type: specialistsTypes.GET_ALL_SPECIALISTS,
            specialistsList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const add = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    let formData = new FormData();

    Object.keys(data).forEach(key => {
      if (data.signature && key === "signature") {
        formData.append('signature', data.signature.originFileObj, data.signature.name);
      } else {
        formData.append(key, data[key]);
      }
    });
    specialistsServices.add(formData)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const update = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    let formData = new FormData();

    Object.keys(data).forEach(key => {
      if (data.signature && key === "signature") {
        formData.append('signature', data.signature.originFileObj, data.signature.name);
      } else {
        formData.append(key, data[key]);
      }
    });
    specialistsServices.update(formData)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const remove = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    specialistsServices.remove(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const getSpecialties = () => {
  return dispatch => {
    dispatch(setLoading(true));
    specialistsServices.getSpecialties()
        .then(response => {
          dispatch({
            type: specialistsTypes.GET_SPECIALTIES,
            specialties: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};