//Libraries
import React, { Component } from "react";
import { Button, Modal, Row, Col, Form, Input, Select, Checkbox, Transfer, message } from 'antd';
import * as PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

//Services
import { getSpecialties } from "../../store/redux/actions/specialists/specialistsActions";
import { getRoles } from "../../store/redux/actions/employees/employeesActions";
import { getHeadquartersParams } from "../../store/redux/actions/headquarters/headquartersActions";
import { getContractsParams } from "../../store/redux/actions/contracts/contractsActions";

//SubComponents
import ImageCrop from "../subComponents/ImageCrop";

//Functions
import { getBase64 } from "../../utils/functions";

//Constants
import { MENU_CONSTANTS } from "../../utils/constants";
import {thousandsNumberSeparator} from "../../utils/stringUtils";
const { Item } = Form;
const { Option } = Select;

class AddModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      loading: false,
      imageFile: null,
      transferTargetKeys: [],
      transferSelectedKeys: [],
      tariffSpecimenContract: true,
      selectSpecimenContract: false,
      tariffSpecimenContractValue: {},
      tariffSpecimenContractValues: [],
    };
    this.onHandleChangeImage = this.onHandleChangeImage.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleTransferChange = this.handleTransferChange.bind(this);
    this.handleSelectTransferChange = this.handleSelectTransferChange.bind(this);
    this.onChangeSpecimenTable = this.onChangeSpecimenTable.bind(this);
    this.onChangeSpecimenCategory = this.onChangeSpecimenCategory.bind(this);
    this.onClickButtonTariff = this.onClickButtonTariff.bind(this);
  }

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        switch (this.props.section) {
          case MENU_CONSTANTS.specialists:
            this.props.getSpecialties();
            break;
          case MENU_CONSTANTS.employees:
            this.props.getRoles();
            break;
          case MENU_CONSTANTS.headquarters:
            this.props.getHeadquartersParams();
            break;
          case MENU_CONSTANTS.contracts:
            this.props.getContractsParams();
            break;
          default:
            break;
        }
      }
    });
  }

  validateForm() {
    const { form } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      else {
        if (this.props.section === MENU_CONSTANTS.clients) {
          values.logo = this.state.imageFile;
        }else if(this.props.section === MENU_CONSTANTS.specialists){
          values.signature = this.state.imageFile;
        }else if(this.props.section === MENU_CONSTANTS.contracts){
          if(!this.state.tariffSpecimenContract){
            message.warn('Deselecciona el especímen para continuar.')
            return;
          }
          delete values.specimenCategoryId;
          delete values.specimenTableId;
          delete values.priceSpecimen;
          values.specimens = this.state.tariffSpecimenContractValues;
        }
        this.props.onToggleAddModal(false);
        this.props.addFunction(values);
      }
    });
  }

  onHandleChangeImage (info) {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          imageFile: info.file,
          loading: false,
        })
      );
    }
  };

  handleTransferChange(nextTargetKeys, direction){
    const { setFieldsValue } = this.props.form;
    const { tariffSpecimenContractValues, tariffSpecimenContractValue, specimenList } = this.state;
    this.setState({ transferTargetKeys: nextTargetKeys });
    let newTariffSpecimenContractValues;
    if(direction === "right"){
      newTariffSpecimenContractValues = tariffSpecimenContractValues;
      nextTargetKeys.forEach( id => {
        if(newTariffSpecimenContractValues.find( tsc => tsc.specimenId === id) === undefined){
          let tariffSpecimenContractSelected =  {
            price: specimenList.find(sl => sl.specimenId === id).defaultTariff,
            specimenId: id
          }
          newTariffSpecimenContractValues.push(tariffSpecimenContractSelected);
        }
      });
      this.setState({tariffSpecimenContractValues: newTariffSpecimenContractValues})
    }
    else{
      newTariffSpecimenContractValues = tariffSpecimenContractValues.filter( tsc =>
        tsc.specimenId !== tariffSpecimenContractValue.specimenId
      )
      this.setState({tariffSpecimenContractValues: newTariffSpecimenContractValues})
    }
    setFieldsValue({ "priceSpecimen": 0 });
  };

  handleSelectTransferChange(sourceSelectedKeys, targetSelectedKeys){
    this.setState({ transferSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
    const { specimenList } = this.state;
    const { setFieldsValue } = this.props.form;
    if(sourceSelectedKeys.length > 0){
      this.setState({selectSpecimenContract: true});
    }
    else if(targetSelectedKeys.length > 0){
      let price;
      specimenList.forEach( specimen =>{
        if(specimen.key !== targetSelectedKeys[0]){
         specimen.disabled = true;
         price = specimen.defaultTariff;
        }
        else{
          setFieldsValue({ "priceSpecimen": specimen.defaultTariff });
        }
      });
      let data = {
        price: price,
        specimenId: targetSelectedKeys[0]
      }
      this.setState({
        tariffSpecimenContractValue: data,
        tariffSpecimenContract: false
      });
    }
    else{
      specimenList.forEach( specimen =>{ specimen.disabled = false; });
      this.setState({
        tariffSpecimenContract: true,
        selectSpecimenContract: false,
      });
    }
  };

  onChangeSpecimenTable (value) {
    const { contractsParams } = this.props;
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ "specimenCategoryId": undefined });
    let specimenCategoryList = value
        ? contractsParams.specimenTables.filter(st => st.specimenTableId === value)[0].specimenCategories
        : [];
    this.setState({
      specimenCategoryList: specimenCategoryList,
    });
  };

  onChangeSpecimenCategory (value) {
    const { specimenCategoryList } = this.state;
    let specimenList = value
        ? specimenCategoryList.filter(sc => sc.specimenCategoryId === value)[0].specimens
        : [];
    specimenList.forEach(specimen => { specimen.disabled = false; });
    this.setState({
      specimenList: specimenList,
    });
  };

  onClickButtonTariff () {
    const { getFieldValue } = this.props.form;
    const { transferSelectedKeys, specimenList, tariffSpecimenContractValues } = this.state;
    let data = {
      price: getFieldValue("priceSpecimen"),
      specimenId: transferSelectedKeys[0]
    }
    if(tariffSpecimenContractValues.find( tsc => tsc.specimenId === transferSelectedKeys[0])){
      let newTariffSpecimenContractValues = tariffSpecimenContractValues;
      newTariffSpecimenContractValues.forEach((tcs, index) => {
        if(tcs.specimenId === transferSelectedKeys[0]) {
          newTariffSpecimenContractValues[index].price = getFieldValue("priceSpecimen");
        }
      });
    }
    specimenList.forEach(specimen => {
      if(specimen.specimenId === transferSelectedKeys[0]) {
        specimen.defaultTariff = getFieldValue("priceSpecimen")
      }
    });
    this.setState({
      tariffSpecimenContractValue: data,
      tariffSpecimenContract: false,
    });
    message.config({
      top: 60,
      duration: 4,
    });
    message.success('Tarifa modificada.');
  };

  render() {
    const { title, onToggleAddModal, section, specialties, roles, headquartersParams, contractsParams } = this.props;
    const { imageFile, imageUrl, loading, transferTargetKeys, transferSelectedKeys,
            tariffSpecimenContract, specimenCategoryList, specimenList, selectSpecimenContract } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    let specimenTableValue = getFieldValue("specimenTableId");
    let specimenCategoryValue = getFieldValue("specimenCategoryId");
    let addButtonDisabled = section === MENU_CONSTANTS.contracts ? transferTargetKeys.length === 0 : false;
    return (
      <Modal className={"add-modal"}
             title={title} visible={true} keyboard={false}
             closable={false} destroyOnClose={true} centered
             footer={[
               <Button type={"default"} key="back" onClick={() => onToggleAddModal(false)}>
                  Cancelar
               </Button>,
               <Button key="submit" type="primary" disabled={addButtonDisabled}
                       onClick={this.validateForm}>
                  Agregar
                </Button>
             ]}
      >
        <Form>
          {
            //#region "Add contract"
            section === MENU_CONSTANTS.contracts && (
              <div>
                <Row>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Nombre del contrato">
                      {getFieldDecorator("name", {
                        rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                      })(
                          <Input type="text" autoComplete={"off"} />
                        )}
                    </Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Sede">
                      {getFieldDecorator("campusId", {
                        rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                      })(
                        <Select showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                          {contractsParams.campuses.map(campus =>
                            <Option key={campus.campusId} value={campus.campusId}>
                              {campus.name}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Tabla de especímenes">
                      {getFieldDecorator("specimenTableId", {
                        rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                      })(
                        <Select disabled={!tariffSpecimenContract || selectSpecimenContract}
                                onChange={ (e) => this.onChangeSpecimenTable(e) }>
                          {contractsParams.specimenTables.map(st =>
                            <Option key={st.specimenTableId} value={st.specimenTableId}>
                              {st.name}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Categoría">
                      {getFieldDecorator("specimenCategoryId", {
                        rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                      })(
                          <Select disabled={!specimenTableValue || !tariffSpecimenContract || selectSpecimenContract}
                                  onChange={ (e) => this.onChangeSpecimenCategory(e) }>
                            {specimenCategoryList !== undefined &&
                              specimenCategoryList.map(sc =>
                                <Option key={sc.specimenCategoryId} value={sc.specimenCategoryId}>
                                  {sc.name}
                                </Option>
                            )}
                          </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <Item className="form-item-small" label="Precios personalizados">
                      <Transfer className={"add-modal-transfer"}
                                rowKey={record => record.specimenId}
                                disabled={!specimenCategoryValue }
                                dataSource={specimenList}
                                showSelectAll={false}
                                selectedKeys={transferSelectedKeys} targetKeys={transferTargetKeys}
                                onChange={this.handleTransferChange}
                                onSelectChange={this.handleSelectTransferChange}
                                render={specimen =>
                                    `(${specimen.cupsCode}) $${thousandsNumberSeparator(specimen.defaultTariff)}: ${specimen.name}`
                                }
                      />
                    </Item>
                  </Col>
                </Row>
                <Row type="flex" justify="end" align="middle">
                  <Col xs={5}>
                    <label>Precio espécimen:</label>
                  </Col>
                  <Col xs={5}>
                    <Item className="small-form-item">
                      {getFieldDecorator("priceSpecimen", {
                          getValueFromEvent: (e) => {
                              let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                              if (!isNaN(value)){
                                return value
                              }
                              else{
                                return 0;
                              }
                          },
                          initialValue: 0,
                          rules: [
                            { required: !tariffSpecimenContract, message: "Cantidad requerida" },
                            { type: "number", min: "0" },
                          ]
                        })(
                          <Input className={"center-align"} disabled={tariffSpecimenContract} prefix={"$"}/>
                        )}
                    </Item>
                  </Col>
                  <Col xs={2} className="center-align">
                    <Button type={"default"} icon={"check"} className="margin-top-button"
                            disabled={tariffSpecimenContract}
                            onClick={this.onClickButtonTariff}
                    />
                  </Col>
                </Row>
              </div>
            )
            //#endregion
          }
          {
            //#region "Add specialist"
            section === MENU_CONSTANTS.specialists && (
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombres">
                    {getFieldDecorator("names", {
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Apellidos">
                    {getFieldDecorator("surnames", {
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="email" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Registro médico">
                    {getFieldDecorator("medicalNumber", {
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Especialidad">
                    {getFieldDecorator("specialtyId", {
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                      <Select>
                        {specialties.map(specialty =>
                            <Option key={specialty.id} value={specialty.id}>
                              {specialty.name}
                            </Option>
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Título">
                    {getFieldDecorator("degree", {
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ImageCrop imageFile={imageFile} imageUrl={imageUrl}
                             name={"Firma"} 
                             loading={loading}
                             aspect={2.5}
                             onHandleChangeImage={(info) => this.onHandleChangeImage(info)}/>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Add client"
            section === MENU_CONSTANTS.clients && (
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombre">
                    {getFieldDecorator("name", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="NIT">
                    {getFieldDecorator("nit", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="email" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item className="form-item" label="">
                    {getFieldDecorator("letterWithLogo", {
                      valuePropName: "checked",
                      initialValue: true,
                      rules: [{ required: false }],
                    })(
                        <Checkbox>Carta con logo</Checkbox>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ImageCrop imageFile={imageFile}
                             imageUrl={imageUrl}
                             name={"Logo"}
                             loading={loading}
                             onHandleChangeImage={(info) => this.onHandleChangeImage(info)}/>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Add employee"
            section === MENU_CONSTANTS.employees && (
              <Row>
                <Col sm={12} xs={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombres">
                    {getFieldDecorator("names", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col sm={12} xs={24} md={12} lg={12}>
                  <Item className="form-item" label="Apellidos">
                    {getFieldDecorator("surnames", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="email" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Rol">
                    {getFieldDecorator("roleId", {
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                        <Select>
                          {roles.map(role =>
                              <Option key={role.id} value={role.id}>
                                {role.name}
                              </Option>
                          )}
                        </Select>
                    )}
                  </Item>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Add headquarters"
            section === MENU_CONSTANTS.headquarters && (
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item className="form-item" label="Nombre de sede">
                    {getFieldDecorator("name", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Prefijo">
                    {getFieldDecorator("prefix", {
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Cliente">
                    {getFieldDecorator("clientId", {
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                        <Select>
                          {headquartersParams.map(client =>
                              <Option key={client.id} value={client.id}>
                                {client.value}
                              </Option>
                          )}
                        </Select>
                    )}
                  </Item>
                </Col>
              </Row>
            )
            //#endregion
          }
        </Form>
      </Modal>
    );
  }
}

AddModal.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string,
  specialties: PropTypes.array,
  roles: PropTypes.array,
  contractsParams: PropTypes.object,
  onToggleAddModal: PropTypes.func,
  addFunction: PropTypes.func,
  getSpecialties: PropTypes.func,
  getRoles: PropTypes.func,
  getHeadquartersParams: PropTypes.func,
  getContractsParams: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    specialties: state.specialistsReducer.specialties,
    roles: state.employeesReducer.roles,
    headquartersParams: state.headquartersReducer.headquartersParams,
    contractsParams: state.contractsReducer.contractsParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialties: () => dispatch(getSpecialties()),
    getRoles: () => dispatch(getRoles()),
    getHeadquartersParams: () => dispatch(getHeadquartersParams()),
    getContractsParams: () => dispatch(getContractsParams())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddModal));