//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Button, Input, Form, Select, Icon, Collapse,
         DatePicker, Row, Divider } from "antd";
import { connect } from "react-redux";
import { ClearIcon } from "../../assets/customIcons";
import { isValueDateTime, isValueSelectedFilter, valueSelectedFilter, valueSelectedFilterSearch,
         valueDateTime, clientRestriction, isValueSelectedFilterSearch, isFilterSearchDiagnosis } from "../../utils/functions";
import moment from 'moment';

// Services
import { getAllPaginated, getCie10, cleanCie10List } from "../../store/redux/actions/cases/casesActions";
import { getAllSpecimens, cleanSpecimensList } from "../../store/redux/actions/specimens/specimensActions";

//Constants
import { RENDER_ROLE } from "../../utils/constants";
const { Item } = Form;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;
let restrictionId = 1;

class Filters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      withPreviousFilter: false,
      setRestrictionKeys: false,
    };

    this.removeRestriction = this.removeRestriction.bind(this);
    this.addRestriction = this.addRestriction.bind(this);
    this.onChangeFilterTypeSelect = this.onChangeFilterTypeSelect.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.screenSize = this.screenSize.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        if(this.props.filters.length > 0 ){
          this.setState({ withPreviousFilter: true});
        }
        window.addEventListener("resize", this.screenSize);
        this.screenSize();
      }
    });
  }
  componentDidUpdate(prevProps, prevState){
    const { identificationFilter, form, pagination, filters } = this.props;
    const { withPreviousFilter, setRestrictionKeys } = this.state;
    if(prevProps.identificationFilter !== identificationFilter){
      if(identificationFilter !== null){
        const { setFieldsValue } = form;
        let identificationProperty = "5";
        setFieldsValue({
          [`filters`]: [{key: identificationProperty, value: identificationFilter}],
          [`restrictionKeys`]: [0],
        });
        let data = {
          paginationVm: {
            pageSize: pagination.pageSize,
            page: 1,
          },
          filterVm: {
            textFilters:{}
          },
        };
        data.filterVm.textFilters[identificationProperty] = [];
        data.filterVm.textFilters[identificationProperty].push(identificationFilter);
        this.props.handleChangeFilters(data.filterVm);
        this.props.getAllPaginated(data);
        this.props.onFilterIdentification(null);
      }
    }
    if(withPreviousFilter !== prevState.withPreviousFilter){
      const { setFieldsValue } = form;
      let restrictionKeys = [];
      filters.forEach((item, index) => {
        restrictionKeys.push(index);
      });
      setFieldsValue({
        [`restrictionKeys`]: restrictionKeys,
      });
      this.setState({setRestrictionKeys: true});
    }
    if(prevState.setRestrictionKeys !== setRestrictionKeys){
      const { setFieldsValue } = form;
      setFieldsValue({
        [`filters`]: this.props.filters,
      });
    }
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.screenSize);
  }
  screenSize() {
    let changeSmallSize = (window.innerWidth <= 576);
    if (changeSmallSize !== this.state.smallSize) {
        this.setState({smallSize: changeSmallSize});
    }
  }
  validateForm() {
    const { form, pagination } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if(errors) return;
      else{
        let data = {
          paginationVm: {
            pageSize: pagination.pageSize,
            page: 1,
          },
          filterVm: {
            textFilters:{}
          },
        };
        form.getFieldValue('filters').map((item) => {
          if(isValueDateTime(item.key)){
            data.filterVm[`initial${valueDateTime(item.key)}Date`] = moment(new Date(item.value[0]).setHours(0, 0, 0)).format();
            data.filterVm[`final${valueDateTime(item.key)}Date`] = moment(new Date(item.value[1]).setHours(23, 59, 59)).format();
          }
          else{
            if(data.filterVm.textFilters[item.key] !== undefined)
            {
              data.filterVm.textFilters[item.key].push(item.value);
            }
            else{
              data.filterVm.textFilters[item.key] = [];
              data.filterVm.textFilters[item.key].push(item.value);
            }
          }
        });
        this.props.handleChangeFilters(data.filterVm, form.getFieldValue('filters'));
        this.props.getAllPaginated(data);
      }
    });
  }
  clearFilters(){
    const { setFieldsValue, getFieldValue } = this.props.form;
    let data = {
      paginationVm: {
        pageSize: 5,
        page: 1,
      },
      filterVm: {
        textFilters:{}
      },
    };
    setFieldsValue({
      [`filters`]: [{key: undefined, value: undefined}],
      [`restrictionKeys`]: [0],
    });
    this.props.handleChangeFilters(data.filterVm, getFieldValue('filters'));
    this.props.getAllPaginated(data);
  }
  removeRestriction(item){
    const { form } = this.props;
    const restrictionKeys = form.getFieldValue('restrictionKeys');

    if (restrictionKeys.length === 1) return;
    form.setFieldsValue({
      restrictionKeys: restrictionKeys.filter(key => key !== item),
    });
  };
  addRestriction(){
    const { form } = this.props;
    const restrictionKeys = form.getFieldValue('restrictionKeys');
    const nextRestrictionKeys = restrictionKeys.concat(restrictionId++);

    form.setFieldsValue({
      restrictionKeys: nextRestrictionKeys,
    });
  };
  onChangeFilterTypeSelect(key){
    const { setFieldsValue , getFieldValue } = this.props.form;
    if(getFieldValue(`filters[${key}].value`) !== undefined){
      setFieldsValue({[`filters[${key}].value`]: undefined});
    }
  };
  handleSearch(value, currentCaseFilter){
    const { getFieldValue } = this.props.form;
    let filter = valueSelectedFilterSearch(getFieldValue(`filters[${currentCaseFilter}].key`));
    if(value.length === 3){
      if(isFilterSearchDiagnosis(filter) && this.props.cie10List.length === 0){
        this.props.getCie10({value: value});
      }
      else if(this.props.specimensList.specimens.length === 0){
        this.props.getAllSpecimens({stringFilter: value});
      }
    }
    else if(value.length < 3){
      if(isFilterSearchDiagnosis(filter)){
        this.props.cleanCie10List();
      }
      else{
          this.props.cleanSpecimensList();
      }
    }
  }

  render() {
    let { smallSize } = this.state;
    const { casesParams, specimensList, cie10List } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('restrictionKeys', { initialValue: [0] });
    const restrictionKeys = getFieldValue('restrictionKeys');
    let role = localStorage.getItem("render_role");
    if(role === RENDER_ROLE.client.role){
      delete casesParams.caseFilters[clientRestriction()];
    }
    const restrictionItemsSmall = restrictionKeys.map((k) => (
        <Row type="flex" justify="space-around" align="middle" key={k} >
          <Col xs={restrictionKeys.length > 1 ? 23 : 24}>
            <Row type="flex" justify="space-around" align="middle">
              <Col xs={4} className="small-input">
                <label>Filtro:</label>
              </Col>
              <Col xs={20}>
                <Item className="form-item">
                  {getFieldDecorator(`filters[${k}].key`, {
                    rules: [{ required: true, message: "Campo requerido" }]
                  })(
                      <Select onChange={() => this.onChangeFilterTypeSelect(k)}
                              size={"small"}>
                        {casesParams.caseFilters.map( caseFilter =>
                          <Option value={caseFilter.id} key={caseFilter.id}>
                            {caseFilter.value}
                          </Option>
                        )}
                      </Select>
                  )}
                </Item>
              </Col>

              <Col xs={4} className="small-input">
                <label>
                  {isValueDateTime( getFieldValue(`filters[${k}].key`) ) ? "Fecha:" : "Valor:"}
                </label>
              </Col>
              {isValueDateTime( getFieldValue(`filters[${k}].key`) ) ?
                <Col xs={20}>
                  <Item className="form-item">
                    {getFieldDecorator(`filters[${k}].value`, {
                      rules: [{ required: true, message: "Fecha requerida" }]
                    })(
                        <RangePicker format={"DD/MM/YYYY"} size={"small"} allowClear={false} disabled={getFieldValue(`filters[${k}].key`) === undefined}/>
                    )}
                  </Item>
                </Col>
                :
                <Col xs={20}>
                  <Item className="form-item">
                    {getFieldDecorator(`filters[${k}].value`, {
                      rules: [{ required: true, message: "Valor requerido" }]
                    })(
                      isValueSelectedFilterSearch( getFieldValue(`filters[${k}].key`) ) ?
                        <Select disabled={getFieldValue(`filters[${k}].key`) === undefined}
                                size={"small"}
                                placeholder={"Introduzca un texto para buscar (mínimo 3 letras)"}
                                showArrow={false}
                                filterOption={false}
                                onSearch={(value) => this.handleSearch(value, k)}
                                notFoundContent={null}
                                showSearch
                                filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {isFilterSearchDiagnosis(valueSelectedFilterSearch(getFieldValue(`filters[${k}].key`))) ?
                                  cie10List.map( item =>
                                    <Option key={item.id} value={item.id + ' - ' + item.value} size={"small"}>
                                      {item.id + ' - ' + item.value}
                                    </Option>
                                ):specimensList[valueSelectedFilter(getFieldValue(`filters[${k}].key`))].map( item =>
                                    <Option key={item.id} value={item.id}>
                                      {item.value}
                                    </Option>
                                )}
                        </Select>
                        :
                        isValueSelectedFilter(getFieldValue(`filters[${k}].key`)) ?
                          <Select disabled={getFieldValue(`filters[${k}].key`) === undefined}
                                  showSearch={true}
                                  size={"small"}
                                  optionFilterProp={"children"}
                                  filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                            {casesParams[valueSelectedFilter(getFieldValue(`filters[${k}].key`))].map( item =>
                              item.id !== undefined ?
                                <Option key={item.id} value={item.id}>
                                  {item.value}
                                </Option>
                            : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "campuses" ?
                              <Option key={item.campusId} value={item.campusId}>
                                {item.name}
                              </Option>
                                : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "contracts" ?
                                  <Option key={item.contractId} value={item.contractId}>
                                    {item.name}
                                  </Option>
                                : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "clients" ?
                                  <Option key={item.clientId} value={item.clientId}>
                                    {item.name}
                                  </Option>
                                  :
                                <Option key={item.specialistId} value={item.specialistId}>
                                  {item.names+" "+item.surnames}
                                </Option>
                            )}
                          </Select>
                          :
                          <Input disabled={getFieldValue(`filters[${k}].key`) === undefined}
                                 size={"small"}/>
                    )}
                  </Item>
                </Col>
              }
            </Row>
          </Col>
          <Col xs={1}>
            {restrictionKeys.length > 1
                ? <Col md={1} sm={1} xs={1} className={"center-align small-input delete-filter"}>
                  <Icon type="delete" onClick={() => this.removeRestriction(k)} />
            </Col> : null}
          </Col>
          <Divider className="divider-small-input" />
        </Row>
    ));

    const restrictionItems = restrictionKeys.map((k) => (
        <div key={k} className={"filters-main-container"}>
          <Col md={9} xs={9}>
            <Item className="form-item" label="Filtro">
              {getFieldDecorator(`filters[${k}].key`, {
                rules: [{ required: true, message: "Campo requerido" }]
              })(
                  <Select onChange={() => this.onChangeFilterTypeSelect(k)}>
                    {casesParams.caseFilters.map( caseFilter =>
                      <Option value={caseFilter.id} key={caseFilter.id}>
                        {caseFilter.value}
                      </Option>
                    )}
                  </Select>
              )}
            </Item>
          </Col>
          {isValueDateTime( getFieldValue(`filters[${k}].key`) ) ?
            <Col md={restrictionKeys.length > 1 ? 14 : 15} xs={restrictionKeys.length > 1 ? 14 : 15}>
              <Item className="form-item" label="Fechas">
                {getFieldDecorator(`filters[${k}].value`, {
                  rules: [{ required: true, message: "Fecha requerida" }]
                })(
                    <RangePicker format={"DD/MM/YYYY"} allowClear={false} disabled={getFieldValue(`filters[${k}].key`) === undefined}/>
                )}
              </Item>
            </Col>
            :
            <Col md={restrictionKeys.length > 1 ? 14 : 15} xs={restrictionKeys.length > 1 ? 14 : 15}>
              <Item className="form-item" label="Valor">
                {getFieldDecorator(`filters[${k}].value`, {
                  rules: [{ required: true, message: "Valor requerido" }]
                })(
                  isValueSelectedFilterSearch( getFieldValue(`filters[${k}].key`) ) ?
                    <Select disabled={getFieldValue(`filters[${k}].key`) === undefined}
                            placeholder={"Introduzca un texto para buscar (mínimo 3 letras)"}
                            showArrow={false}
                            onSearch={(value) => this.handleSearch(value, k)}
                            notFoundContent={null}
                            showSearch
                            filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {isFilterSearchDiagnosis(valueSelectedFilterSearch(getFieldValue(`filters[${k}].key`))) ?
                        cie10List.map( item =>
                            <Option key={item.id} value={item.id + ' - ' + item.value}>
                              {item.id + ' - ' + item.value}
                            </Option>
                        ):specimensList[valueSelectedFilter(getFieldValue(`filters[${k}].key`))].map( item =>
                            <Option key={item.id} value={item.id}>
                              {item.value}
                            </Option>
                        )}
                    </Select>
                    :
                    isValueSelectedFilter(getFieldValue(`filters[${k}].key`)) ?
                      <Select disabled={getFieldValue(`filters[${k}].key`) === undefined}
                              showSearch={true}
                              optionFilterProp={"children"}
                              filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                        {casesParams[valueSelectedFilter(getFieldValue(`filters[${k}].key`))].map( item =>
                          item.id !== undefined ?
                            <Option key={item.id} value={item.id}>
                              {item.value}
                            </Option>
                        : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "campuses" ?
                          <Option key={item.campusId} value={item.campusId}>
                            {item.name}
                          </Option>
                            : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "contracts" ?
                              <Option key={item.contractId} value={item.contractId}>
                                {item.name}
                              </Option>
                            : valueSelectedFilter(getFieldValue(`filters[${k}].key`)) === "clients" ?
                              <Option key={item.clientId} value={item.clientId}>
                                {item.name}
                              </Option>
                              :
                            <Option key={item.specialistId} value={item.specialistId}>
                              {item.names+" "+item.surnames}
                            </Option>
                        )}
                      </Select>
                      :
                      <Input disabled={getFieldValue(`filters[${k}].key`) === undefined}/>
                )}
              </Item>
            </Col>
          }
          {restrictionKeys.length > 1
              ? <Col md={1} xs={1} className={"center-align margin-top-field"}>
                <Icon type="minus-circle-o" onClick={() => this.removeRestriction(k)} />
          </Col> : null}
        </div>
    ));
    return (
        <Row className={"case-list-filters"}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Filtros de búsqueda" key="1">
              <Form>
                {!smallSize ? (
                  <Col md={24} sm={24} xs={0}>
                    {restrictionItems}
                  </Col>
                ):(
                  <Col md={0} sm={0} xs={24}>
                    {restrictionItemsSmall}
                  </Col>
                )}
                <Col md={10} sm={10} xs={0} className={"add-buttons"}>
                  <Button type={"default"} icon={"plus"} onClick={() => this.addRestriction()}>
                    Agregar restricción
                  </Button>
                </Col>

                <Col md={0} sm={0} xs={24} className={"right-align"}>
                  <Row>
                    <Button type="danger" shape="circle" onClick={this.clearFilters}>
                      <ClearIcon size={18} fill={"white"}/>
                    </Button>
                    <Button type="default" shape="circle" icon="plus" onClick={() => this.addRestriction()} />
                  </Row>
                </Col>

                <Col md={0} sm={0} xs={24}>
                  <Button block type="default" icon={"search"} onClick={this.validateForm}>
                    Buscar
                  </Button>
                </Col>

                <Col md={14} sm={14} xs={0} className={"add-buttons right-align"}>
                    <Button type="danger" onClick={this.clearFilters}>
                      <ClearIcon size={18} fill={"white"}/>
                    </Button>
                    <Button type="default" icon={"search"} onClick={this.validateForm}>
                      Buscar
                    </Button>
                </Col>
              </Form>
            </Panel>
          </Collapse>
        </Row>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.array,
  identificationFilter: PropTypes.string,
  filterVm: PropTypes.object,
  pagination: PropTypes.object,
  onFilterIdentification: PropTypes.func,
  handleChangeFilters: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    casesParams: state.casesReducer.casesParams,
    specimensList: state.specimensReducer.specimensList,
    cie10List: state.casesReducer.cie10List,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPaginated: (data) => dispatch(getAllPaginated(data)),
    getAllSpecimens: (data) => dispatch(getAllSpecimens(data)),
    getCie10: (data) => dispatch(getCie10(data)),
    cleanCie10List: () => dispatch(cleanCie10List()),
    cleanSpecimensList: () => dispatch(cleanSpecimensList()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Filters));