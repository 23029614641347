import React from "react";
import {Button, Modal} from 'antd';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

//Functions
import {clearStorage} from "../../../utils/accountUtils";

//Constants
import routes from "../../../lib/routes";

class LogoutModal extends React.Component{
  constructor (props) {
    super(props);

    this.state = {
      isMounted: false,
      modalVisible: false
    };
  };

  componentDidMount(){
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
  }
  onClickLogout(){
    clearStorage();
    window.location.href = routes.home;
  }

  render(){
    const { onToggleModal } = this.props;

    return(
        <Modal title={"Cerrar sesión"} destroyOnClose={true} keyboard={false} closable={false}
               visible={true}
               footer={[
                 <Button type={"default"} key="back" onClick={() => onToggleModal(false)}>
                   Cancelar
                 </Button>,
                <Button key="submit" type="primary" onClick={this.onClickLogout}>
                   Cerrar sesión
                </Button>
               ]}>
          ¿Está seguro de salir de la sesión actual?
        </Modal>
    );
  };
}


LogoutModal.propTypes = {
  onToggleModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);