/*--------------- General ---------------*/
export const generalTypes = {
  LOADING: "LOADING",
  SET_CURRENT_ROUTE: "SET_CURRENT_ROUTE",
};

/*--------------- AccountManagement ---------------*/
export const accountTypes = {
  LOGGING: "LOGGING",
};

/*--------------- Clients ---------------*/
export const clientsTypes = {
  GET_ALL_CLIENTS: "GET_ALL_CLIENTS",
};
/*--------------- Headquarters ---------------*/
export const headquartersTypes = {
  GET_ALL_HEADQUARTERS: "GET_ALL_HEADQUARTERS",
  GET_PARAMS_HEADQUARTERS: "GET_PARAMS_HEADQUARTERS",
};
/*--------------- Contract ---------------*/
export const contractsTypes = {
  GET_ALL_CONTRACTS: "GET_ALL_CONTRACTS",
  GET_CONTRACT_PARAMS: "GET_CONTRACT_PARAMS",
  GET_CONTRACT_DETAIL: "GET_CONTRACT_DETAIL",
};
/*--------------- Specialists ---------------*/
export const specialistsTypes = {
  GET_ALL_SPECIALISTS: "GET_ALL_SPECIALISTS",
  GET_SPECIALTIES: "GET_SPECIALTIES",
};
/*--------------- Specialists ---------------*/
export const employeesTypes = {
  GET_ALL_EMPLOYEES: "GET_ALL_EMPLOYEES",
  GET_ROLES: "GET_ROLES",
};
/*--------------- Specimen ---------------*/
export const specimenTypes = {
  GET_ALL_SPECIMENS: "GET_ALL_SPECIMENS",
};
/*--------------- Cases ---------------*/
export const casesTypes = {
  GET_CASES_PARAMS: "GET_CASES_PARAMS",
  GET_ALL_PAGINATED_CASES_LIST: "GET_ALL_PAGINATED_CASES_LIST",
  GET_CAMPUS_BY_CLIENT_ID: "GET_CAMPUS_BY_CLIENT_ID",
  GET_CONTRACT_BY_CAMPUS_ID: "GET_CONTRACT_BY_CAMPUS_ID",
  GET_SPECIMEN_CONTRACT_BY_CONTRACT_ID: "GET_SPECIMEN_CONTRACT_BY_CONTRACT_ID",
  GET_CASE_DETAIL_BY_ID: "GET_CASE_DETAIL_BY_ID",
  GET_CIE_10: "GET_CIE_10",
  GET_HISTORY: "GET_HISTORY",
  GET_HISTORY_VERSIONS: "GET_HISTORY_VERSIONS",
};