// Types
import { casesTypes } from '../../types';

// Initial State
const initialState = {
  casesParams: {
    identificationTypes: [],
    ageMeasuringUnits: [],
    genders: [],
    regimes: [],
    clients: [],
    planningMethods: [],
    menstrualCycles: [],
    neckAspects: [],
    tracings: [],
    sampleQualities: [],
    otherFindings: [],
    microorganisms: [],
    generalCategorizations: [],
    squamousCells: [],
    glandularCells: [],
    cytologists: [],
    pathologists: [],
    caseStatuses: [],
    caseFilters: [],
  },
  casesList: [],
  cie10List: [],
  pagination: {},
  totalCasesList: 0,
  paginationCases: {},
  campusParams: [],
  contractParams: [],
  specimenContractParams: [],
  caseDetail: {
    patient: {},
    caseStatus: {},
    cytologist: {},
    cytologyAnalysisResult: {},
    cytologyHistory: {},
    specimenContractCases: [
      {
        specimenContract: {
          contract: {
            campus: {
              client: {},
            },
          },
        },
      },
    ],
    cytologyAnalysisResult: {
      neckAspectsIds: [],
      otherFindingsIds: [],
      microorganismsIds: [],
      squamousCellAbnormalitiesIds: [],
      glandularCellAbnormalitiesIds: [],
    },
  },
  caseHistory: null,
  caseHistoryVersions: [],
};

export default function casesReducer (state = initialState, action) {
  switch (action.type) {
    case casesTypes.GET_CASES_PARAMS:
      return{
        ...state,
        casesParams: action.casesParams
      };
    case casesTypes.GET_ALL_PAGINATED_CASES_LIST:
      return{
        ...state,
        casesList: action.casesList,
        pagination: action.pagination,
        totalCasesList: action.totalCasesList,
      };
    case casesTypes.GET_CAMPUS_BY_CLIENT_ID:
      return{
        ...state,
        campusParams: action.campusParams
      };
    case casesTypes.GET_CONTRACT_BY_CAMPUS_ID:
      return{
        ...state,
        contractParams: action.contractParams
      };
    case casesTypes.GET_SPECIMEN_CONTRACT_BY_CONTRACT_ID:
      return{
        ...state,
        specimenContractParams: action.specimenContractParams
      };
    case casesTypes.GET_CASE_DETAIL_BY_ID:
      return{
        ...state,
        caseDetail: action.caseDetail
      };
    case casesTypes.GET_CIE_10:
      return{
        ...state,
        cie10List: action.cie10List
      };
    case casesTypes.GET_HISTORY:
      return{
        ...state,
        caseHistory: action.caseHistory
      };
    case casesTypes.GET_HISTORY_VERSIONS:
      return{
        ...state,
        caseHistoryVersions: action.caseHistoryVersions
      };
    default:
      return state;
  }
}