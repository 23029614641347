export const MENU_CONSTANTS = {
  contracts: "contracts",
  specialists: "specialists",
  headquarters: "headquarters",
  employees: "employees",
  clients: "clients",
  contractsSpecimenTable: "contractsSpecimenTable",
};

export const PAGINATION_CONFIG = {
  showSizeChanger: true,
  size: "small",
  showTotal: (total, range) => `${range[1] - range[0] + 1} de ${total}`,
  pageSizeOptions: ["5", "10", "15", "20"],
};

export const DATA_LABELS = {
  name: "Nombre",
  email: "Correo",
  nit: "NIT",
  isActive: "Activo",
  clientName: "Cliente",
  campusName: "Sede",
  letterWithLogo: "Carta con logo",
  degree: "Título",
  medicalNumber: "Registro médico",
  names: "Nombres",
  surnames: "Apellidos",
  tariff: "Tarifa",
  cases: "Casos en el contrato",
  prefix: "Prefijo",
};

export const DATA_SELECTS = {
  client: 0,
  campus: 1,
  contract: 2,
  specimen: 3,
};

export const IDENTIFICATION_TYPE = {
  CC: 1,
  CE: 2,
  TI: 3,
  NIP: 4,
  PP: 5,
};

export const AGE_MEASURING_UNIT= {
  years: 1,
  months: 2,
  days: 3,
};

export const GENERAL_CATEGORIZATION = {
  epithelialCellAbnormalities: 2,
};

export const OTHER_PLANNING_METHOD = {
  other: 8,
};

export const RENDER_ROLE = {
  client: {role: "Client", privileges: 1},
  specialist: {role: "Specialist", privileges: 2},
  receptor: {role: "Receptor", privileges: 3},
  administrator: {role: "Admin", privileges: 3},
};

export const PRIVILEGE_LEVEL = {
  low: 1,
  middle: 2,
  high : 3,
};

export const STATUS_CONSTANTS = {
  completed: "Completado",
  submittedMaterial: "Material remitido",
  pendingDiagnosis: "Pendiente de diagnóstico",
};

export const FILTERS_VALUES = [
  { key: "1", property: "caseTypes", isValueSelectedFilter: true},
  { key: "2", property: "caseId"},
  { key: "3", property: "patientName"},
  { key: "4", property: "genders", isValueSelectedFilter: true},
  { key: "5", property: "PatientIdentification"},
  { key: "6", property: "receptionDate", valueDateTime: true, dateType: "Reception"},
  { key: "7", property: "creationDate", valueDateTime: true, dateType: "Created"},
  { key: "8", property: "lastModificationDate", valueDateTime: true, dateType: "LastModified"},
  { key: "9", property: "caseStatuses", isValueSelectedFilter: true},
  { key: "10", property: "contracts", isValueSelectedFilter: true},
  { key: "11", property: "campuses", isValueSelectedFilter: true},
  { key: "12", property: "clients", isValueSelectedFilter: true},
  { key: "13", property: "pathologists", isValueSelectedFilter: true},
  { key: "14", property: "cytologists", isValueSelectedFilter: true},
  { key: "15", property: "eps"},
  { key: "16", property: "diagnosis", onSearch: true},
  { key: "17", property: "specimens", onSearch: true},
  { key: "18", property: "isPositiveOptions", isValueSelectedFilter: true},
  { key: "19", property: "sampleQualities", isValueSelectedFilter: true},
  { key: "20", property: "generalCategorizations", isValueSelectedFilter: true},
  { key: "21", property: "glandularCells", isValueSelectedFilter: true},
  { key: "22", property: "squamousCells", isValueSelectedFilter: true},
  { key: "23", property: "internalNumber"},
  { key: "24", property: "isOncologicalOptions", isValueSelectedFilter: true},
  { key: "25", property: "isEpidemiologicalSurveillanceOptions", isValueSelectedFilter: true},
];

export const STATE_COLOR = {
  green: "#60F530",
  yellow: "#FFEC31",
  orange: "#F59E30",
  red: "#FF0000",
  redWine: "#820000",
  gray: "#ABABAB",
  black: "#000000",
};