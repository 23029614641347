//Dependencies
import { combineReducers } from 'redux';

//Subcomponents
import generalReducer from "./general/generalReducer";
import accountReducer from "./account/accountReducer";
import clientsReducer from "./clients/clientsReducer";
import headquartersReducer from "./headquarters/headquartersReducer";
import contractsReducer from "./contracts/contractsReducer";
import specialistsReducer from "./specialists/specialistsReducer";
import employeesReducer from "./employees/employeesReducer";
import casesReducer from "./cases/casesReducer";
import specimensReducer from "./specimens/specimensReducer";

//Reducers
export default combineReducers({
  generalReducer: generalReducer,
  accountReducer: accountReducer,
  clientsReducer: clientsReducer,
  headquartersReducer: headquartersReducer,
  contractsReducer: contractsReducer,
  specialistsReducer: specialistsReducer,
  employeesReducer: employeesReducer,
  casesReducer: casesReducer,
  specimensReducer: specimensReducer,
});