//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Button, Select, Icon } from "antd";
import { connect } from "react-redux";

// Services
import {
  getAllPaginated, getCaseDetail, getParams, generateCasesReport, generatePDFsReport, generateZIP
} from "../../store/redux/actions/cases/casesActions";

//Components
import RightContainer from "../subComponents/RightContainer";
import CaseTable from "./CaseTable";
import FormatNameModal from "./modals/FormatNameModal";

//Constants
import Filters from "./Filters";
import AddEditModal from "./modals/AddEditModal";
import { RENDER_ROLE } from '../../utils/constants'
const { Option } = Select;

class CaseList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      selectedCase: undefined,
      showAddEditModal: false,
      showFormatNameModal: false,
      generateFileFunction: null,
      generateFileFunctionData: null,
      actionModal: "",
      caseToEdit: null,
      isCytology: false,
      pagination: {
        pageSize: 5,
        page: 1,
      },
      filterVm: {
        textFilters:{},
      },
      filters: [],
      identificationFilter: null,
    };

    this.onSelectCase = this.onSelectCase.bind(this);
    this.onToggleAddEditModal = this.onToggleAddEditModal.bind(this);
    this.onToggleFormatNameModal = this.onToggleFormatNameModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.onChangePaginationConfig = this.onChangePaginationConfig.bind(this);
    this.onFilterIdentification = this.onFilterIdentification.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        let data = { isCytology: true };
        this.props.getParams(data);
        let dataGetAll = {
          paginationVm: this.state.pagination,
          filterVm: {
            textFilters:{}
          },
        };
        this.props.getAllPaginated(dataGetAll);
      }
    });
  }

  componentDidUpdate(prevProps, prevState){
    const { casesList, getCaseDetail, caseDetail } = this.props;

    if(prevProps.casesList !== casesList){
      if(casesList.length > 0){
        getCaseDetail({ caseId: casesList[0].caseId});
      }
      else{
        this.setState({ selectedCase: undefined });
      }
    }
    if(prevProps.caseDetail !== caseDetail){
      this.setState({ selectedCase: caseDetail });
    }
  }

  onSelectCase(selectedCase){
    this.props.getCaseDetail({ caseId: selectedCase.caseId});
  }

  onToggleAddEditModal(isVisible, action = "", caseToEdit = null, isCytology = false){
    this.setState({
      showAddEditModal: isVisible,
      actionModal: action,
      caseToEdit: caseToEdit,
      isCytology: isCytology,
    });
    if(caseToEdit !== null){
      getCaseDetail({ caseId: caseToEdit.caseId});
    }
    else if(this.state.caseToEdit !== null){
      getCaseDetail({ caseId: this.state.caseToEdit.caseId});
    }
    else if(action === ""){
      const { casesList, getCaseDetail } = this.props;
      if(casesList.length > 0){
        let data = { isCytology: false };
        this.props.getParams(data);
        getCaseDetail({ caseId: casesList[0].caseId});
        this.setState({ selectedCase: undefined });
      }
      else{
        this.setState({ selectedCase: undefined });
      }
    }
  }

  onToggleFormatNameModal(isVisible, generateFileFunction, data){
    this.setState({
      showFormatNameModal: isVisible,
      generateFileFunction,
      generateFileFunctionData: data
    });

  }

  handleChange(value) {
    this.onToggleAddEditModal(true, "Add", null, value === "cytology");
  }

  handleChangeFilters(filterVm, filters){
    this.setState({
      filterVm: filterVm,
      filters: filters,
    });
  }

  onFilterIdentification(identification){
    this.setState({identificationFilter: identification});
  }

  onChangePaginationConfig(page, size){
    let dataGetAll = {
      paginationVm: {
        page: page,
        pageSize: size,
      },
      filterVm: this.state.filterVm,
    };
    this.setState({
      pagination: {
        page: page,
        pageSize: size,
      }
    });
    if(Object.keys(dataGetAll.filterVm).length === 0){
      dataGetAll.filterVm.textFilters = {};
    }
    this.props.getAllPaginated(dataGetAll);
  }

  render() {
    const { selectedCase, showAddEditModal, actionModal, caseToEdit, isCytology,
      pagination, identificationFilter, filterVm, filters,
      showFormatNameModal, generateFileFunction, generateFileFunctionData } = this.state;
    let role = localStorage.getItem("render_role");

    return (
        <Row className={!showAddEditModal ? "main-container" : "main-container section-list"}>
          {showAddEditModal ?
            <AddEditModal action={actionModal} caseToEdit={caseToEdit} isCytology={isCytology}
                          filterVm={filterVm}
                          onToggleAddEditModal={this.onToggleAddEditModal}/>
          :
          <div>
            {showFormatNameModal &&
              <FormatNameModal generateFileFunction={generateFileFunction}
                               generateFileFunctionData={generateFileFunctionData}
                               onToggleFormatNameModal={this.onToggleFormatNameModal}
              />
            }

            <Col lg={14} md={24} className={"side-container left-side"}>
              <Row>
                <Filters identificationFilter={identificationFilter}
                          pagination={pagination}
                          filters={filters} filterVm={filterVm}
                          onFilterIdentification={this.onFilterIdentification}
                          handleChangeFilters = {this.handleChangeFilters}/>
                <Col md={24} xs={24} className={"buttons-table-container no-margin"}>
                  {RENDER_ROLE.client.role !== role &&
                    <Select className={"add-case-select"} value={"default"}
                            style={{ width: 120 }} onChange={this.handleChange}>
                      <Option value="default" style={{ display: 'none' }}>
                        <div className={"div-case-select"}>
                          <span><Icon type="plus" />&nbsp;&nbsp;Agregar caso</span>
                        </div>
                      </Option>
                      <Option value="cytology">Citología</Option>
                      <Option value="surgicalCase">Quirúrgico</Option>
                    </Select>
                  }
                </Col>
                <Col md={24} xs={24} className={"buttons-table-container"}>
                  <Button className={"zip-button"} type={"default"} icon={"file-zip"}
                          onClick={() => this.onToggleFormatNameModal(true, this.props.generateZIP, { paginationVm: pagination, filterVm })}>
                          Exportar a ZIP
                  </Button>
                  <Button className={"pdf-button"} type={"default"} icon={"file-pdf"}
                          onClick={() => this.props.generatePDFsReport(pagination, filterVm)}>
                          Exportar a PDF
                  </Button>
                  <Button className={"excel-button"} type={"default"} icon={"file-excel"}
                          onClick={() => this.props.generateCasesReport(pagination, filterVm)}>
                          Exportar a Excel
                  </Button>
                </Col>
              </Row>
              <CaseTable selectedCase={selectedCase}
                         pagination={pagination}
                         filterVm={filterVm}
                         onSelectCase={this.onSelectCase}
                         onFilterIdentification={this.onFilterIdentification}
                         onChangePaginationConfig={this.onChangePaginationConfig}
                         onToggleAddEditModal={this.onToggleAddEditModal}
                         onToggleFormatNameModal={this.onToggleFormatNameModal} />
            </Col>
            <Col lg={10} md={0} xs={0} className={"side-container right-side"}>
              <RightContainer selectedCase={selectedCase}
                              filterVm={filterVm}
                              onFilterIdentification={this.onFilterIdentification}
              />
            </Col>
          </div>
          }
        </Row>
    );
  }
}

CaseList.propTypes = {
};

const mapStateToProps = (state) => {
  return {
    casesList: state.casesReducer.casesList,
    caseDetail: state.casesReducer.caseDetail,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPaginated: (data) => dispatch(getAllPaginated(data)),
    getCaseDetail: (data) => dispatch(getCaseDetail(data)),
    getParams: (data) => dispatch(getParams(data)),
    generateCasesReport: (pagination, filterVm) => dispatch(generateCasesReport(pagination, filterVm)),
    generatePDFsReport: (pagination, filterVm) => dispatch(generatePDFsReport(pagination, filterVm)),
    generateZIP: (data, formatName) => dispatch(generateZIP(data, formatName)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseList);