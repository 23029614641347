import { generalTypes, casesTypes } from "../../types";
import casesServices from "../../../../api/casesServices";
import headquartersServices from "../../../../api/headquartersServices";
import contractsServices from "../../../../api/contractsServices";
import routes from "../../../../lib/routes";
import baseURL from "../../../../api/baseUrl";

//Components
import {
  ERROR_MODAL,
  ERROR_MODAL_ON_OK,
  SUCCESS_MODAL,
} from "../../../../components/subComponents/responseModals";

//Functions

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const setInitialCaseDetail = () => {
  let caseDetail = {
    patient: {},
    caseStatus: {},
    cytologist: {},
    cytologyAnalysisResult: {},
    cytologyHistory: {},
    specimenContractCases: [
      {
        specimenContract: {
          contract: {
            campus: {
              client: {},
            },
          },
        },
      },
    ],
    cytologyAnalysisResult: {
      neckAspectsIds: [],
      otherFindingsIds: [],
      microorganismsIds: [],
      squamousCellAbnormalitiesIds: [],
      glandularCellAbnormalitiesIds: [],
    },
  };
  return {
    type: casesTypes.GET_CASE_DETAIL_BY_ID,
    caseDetail: caseDetail
  }
};
export const getAllPaginated = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.getAllPaginated(data)
        .then(response => {
          dispatch({
            type: casesTypes.GET_ALL_PAGINATED_CASES_LIST,
            casesList: response.data.collection,
            pagination: {
              pageSize: response.data.pageSize,
              page: response.data.page,
            },
            totalCasesList: response.data.rowCount,
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const getCie10 = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.getCie10(data)
        .then(response => {
          dispatch({
            type: casesTypes.GET_CIE_10,
            cie10List: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const cleanCie10List = () => {
  return dispatch => {
          dispatch({
            type: casesTypes.GET_CIE_10,
            cie10List: []
          });
        }
};

export const generateCaseReportPdf = (data, formatName) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.generatePdf({ caseId: data.caseId, formatName })
        .then(response => {
          window.open(baseURL + "/" + response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const generateCaseReportExcel = (data, formatName) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.generateExcel({ caseId: data.caseId, formatName })
        .then(response => {
          window.open(baseURL + "/" + response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const generateZIP = (data, formatName) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.generateZIP(data, formatName)
        .then(response => {
          window.open(baseURL + "/" + response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const generatePDFsReport = (paginationVm, filterVm) => {
  let data = {
    paginationVm,
    filterVm,
  }
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.generatePDFsReport(data)
        .then(response => {
          window.open(baseURL + "/" + response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const generateCasesReport = (pagination, filterVm) => {
  let data = {
    pagination,
    filterVm,
  }
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.generateCasesReport(data)
        .then(response => {
          window.open(baseURL + "/" + response.data);
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};

export const getCaseDetail = (data, resetCaseDetail = false) => {
  return dispatch => {
    dispatch(setLoading(true));
    if(resetCaseDetail) {
      dispatch(setInitialCaseDetail());
    }
    casesServices.getCaseDetail(data)
        .then(response => {
          let caseDetail = response.data;
          if(caseDetail.isCytology){
            if(caseDetail.cytologyAnalysisResult !== null){
              if(caseDetail.cytologyAnalysisResult.neckAspectsIds !== null){
                caseDetail.cytologyAnalysisResult.neckAspectsIds = caseDetail.cytologyAnalysisResult.neckAspectsIds.split(",");
              }
              if(caseDetail.cytologyAnalysisResult.otherFindingsIds !== null){
                caseDetail.cytologyAnalysisResult.otherFindingsIds = caseDetail.cytologyAnalysisResult.otherFindingsIds.split(",");
              }
              if(caseDetail.cytologyAnalysisResult.microorganismsIds !== null){
                caseDetail.cytologyAnalysisResult.microorganismsIds = caseDetail.cytologyAnalysisResult.microorganismsIds.split(","); 
              }
              if(caseDetail.cytologyAnalysisResult.squamousCellAbnormalitiesIds !== null){
                caseDetail.cytologyAnalysisResult.squamousCellAbnormalitiesIds = caseDetail.cytologyAnalysisResult.squamousCellAbnormalitiesIds.split(",");
              }
              if(caseDetail.cytologyAnalysisResult.glandularCellAbnormalitiesIds !== null){
                caseDetail.cytologyAnalysisResult.glandularCellAbnormalitiesIds = caseDetail.cytologyAnalysisResult.glandularCellAbnormalitiesIds.split(",");
              }
            }
          }
          dispatch({
            type: casesTypes.GET_CASE_DETAIL_BY_ID,
            caseDetail: caseDetail
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          if(resetCaseDetail) {
            ERROR_MODAL_ON_OK(err.data, () => window.location.href = routes.caseList);
          } else {
            ERROR_MODAL(err.data);
          }
          dispatch(setLoading(false));
        })
  }
};
export const getParams = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.getParams(data)
        .then(response => {
          dispatch({
            type: casesTypes.GET_CASES_PARAMS,
            casesParams: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const getCampusAllByClientId = (data) => {
  return dispatch => {
  dispatch(setLoading(true));
  headquartersServices.GetAllByClientId(data)
      .then(response => {
        dispatch({
          type: casesTypes.GET_CAMPUS_BY_CLIENT_ID,
          campusParams: response.data
        });
        dispatch(setLoading(false));
      })
      .catch(err => {
        ERROR_MODAL(err.data);
        dispatch(setLoading(false));
      })
  }
};
export const getContractAllByCampusId = (data) => {
  return dispatch => {
  dispatch(setLoading(true));
  contractsServices.GetAllByCampusId(data)
        .then(response => {
        dispatch({
          type: casesTypes.GET_CONTRACT_BY_CAMPUS_ID,
          contractParams: response.data
        });
        dispatch(setLoading(false));
      })
      .catch(err => {
        ERROR_MODAL(err.data);
        dispatch(setLoading(false));
      })
  }
};
export const getSpecimensContractByContractId = (data) => {
  return dispatch => {
  dispatch(setLoading(true));
  contractsServices.getSpecimensContractByContractId(data)
        .then(response => {
        dispatch({
          type: casesTypes.GET_SPECIMEN_CONTRACT_BY_CONTRACT_ID,
          specimenContractParams: response.data
        });
        dispatch(setLoading(false));
      })
      .catch(err => {
        ERROR_MODAL(err.data);
        dispatch(setLoading(false));
      })
  }
};
export const add = (data, callback, pagination, filterVm) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.add(data)
        .then(response => {
          SUCCESS_MODAL(response.data);
          let dataGetAll = {
            paginationVm: pagination,
            filterVm: filterVm,
          };
          dispatch(getAllPaginated(dataGetAll));
          dispatch(setLoading(false));
          callback(false);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const update = (data, callback, pagination, filterVm) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.update(data)
        .then(response => {
          SUCCESS_MODAL(response.data);
          let dataGetAll = {
            paginationVm: pagination,
            filterVm: filterVm,
          };
          dispatch(getAllPaginated(dataGetAll));
          dispatch(setLoading(false));
          callback(false);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const remove = (caseId, pagination, filterVm) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.remove(caseId)
      .then(response => {
        SUCCESS_MODAL(response.data);
        let dataGetAll = {
          paginationVm: pagination,
          filterVm: filterVm,
        };
        dispatch(getAllPaginated(dataGetAll));
        dispatch(setLoading(false));
      })
      .catch(err => {
        ERROR_MODAL(err.data);
        dispatch(setLoading(false));
      })
  }
};
export const submitMaterial = (selectedCase, pagination, filterVm) => {
  let data = { caseId: selectedCase.caseId};
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.submitMaterial(data)
        .then(response => {
          SUCCESS_MODAL(response.data);
          dispatch(getCaseDetail(data));
          let dataGetAll = {
            paginationVm: pagination,
            filterVm: filterVm,
          };
          dispatch(getAllPaginated(dataGetAll));
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
    }
};
export const getCaseHistory = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    casesServices.getCaseHistory(data)
        .then(response => {
          dispatch({
            type: casesTypes.GET_HISTORY,
            caseHistory: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
    }
};
export const getCaseHistoriesByCaseId = (data) => {
  return dispatch => {
    dispatch(resetCaseHistory());
    dispatch(setLoading(true));
    casesServices.getCaseHistoriesByCaseId(data)
        .then(response => {
          dispatch({
            type: casesTypes.GET_HISTORY_VERSIONS,
            caseHistoryVersions: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
    }
};
export const resetCaseHistory = () => {
  return dispatch => {
    dispatch({
      type: casesTypes.GET_HISTORY,
      caseHistory: null
    });
    dispatch({
      type: casesTypes.GET_HISTORY_VERSIONS,
      caseHistoryVersions: []
    });
  }
};