import request from "./requestWrapper";

function getAll() {
  return request({
    url: '/Specialist/GetAll',
    method: 'GET'
  });
}
function add(data) {
  return request({
    url: '/Specialist/Add',
    method: 'POST',
    data: data
  });
}
function update(data) {
  return request({
    url: '/Specialist/Update',
    method: 'PUT',
    data: data
  });
}
function remove(data) {
  return request({
    url: '/Specialist/Delete',
    method: 'DELETE',
    data: data
  });
}
function getSpecialties() {
  return request({
    url: '/Specialist/GetSpecialties',
    method: 'GET'
  });
}

export default {
  getAll, add, update, remove, getSpecialties
}