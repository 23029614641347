//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col, Input, Button, Form, Table, message } from "antd";
import { connect } from "react-redux";
import { getTableColumns } from "../../utils/functions";
import { MENU_CONSTANTS } from "../../utils/constants";
import { PAGINATION_CONFIG } from "../../utils/constants";

//Component
import EditModal from "../subComponents/EditModal";

//Services
import { getContractDetail } from "../../store/redux/actions/contracts/contractsActions";

const { Item } = Form;


class EditContract extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false,
      placement: "top",
      pagination: {
        pageSize: 5,
        current: 1
      },
      editModalVisible: false,
      specimensIds: [],
      specimensContracts: [],
    };
    this.onToggleEditModal = this.onToggleEditModal.bind(this);
    this.editContract = this.editContract.bind(this);
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        const { selectedItem } = this.props;
        this.setState({
          isMounted: false,
        });
        let data = { contractId: selectedItem.contractId };
        this.props.getContractDetail(data);
      }
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.specimensContracts !== this.props.specimensContracts){
      let specimensContractsIds = [];
      this.props.specimensContracts.forEach((specimenContract,index) => {
        specimensContractsIds[index] = {
          price: specimenContract.defaultTariff,
          specimenId: specimenContract.key,
        };
      });
      this.setState({specimensIds: specimensContractsIds});
    }
    if(prevProps.specimensContracts !== this.props.specimensContracts){
      this.setState({
        specimensContracts: this.props.specimensContracts,
      });
    }
  }

  validateForm() {
    const { form } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      else {
        values.specimens = this.state.specimensIds;
        values.contractId = this.props.selectedItem.contractId;
        delete values.campus;
        this.props.editFunction(values);
      }
    });
  }

  onChangePaginationConfig (current, pageSize) {
    this.setState({ pagination: {
        current: current,
        pageSize: pageSize
      }
    });
  };

  onToggleEditModal (isVisible) {
    this.setState({
      editModalVisible: isVisible,
    });
  };

  editContract(data) {
    const { specimenTables } = this.props.contractsParams;
    const { specimensContracts } = this.state;
    let newSpecimensContracts = [];

    specimenTables.forEach( specimenTable =>{
      specimenTable.specimenCategories.forEach( specimenCategory =>{
        specimenCategory.specimens.forEach( specimen =>{
          if(data.specimensIds.find(s => s === specimen.specimenId) !== undefined){
            let defaultTariff = 0;
            data.specimens.forEach((specimenContract)=>{
              if(specimen.specimenId === specimenContract.specimenId) defaultTariff = specimenContract.price
            });
            newSpecimensContracts.push({
              key: specimen.specimenId,
              defaultTariff: defaultTariff,
              category: specimenCategory.name,
              specimen: "(" + specimen.cupsCode +") " + specimen.name,
            });
          }
        })
      })
    });
    this.setState({
      specimensIds: data.specimens,
      specimensContracts: newSpecimensContracts,
    });
    message.config({
      top: 60,
      duration: 4,
    });
    message.success('Tabla de especímenes exitosamente. Recuerda guardar los cambios antes de salir.');
  };

  render() {
    const { editModalVisible, specimensContracts } = this.state;
    const { pageSize, current } = this.state.pagination;
    const { onToggleEditContract, selectedItem } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        {editModalVisible &&
          <EditModal title={"Modificar tabla de especímenes"} section={MENU_CONSTANTS.contractsSpecimenTable}
                     onToggleEditModal={this.onToggleEditModal}
                     specimensContracts={specimensContracts}
                     editFunction={this.editContract} />
        }
        <Col>
          <Form>
            <Row>
              <Col xs={24}>
                <Item className="form-item" label="Nombre del contrato">
                  {getFieldDecorator("name", {
                    initialValue: selectedItem.name,
                    rules: [
                      {
                        required: true,
                        message: "Campo requerido",
                        whitespace: false,
                      },
                    ],
                  })(<Input type="text" autoComplete={"off"} />)}
                </Item>
              </Col>
              <Col xs={24}>
                <Item className="form-item" label="Sede">
                  {getFieldDecorator("campus.name", {
                    initialValue: selectedItem.campus.name,
                    rules: [
                      {
                        required: true,
                        message: "Campo requerido",
                        whitespace: false,
                      },
                    ],
                  })(<Input type="text" autoComplete={"off"} disabled/>)}
                </Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={24} className="right-align">
            <Button type={"default"} icon="setting" onClick={() => this.onToggleEditModal(true)}>
              Modificar tabla de especímenes
            </Button>
            <br />
            <br />
        </Col>
        <Col className="list-table">
              <Table dataSource={specimensContracts}
                     columns={getTableColumns(MENU_CONSTANTS.contractsPrices, null)}
                     scroll={{ x: "750px" }}
                     pagination={{
                        total: specimensContracts.length,
                        current: current,
                        showSizeChanger: PAGINATION_CONFIG.showSizeChanger,
                        pageSize: pageSize,
                        size: PAGINATION_CONFIG.size,
                        showTotal: (total, range) => PAGINATION_CONFIG.showTotal(total, range),
                        pageSizeOptions: PAGINATION_CONFIG.pageSizeOptions,
                        onChange: (page, size) => {
                          this.onChangePaginationConfig(page, size);
                        },
                        onShowSizeChange: (page, size) => {
                          this.onChangePaginationConfig(page, size);
                        },
                     }}
              />
        </Col>
        <Col className="right-align">
          <Button type={"default"} key="back" onClick={() => onToggleEditContract(false)}>
            Cancelar
          </Button>
          &nbsp;&nbsp;
          <Button key="submit" type="primary" onClick={() => this.validateForm()} >
            Guardar
          </Button>
        </Col>
      </Row>
    );
  }
}

EditContract.propTypes = {
  selectedItem: PropTypes.object,
  onToggleEditContract: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    specimensContracts: state.contractsReducer.specimensContracts,
    contractsParams: state.contractsReducer.contractsParams,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContractDetail: (data) => dispatch(getContractDetail(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditContract));