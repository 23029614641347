import { message } from 'antd';
import { MENU_CONSTANTS, STATE_COLOR, RENDER_ROLE, PRIVILEGE_LEVEL,
         STATUS_CONSTANTS, FILTERS_VALUES } from "./constants";
import { thousandsNumberSeparator } from "./stringUtils";
import moment from "moment";

export function beforeUpload(file){
    if(file !== null){
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('Solo se admiten imágenes JPG/PNG.');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('La imagen debe ser menor a 2MB.');
        }
        return isJpgOrPng && isLt2M;
    }
    else{
        return null;
    }
}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

export function getPrivilege(role) {
  if(role === RENDER_ROLE.client.role) return RENDER_ROLE.client.privileges;
  else if(role === RENDER_ROLE.specialist.role) return RENDER_ROLE.specialist.privileges;
  else if(role === RENDER_ROLE.receptor.role) return RENDER_ROLE.receptor.privileges;
  else if(role === RENDER_ROLE.administrator.role) return RENDER_ROLE.administrator.privileges;
  else return PRIVILEGE_LEVEL.low;
};

export function getColorStatus(receptionDate, caseStatus) {
  if(caseStatus.value === STATUS_CONSTANTS.completed){
    return STATE_COLOR.gray;
  }
  if(caseStatus.value === STATUS_CONSTANTS.submittedMaterial){
    return STATE_COLOR.black;
  }
  else{
    let currentDate = moment().set({'hour': 0, 'minute': 0, 'second': 0, millisecond: 0 });
    let daysLate = moment(currentDate).diff(moment(receptionDate,"DD/MM/YYYY"), 'days');
    if(daysLate <= 2)
      return STATE_COLOR.green;
    else if((4 >= daysLate) && (daysLate > 2)){
      return STATE_COLOR.yellow;
    }
    else if((6 >= daysLate) && (daysLate > 4)){
      return STATE_COLOR.orange;
    }
    else if((8 >= daysLate) && (daysLate > 6)){
      return STATE_COLOR.red;
    }
    else if(daysLate > 8){
      return STATE_COLOR.redWine;
    }
    else{
      return STATE_COLOR.gray;
    }
  }
};

export function isDownloadable(caseStatus) {
  if(caseStatus.value === STATUS_CONSTANTS.completed || caseStatus.value === STATUS_CONSTANTS.submittedMaterial) return true;
  else return false
};

export function isStatusComplete(currentCaseStatus, caseStatus, isSpecialistAndAssigned = false) {
  if(currentCaseStatus.name === STATUS_CONSTANTS.completed){
    if(caseStatus.value === STATUS_CONSTANTS.completed || caseStatus.value === STATUS_CONSTANTS.submittedMaterial) return false;
    if(isSpecialistAndAssigned && caseStatus.value === STATUS_CONSTANTS.pendingDiagnosis) return false;
    else return true;
  }
  else if(currentCaseStatus.name === STATUS_CONSTANTS.submittedMaterial){
    if(caseStatus.value === STATUS_CONSTANTS.submittedMaterial) return false;
    else return true;
  }
  else{
    if(caseStatus.value !== STATUS_CONSTANTS.submittedMaterial) return false;
    else return true;
  }
};

export function isValueDateTime(currentCaseFilter) {
  let isValueDateTime = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  if(isValueDateTime){
    if (isValueDateTime.valueDateTime) return true;
    else return false;
  }
};

export function isValueSelectedFilterSearch(currentCaseFilter) {
  let isValueSelectedFilterSearch = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  if(isValueSelectedFilterSearch){
    if (isValueSelectedFilterSearch.onSearch) return true;
    else return false;
  }
};

export function valueSelectedFilterSearch(currentCaseFilter) {
  let isValueSelectedFilterSearch = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  if(isValueSelectedFilterSearch){
    return isValueSelectedFilterSearch.property;
  }
};

export function isFilterSearchDiagnosis(filterName) {
  let isValueSelectedFilterSearch = FILTERS_VALUES.find( item => item.key === '16');
  return isValueSelectedFilterSearch.property === filterName;
};

export function valueDateTime(currentCaseFilter) {
  let valueDateTime = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  return valueDateTime.dateType;
};

export function isValueSelectedFilter(currentCaseFilter) {
  let isValueSelectedFilter = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  if(isValueSelectedFilter){
    if (isValueSelectedFilter.isValueSelectedFilter) return true;
    else return false;
  }
};

export function valueSelectedFilter(currentCaseFilter) {
  let valueSelectedFilter = FILTERS_VALUES.find( item => item.key === currentCaseFilter);
  return valueSelectedFilter.property;
};

export function clientRestriction() {
  let restriction = FILTERS_VALUES.find( item => item.property === "clients");
  return restriction.key-1;
};

export function dummyRequest({ file, onSuccess }) {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export function getTableColumns(section, extraIcons = null, windowWidth){
  let columns = [];

  switch (section) {
    case MENU_CONSTANTS.specialists:
      columns = [
        { title: 'Nombres', dataIndex: 'names', key: 'names' },
        { title: 'Apellidos', dataIndex: 'surnames', key: 'surnames' },
        { title: 'Acciones', dataIndex: '', key: '', render: (item) => extraIcons(item),
          fixed: windowWidth < 700 ? 'right' : '' }
      ];
      break;
    case MENU_CONSTANTS.clients:
      columns = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Correo', dataIndex: 'email', key: 'email' },
        { title: 'Acciones', dataIndex: '', key: '', render: (item) => extraIcons(item),
          fixed: windowWidth < 700 ? 'right' : '' }
      ];
      break;
    case MENU_CONSTANTS.employees:
      columns = [
        { title: 'Nombres', dataIndex: 'names', key: 'names' },
        { title: 'Apellidos', dataIndex: 'surnames', key: 'surnames' },
        { title: 'Acciones', dataIndex: '', key: '', render: (item) => extraIcons(item),
          fixed: windowWidth < 700 ? 'right' : '' }
      ];
      break;
    case MENU_CONSTANTS.contracts:
      columns = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Sede', dataIndex: `campus.${'name'}`, key: `campus.${'name'}` },
        { title: 'Acciones', dataIndex: '', key: '', render: (item) => extraIcons(item),
          fixed: windowWidth < 700 ? 'right' : '' }
      ];
      break;
    case MENU_CONSTANTS.contractsPrices:
      columns = [
        { title: 'Categoría', dataIndex: 'category', key: 'category' },
        { title: 'Especímenes', dataIndex: 'specimen', key: 'specimen' },
        { title: 'Tarifa', dataIndex: 'defaultTariff', key: 'defaultTariff', render: tariff => { return "$"+thousandsNumberSeparator(tariff) }, },
      ];
      break;
    case MENU_CONSTANTS.headquarters:
      columns = [
        { title: 'Nombre', dataIndex: 'name', key: 'name' },
        { title: 'Cliente', dataIndex: `client.${'name'}`, key: `client.${'name'}` },
        { title: 'Prefijo', dataIndex: 'prefix', key: 'prefix' },
        { title: 'Acciones', dataIndex: '', key: '', render: (item) => extraIcons(item),
          fixed: windowWidth < 700 ? 'right' : '' }
      ];
      break;
    default:
      break;
    }
    return columns;
}