//Libraries
import React, {Component} from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Layout } from "antd";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

//Components
import { PrivateRoute } from "./PrivateRoute";
import Login from "../account/Login";
import Register from "../account/Register";
import ForgotPassword from "../account/ForgotPassword";
import ResetPassword from "../account/ResetPassword";
import CaseList from "../caseList/CaseList";
import Contract from "../contract/Contract";
import SpecialistList from "../specialists/Specialists";
import Headquarters from "../headquarters/Headquarters";
import Employees from "../employees/Employees";
import ActionsRecord from "../actionsRecord/ActionsRecord";
import Clients from "../clients/Clients";
import { getPrivilege } from '../../utils/functions';

//Constants
import routes from '../../lib/routes';
import { PRIVILEGE_LEVEL } from "../../utils/constants";

class Router extends Component {
  render() {
    const { loggedIn } = this.props;
    let rolePrivilege = getPrivilege(localStorage.getItem("render_role"));
    return (
        <Layout.Content className={"content"}>
          <Switch>
            {!loggedIn && <Route exact path={routes.login} render={() => <Login />}/>}
            {!loggedIn && <Route exact path={routes.register} render={() => <Register />}/>}
            {!loggedIn && <Route exact path={routes.forgotPassword} render={() => <ForgotPassword />}/>}
            {!loggedIn && <Route path={routes.newPassword} render={() => <ResetPassword />}/>}

            { rolePrivilege >= PRIVILEGE_LEVEL.low && <PrivateRoute  exact path={routes.caseList} loggedIn={loggedIn} component={CaseList} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.middle && <PrivateRoute  exact path={routes.contract} loggedIn={loggedIn} component={Contract} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.middle && <PrivateRoute  exact path={routes.clients} loggedIn={loggedIn} component={Clients} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.middle && <PrivateRoute  exact path={routes.headquarters} loggedIn={loggedIn} component={Headquarters} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.high && <PrivateRoute  exact path={routes.specialist} loggedIn={loggedIn} component={SpecialistList} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.high && <PrivateRoute  exact path={routes.actionsRecord} loggedIn={loggedIn} component={ActionsRecord} /> }
            { rolePrivilege >= PRIVILEGE_LEVEL.high && <PrivateRoute  exact path={routes.employees} loggedIn={loggedIn} component={Employees} /> }

            <Route render={() => <Redirect to={{ pathname: loggedIn ? routes.caseList : routes.login }} />}
            />
          </Switch>
        </Layout.Content>
    );
  };
}

CaseList.propTypes = {
  loggedIn: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.accountReducer.loggedIn
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Router));