import request from "./requestWrapper";

function getParams() {
  return request({
    url: '/Contract/GetParams',
    method: 'GET'
  });
}
function getAll() {
  return request({
    url: '/Contract/GetAll',
    method: 'GET'
  });
}
function GetAllByCampusId(data) {
  return request({
    url: '/Contract/GetAllByCampusId',
    method: 'GET',
    params: data
  });
}
function getSpecimensContractByContractId(data) {
  return request({
    url: '/Contract/getSpecimensContractByContractId',
    method: 'GET',
    params: data
  });
}
function getContractDetail(data) {
  return request({
    url: '/Contract/GetContractDetail',
    method: 'GET',
    params: data
  });
}
function add(data) {
  return request({
    url: '/Contract/Add',
    method: 'POST',
    data
  });
}
function remove(data) {
  return request({
    url: '/Contract/Delete',
    method: 'DELETE',
    params: data
  });
}
function update(data) {
  return request({
    url: '/Contract/Update',
    method: 'PUT',
    data: data
  });
}

export default {
  getParams, getAll, add , getContractDetail, GetAllByCampusId,
  getSpecimensContractByContractId, remove, update
}