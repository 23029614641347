//Libraries
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {Row, Col, Input, Form, Button, Divider, Tooltip, Popover, Icon} from "antd";
import { connect } from "react-redux";

//Services
import { resetPassword } from "../../store/redux/actions/account/accountActions";

//functions
import { isValidPassword} from "../../utils/accountUtils";

//Constants
import routes from "../../lib/routes";
import { ERROR_MODAL } from "../subComponents/responseModals";
import {CorpopatLogo} from "../../assets/customIcons";
import geekcoreLogo from "../../assets/images/geekcore-logo.png";
const { Item } = Form;

const PasswordPolicyContent = (
  <div>
    <ul>
      <li> Debe contener mínimo un número </li>
      <li> Debe contener mínimo 6 caractéres de longitud </li>
      <li> Debe contener mínimo 6 caractéres distintos </li>
      <li> Debe contener mínimo una letra mayúscula </li>
    </ul>
  </div>
);

class ResetPassword extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isMounted: false,
      data: {
        userId: null,
        code: null
      }
    };

    this.onClickReset = this.onClickReset.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  };

  componentDidMount(){
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });

        let pathname = window.location.pathname;
        let urlParams = pathname.split("/");
        let newData = this.state.data;

        if(urlParams.length === 4){
          newData.userId = urlParams[2];
          newData.code = urlParams[3];
          this.setState({ data: newData });
        }else{
          //window.location.href = routes.home;
        }
      }
    });
  }

  onClickReset(){
    const { data } = this.state;
    const { form } = this.props;

    form.validateFields((errors, values) =>{
      if(errors) return null;
      if(values.password !== values.passwordConfirm){
        ERROR_MODAL("Las contraseñas no coinciden. Por favor verifique que sean iguales.");
        return null;
      }
      else{
        let newData = data;

        newData.password = values.password;
        this.props.resetPassword(newData);
      }
    });
  }

  handlePasswordChange(rule, value, callback) {
    try {
      if (!isValidPassword(value)) {
        throw new Error('La contraseña no cumple las politicas');
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
        <Row className="main-container account">
          <div className={"account-main-div"}>
            <div className={"account-container"}>
              <Col md={12} xs={0} className={"left-content"}>
                <div className={"center-align logo-container"}>
                  <CorpopatLogo width={300} height={200} />
                </div>
              </Col>
              <Col md={12} xs={24} className={"right-content"}>
                <div className={"form-container"}>
                  <h3>Cambio de contraseña</h3>
                  <Form>
                    <Col xs={21} className="center-align">
                      <Item className="form-item" label="">
                        {getFieldDecorator("password", {
                          rules: [
                            { required: true, message: "Campo requerido", whitespace: true},
                            { validator: this.handlePasswordChange }
                          ]
                        })(
                          <Input.Password type="password" placeholder="Nueva contraseña"
                                          prefix={<Icon type="lock"/>}
                          />
                        )}
                      </Item>
                    </Col>
                    <Col xs={3} className="center-align">
                      <Item>
                        <Tooltip title='Consulta las políticas de contraseña (clic)' placement='bottom'>
                          <Popover trigger='click' title='Políticas de contraseña' content={PasswordPolicyContent} placement='top'>
                            <Button type={"default"} className={"info-password"} shape="circle" icon='info'/>
                          </Popover>
                        </Tooltip>
                      </Item>
                    </Col>
                    <Col xs={24} md={24} className="center-align">
                      <Item className="form-item" label="">
                        {getFieldDecorator("passwordConfirm", {
                          rules: [
                            { required: true, message: "Campo requerido", whitespace: true},
                            { validator: this.handlePasswordChange }
                          ]
                        })(
                          <Input.Password type="password" placeholder="Confirmar contraseña"
                                          prefix={<Icon type="lock"/>}
                          />
                        )}
                      </Item>
                    </Col>
                    <Col xs={24}>
                      <Button size="large" type={"primary"}
                              onClick={this.onClickReset}>
                        Cambiar contraseña
                      </Button>
                    </Col>
                  </Form>
                  <Col md={24} xs={24}>
                    <Divider />
                  </Col>
                  <Col md={24} xs={24} className={"account-links center-align"}>
                    <a href={routes.login}>Iniciar sesión</a>
                  </Col>
                </div>
                <p className={"center-align"}>
                  Powered by <a href={"https://geekcore.co"} target={"_blank"}><img alt={"Geekcore"}  src={geekcoreLogo} /></a>
                </p>
              </Col>
            </div>
          </div>
        </Row>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => dispatch(resetPassword(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ResetPassword));
