// Types
import { employeesTypes } from '../../types';


// Initial State
const initialState = {
  employeesList: [],
  roles: []
};

export default function employeesReducer (state = initialState, action) {
  switch (action.type) {
    case employeesTypes.GET_ALL_EMPLOYEES:
      return{
        ...state,
        employeesList: action.employeesList
      };
    case employeesTypes.GET_ROLES:
      return{
        ...state,
        roles: action.roles
      };
    default:
      return state;
  }
}