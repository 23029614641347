//Libraries
import React, { Component } from "react";
import {Button, Modal, Row, Col, Form, Input, Select, Checkbox, Transfer, message} from 'antd';
import * as PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

//Services
import {getSpecialties} from "../../store/redux/actions/specialists/specialistsActions";
import {getRoles} from "../../store/redux/actions/employees/employeesActions";
import { getHeadquartersParams } from "../../store/redux/actions/headquarters/headquartersActions";
import { getContractsParams } from "../../store/redux/actions/contracts/contractsActions";

//SubComponents
import ImageCrop from "../subComponents/ImageCrop";

//Functions
import { getBase64 } from "../../utils/functions";

//Constants
import { thousandsNumberSeparator } from "../../utils/stringUtils";
import { MENU_CONSTANTS } from "../../utils/constants";
const { Item } = Form;
const { Option } = Select;

class EditModal extends Component {
  constructor(props) {
    super(props);
    let imageUrl = null;
    if(this.props.section === MENU_CONSTANTS.clients)
      imageUrl = this.props.selectedItem.logoUrl !== "" ? this.props.selectedItem.logoUrl : null;
    else if(this.props.section === MENU_CONSTANTS.specialists)
        imageUrl = this.props.selectedItem.signatureUrl !== "" ? this.props.selectedItem.signatureUrl : null;

    this.state = {
      isMounted: false,
      loading: false,
      imageUrl: imageUrl,
      imageFile: null,
      transferTargetKeys: [],
      transferSelectedKeys: [],
      tariffSpecimenContract: true,
      selectSpecimenContract: false,
      tariffSpecimenContractValue: {},
      tariffSpecimenContractValues: [],
    };
    this.onHandleChangeImage = this.onHandleChangeImage.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleTransferChange = this.handleTransferChange.bind(this);
    this.handleSelectTransferChange = this.handleSelectTransferChange.bind(this);
    this.onChangeSpecimenTable = this.onChangeSpecimenTable.bind(this);
    this.onChangeSpecimenCategory = this.onChangeSpecimenCategory.bind(this);
    this.onClickButtonTariff = this.onClickButtonTariff.bind(this);
  }

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        switch (this.props.section) {
          case MENU_CONSTANTS.specialists:
            this.props.getSpecialties();
            break;
          case MENU_CONSTANTS.employees:
            this.props.getRoles();
            break;
          case MENU_CONSTANTS.headquarters:
            this.props.getHeadquartersParams();
            break;
          case MENU_CONSTANTS.contractsSpecimenTable:
            this.props.getContractsParams();
            let specimenContractIds = [];
            let specimenContract = [];
            this.props.specimensContracts.forEach((item)=>{
              specimenContractIds.push(item.key);
              specimenContract.push({
                price: item.defaultTariff,
                specimenId: item.key,
              });
            })
            this.setState({
              transferTargetKeys: specimenContractIds,
              tariffSpecimenContractValues: specimenContract
            });
            break;
          default:
            break;
        }
      }
    });
  }

  validateForm() {
    const { form } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      else {
        if (this.props.section === MENU_CONSTANTS.clients) {
          values.logo = this.state.imageFile;
        }else if(this.props.section === MENU_CONSTANTS.specialists){
          values.signature = this.state.imageFile;
        }
        else if(this.props.section === MENU_CONSTANTS.contractsSpecimenTable){
          if(!this.state.tariffSpecimenContract){
            message.warn('Deselecciona el especímen para continuar.')
            return;
          }
          delete values.specimenCategoryId;
          delete values.specimenTableId;
          delete values.priceSpecimen;
          values.specimensIds = this.state.transferTargetKeys;
          values.specimens = this.state.tariffSpecimenContractValues;
        }
        this.props.onToggleEditModal(false);
        this.props.editFunction(values);
      }
    });
  }

  handleTransferChange(nextTargetKeys, direction){
    const { setFieldsValue } = this.props.form;
    const { tariffSpecimenContractValues, tariffSpecimenContractValue, specimenList } = this.state;
    this.setState({ transferTargetKeys: nextTargetKeys });
    let newTariffSpecimenContractValues;
    if(direction === "right"){
      newTariffSpecimenContractValues = tariffSpecimenContractValues;
      nextTargetKeys.forEach( id => {
        if(newTariffSpecimenContractValues.find( tsc => tsc.specimenId === id) === undefined){
          let tariffSpecimenContractSelected =  {
            price: specimenList.find(sl => sl.specimenId === id).defaultTariff,
            specimenId: id
          }
          newTariffSpecimenContractValues.push(tariffSpecimenContractSelected);
        }
      });
      this.setState({tariffSpecimenContractValues: newTariffSpecimenContractValues})
    }
    else{
      newTariffSpecimenContractValues = tariffSpecimenContractValues.filter( tsc =>
        tsc.specimenId !== tariffSpecimenContractValue.specimenId
      )
      this.setState({tariffSpecimenContractValues: newTariffSpecimenContractValues})
    }
    setFieldsValue({ "priceSpecimen": 0 });
  };

  handleSelectTransferChange(sourceSelectedKeys, targetSelectedKeys){
    this.setState({ transferSelectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
    const { specimenList } = this.state;
    const { setFieldsValue } = this.props.form;
    if(sourceSelectedKeys.length > 0){
      this.setState({selectSpecimenContract: true});
    }
    else if(targetSelectedKeys.length > 0){
      let price;
      specimenList.forEach( specimen =>{
        if(specimen.key !== targetSelectedKeys[0]){
         specimen.disabled = true;
         price = specimen.defaultTariff;
        }
        else{
          setFieldsValue({ "priceSpecimen": specimen.defaultTariff });
        }
      });
      let data = {
        price: price,
        specimenId: targetSelectedKeys[0]
      }
      this.setState({
        tariffSpecimenContractValue: data,
        tariffSpecimenContract: false
      });
    }
    else{
      specimenList.forEach( specimen =>{ specimen.disabled = false; });
      this.setState({
        tariffSpecimenContract: true,
        selectSpecimenContract: false,
      });
    }
  };

  onHandleChangeImage (info) {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          imageFile: info.file,
          loading: false,
        })
      );
    }
  };

  onChangeSpecimenTable (value) {
    const { contractsParams } = this.props;
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ "specimenCategoryId": undefined });
    let specimenCategoryList = value
        ? contractsParams.specimenTables.filter(st => st.specimenTableId === value)[0].specimenCategories
        : [];
    this.setState({
      specimenCategoryList: specimenCategoryList,
    });
  };

  onChangeSpecimenCategory (value) {
    const { specimenCategoryList, tariffSpecimenContractValues } = this.state;
    let specimenList = value
        ? specimenCategoryList.filter(sc => sc.specimenCategoryId === value)[0].specimens
        : [];
    specimenList.forEach(specimen => {
      specimen.disabled = false;
      tariffSpecimenContractValues.forEach((tariffSpecimenContract)=>{
        if(tariffSpecimenContract.specimenId === specimen.specimenId){
          specimen.defaultTariff = tariffSpecimenContract.price;
        }
      })
    });
    this.setState({
      specimenList: specimenList,
    });
  };

  onClickButtonTariff () {
    const { getFieldValue } = this.props.form;
    const { transferSelectedKeys, specimenList, tariffSpecimenContractValues } = this.state;
    let data = {
      price: getFieldValue("priceSpecimen"),
      specimenId: transferSelectedKeys[0]
    }
    if(tariffSpecimenContractValues.find( tsc => tsc.specimenId === transferSelectedKeys[0])){
      let newTariffSpecimenContractValues = tariffSpecimenContractValues;
      newTariffSpecimenContractValues.forEach((tcs, index) => {
        if(tcs.specimenId === transferSelectedKeys[0]) {
          newTariffSpecimenContractValues[index].price = getFieldValue("priceSpecimen");
        }
      });
    }
    specimenList.forEach(specimen => {
      if(specimen.specimenId === transferSelectedKeys[0]) {
        specimen.defaultTariff = getFieldValue("priceSpecimen")
      }
    });
    this.setState({
      tariffSpecimenContractValue: data,
      tariffSpecimenContract: false,
    });
    message.config({
      top: 60,
      duration: 4,
    });
    message.success('Tarifa modificada.');
  };


  render() {
    const { title, onToggleEditModal, section, selectedItem, specialties, roles, headquartersParams,
            contractsParams } = this.props;
    const { imageFile, imageUrl, loading, transferTargetKeys, transferSelectedKeys,
            tariffSpecimenContract, specimenCategoryList, specimenList, selectSpecimenContract } = this.state;
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
    let specimenTableValue = getFieldValue("specimenTableId");
    let specimenCategoryValue = getFieldValue("specimenCategoryId");
    let editButtonDisabled = section === MENU_CONSTANTS.contractsSpecimenTable ? transferTargetKeys.length === 0 : false;
    return (
      <Modal className={"edit-modal"}
             title={title} visible={true} keyboard={false}
             closable={false} destroyOnClose={true} centered
             footer={[
               <Button type={"default"} key="back" onClick={() => onToggleEditModal(false)}>
                 Cancelar
               </Button>,
               <Button key="submit" type="primary" disabled={editButtonDisabled}
                       onClick={this.validateForm}>
                  Editar
                </Button>
             ]}
      >
        <Form>
          {
            //#region "Edit specialist"
            section === MENU_CONSTANTS.specialists && (
              <Row>
                {getFieldDecorator("specialistId", { initialValue: selectedItem.specialistId })(<span />)}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombres">
                    {getFieldDecorator("names", {
                      initialValue: selectedItem.names,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Apellidos">
                    {getFieldDecorator("surnames", {
                      initialValue: selectedItem.surnames,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      initialValue: selectedItem.email,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="email" autoComplete={"off"} disabled={true}/>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Registro médico">
                    {getFieldDecorator("medicalNumber", {
                      initialValue: selectedItem.medicalNumber,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Especialidad">
                    {getFieldDecorator("specialtyId", {
                      initialValue: selectedItem.specialtyId,
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                        <Select>
                          {specialties.map(specialty =>
                              <Option key={specialty.id} value={specialty.id}>
                                {specialty.name}
                              </Option>
                          )}
                        </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Título">
                    {getFieldDecorator("degree", {
                      initialValue: selectedItem.degree,
                      rules: [{required: true, message: "Este campo es requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="">
                    {getFieldDecorator("isActive", {
                      valuePropName: "checked",
                      initialValue: selectedItem.isActive,
                      rules: [{ required: false }],
                    })(
                        <Checkbox>Activo</Checkbox>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ImageCrop imageFile={imageFile}
                             imageUrl={imageUrl}
                             name={"Firma"}
                             loading={loading}
                             aspect={2.5}
                             onHandleChangeImage={(info) => this.onHandleChangeImage(info)}/>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Edit client"
            section === MENU_CONSTANTS.clients && (
              <Row>
                {getFieldDecorator("clientId", { initialValue: selectedItem.clientId })(<span />)}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombre">
                    {getFieldDecorator("name", {
                      initialValue: selectedItem.name,
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="NIT">
                    {getFieldDecorator("nit", {
                      initialValue: selectedItem.nit,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      initialValue: selectedItem.email,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="email" autoComplete={"off"}  disabled={true} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="">
                    {getFieldDecorator("letterWithLogo", {
                      valuePropName: "checked",
                      initialValue: selectedItem.letterWithLogo,
                      rules: [{ required: false }],
                    })(
                        <Checkbox>Carta con logo</Checkbox>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="">
                    {getFieldDecorator("isActive", {
                      valuePropName: "checked",
                      initialValue: selectedItem.isActive,
                      rules: [{ required: false }],
                    })(
                        <Checkbox>Activo</Checkbox>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ImageCrop imageFile={imageFile}
                             imageUrl={imageUrl}
                             name={"Logo"}
                             loading={loading}
                             onHandleChangeImage={(info) => this.onHandleChangeImage(info)}/>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Edit employee"
            section === MENU_CONSTANTS.employees && (
              <Row>
                {getFieldDecorator("employeeId", { initialValue: selectedItem.employeeId })(<span />)}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Nombres">
                    {getFieldDecorator("names", {
                      initialValue: selectedItem.names,
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Apellidos">
                    {getFieldDecorator("surnames", {
                      initialValue: selectedItem.surnames,
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Correo">
                    {getFieldDecorator("email", {
                      initialValue: selectedItem.email,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="email" autoComplete={"off"}  disabled={true} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Rol">
                    {getFieldDecorator("roleId", {
                      initialValue: selectedItem.roleId,
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                        <Select>
                          {roles.map(role =>
                              <Option key={role.id} value={role.id}>
                                {role.name}
                              </Option>
                          )}
                        </Select>
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item small-form-item" label="">
                    {getFieldDecorator("isActive", {
                      valuePropName: "checked",
                      initialValue: selectedItem.isActive,
                      rules: [{ required: false }],
                    })(
                        <Checkbox>Activo</Checkbox>
                    )}
                  </Item>
                </Col>
              </Row>
            )
            //#endregion
          }
          {
            //#region "Edit contract specimen table"
            section === MENU_CONSTANTS.contractsSpecimenTable && (
              <div>
                <Row>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Tabla de especímenes">
                      {getFieldDecorator("specimenTableId", {
                        rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                      })(
                        <Select disabled={!tariffSpecimenContract || selectSpecimenContract}
                                onChange={ (e) => this.onChangeSpecimenTable(e) }>
                          {contractsParams.specimenTables.map(st =>
                            <Option key={st.specimenTableId} value={st.specimenTableId}>
                              {st.name}
                            </Option>
                          )}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Item className="form-item-small" label="Categoría">
                      {getFieldDecorator("specimenCategoryId", {
                        rules: [{ required: true, message: "Este campo es requerido", whitespace: false }],
                      })(
                          <Select disabled={!specimenTableValue || !tariffSpecimenContract || selectSpecimenContract}
                                  onChange={ (e) => this.onChangeSpecimenCategory(e) }>
                            {specimenCategoryList !== undefined &&
                              specimenCategoryList.map(sc =>
                                <Option key={sc.specimenCategoryId} value={sc.specimenCategoryId}>
                                  {sc.name}
                                </Option>
                            )}
                          </Select>
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <Item className="form-item-small" label="Precios personalizados">
                      <Transfer className={"edit-modal-transfer"}
                                rowKey={record => record.specimenId}
                                disabled={!specimenCategoryValue}
                                dataSource={specimenList}
                                showSelectAll={false}
                                selectedKeys={transferSelectedKeys} targetKeys={transferTargetKeys}
                                onChange={this.handleTransferChange}
                                onSelectChange={this.handleSelectTransferChange}
                                render={specimen =>
                                    `(${specimen.cupsCode}) $${thousandsNumberSeparator(specimen.defaultTariff)}: ${specimen.name}`
                                }
                      />
                    </Item>
                  </Col>
                </Row>
                <Row type="flex" justify="end" align="middle">
                  <Col xs={5}>
                    <label>Precio espécimen:</label>
                  </Col>
                  <Col xs={5}>
                    <Item className="small-form-item">
                      {getFieldDecorator("priceSpecimen", {
                          getValueFromEvent: (e) => {
                              let value = parseInt(e.currentTarget.value === "" ? 0 : e.currentTarget.value);
                              if (!isNaN(value)){
                                return value
                              }
                              else{
                                return 0;
                              }
                          },
                          initialValue: 0,
                          rules: [
                            { required: !tariffSpecimenContract, message: "Cantidad requerida" },
                            { type: "number", min: "0" },
                          ]
                        })(
                          <Input className={"center-align"} disabled={tariffSpecimenContract} prefix={"$"}/>
                        )}
                    </Item>
                  </Col>
                  <Col xs={2} className="center-align">
                    <Button type={"default"} icon={"check"} className="margin-top-button"
                            disabled={tariffSpecimenContract}
                            onClick={this.onClickButtonTariff}
                    />
                  </Col>
                </Row>
              </div>
            )
            //#endregion
          }
          {
            //#region "Edit headquarters"
            section === MENU_CONSTANTS.headquarters && (
              <Row>
                {getFieldDecorator("campusId", { initialValue: selectedItem.campusId })(<span />)}
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item className="form-item" label="Nombre">
                    {getFieldDecorator("name", {
                      initialValue: selectedItem.name,
                      rules: [{required: true, message: "Campo requerido", whitespace: false}],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Prefijo">
                    {getFieldDecorator("prefix", {
                      initialValue: selectedItem.prefix,
                      rules: [{ required: true, message: "Campo requerido", whitespace: false }],
                    })(
                        <Input type="text" autoComplete={"off"} />
                    )}
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item className="form-item" label="Cliente">
                    {getFieldDecorator("clientId", {
                      initialValue: selectedItem.client.clientId,
                      rules: [{required: true, message: "Este campo es requerido"}],
                    })(
                        <Select>
                          {headquartersParams.map(client =>
                              <Option key={client.id} value={client.id}>
                                {client.value}
                              </Option>
                          )}
                        </Select>
                    )}
                  </Item>
                </Col>
              </Row>
            )
            //#endregion
          }
        </Form>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  title: PropTypes.string,
  section: PropTypes.string,
  specialties: PropTypes.array,
  specimensContracts: PropTypes.array,
  roles: PropTypes.array,
  selectedItem: PropTypes.object,
  onToggleEditModal: PropTypes.func,
  editFunction: PropTypes.func,
  getSpecialties: PropTypes.func,
  getRoles: PropTypes.func,
  getHeadquartersParams: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    specialties: state.specialistsReducer.specialties,
    roles: state.employeesReducer.roles,
    headquartersParams: state.headquartersReducer.headquartersParams,
    contractsParams: state.contractsReducer.contractsParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialties: () => dispatch(getSpecialties()),
    getRoles: () => dispatch(getRoles()),
    getHeadquartersParams: () => dispatch(getHeadquartersParams()),
    getContractsParams: () => dispatch(getContractsParams())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditModal));
