// Types
import { contractsTypes } from '../../types';


// Initial State
const initialState = {
  contractsParams: {
    campuses: [],
    specimenTables: []
  },
  contractsList: [],
  specimensContracts: [],
};

export default function contractsReducer (state = initialState, action) {
  switch (action.type) {
    case contractsTypes.GET_CONTRACT_PARAMS:
      return{
        ...state,
        contractsParams: action.contractsParams
      };
    case contractsTypes.GET_ALL_CONTRACTS:
      return{
        ...state,
        contractsList: action.contractsList
      };
    case contractsTypes.GET_CONTRACT_DETAIL:
      return{
        ...state,
        specimensContracts: action.specimensContracts
      };
    default:
      return state;
  }
}