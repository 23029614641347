// Types
import { headquartersTypes } from '../../types';


// Initial State
const initialState = {
  headquarterList: [],
  headquartersParams: [],
};

export default function headquartersReducer (state = initialState, action) {
  switch (action.type) {
    case headquartersTypes.GET_ALL_HEADQUARTERS:
      action.headquarterList.forEach(headquarter => {
        delete headquarter['clientId'];
      });
      return{
          ...state,
        headquarterList: action.headquarterList
      };
    case headquartersTypes.GET_PARAMS_HEADQUARTERS:
      return{
          ...state,
          headquartersParams: action.headquartersParams
      };
    default:
      return state;
  }
}