import React from "react";
import { Route, Redirect } from 'react-router-dom';

import routes from "../../lib/routes";

export function PrivateRoute ({ component: Component, loggedIn }) {
  return (
      <Route render={() =>
          loggedIn
              ? <Component />
              : <Redirect to={{ pathname: routes.login }} />
      }
      />
  )
}