import { generalTypes, employeesTypes } from "../../types";
import employeesServices from "../../../../api/employeesServices";

//Components
import { ERROR_MODAL, SUCCESS_MODAL } from "../../../../components/subComponents/responseModals";

const setLoading = (isLoading) => {
  return {
    type: generalTypes.LOADING,
    isLoading
  }
};

export const getAll = () => {
  return dispatch => {
    dispatch(setLoading(true));
    employeesServices.getAll()
        .then(response => {
          dispatch({
            type: employeesTypes.GET_ALL_EMPLOYEES,
            employeesList: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const add = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    employeesServices.add(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const update = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    employeesServices.update(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const remove = (data) => {
  return dispatch => {
    dispatch(setLoading(true));
    employeesServices.remove(data)
        .then(response => {
          dispatch(getAll());
          SUCCESS_MODAL(response.data);
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};
export const getRoles = () => {
  return dispatch => {
    dispatch(setLoading(true));
    employeesServices.getRoles()
        .then(response => {
          dispatch({
            type: employeesTypes.GET_ROLES,
            roles: response.data
          });
          dispatch(setLoading(false));
        })
        .catch(err => {
          ERROR_MODAL(err.data);
          dispatch(setLoading(false));
        })
  }
};